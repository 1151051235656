import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { ClothesIcon } from 'druikit'
import { formatVendorName } from 'utils/sellers'
import { useFilterGender } from 'hooks/useFilter'
import styles from './styles.module.scss'
import { TagWithTips } from '../NornaTab'

export const TableCategoryCell = ({
    text,
    record,
    width,
    isCompetitorView,
    showMenuButton = false,
    isDeepBorderRight = false,
}: {
    text: string;
    width: number;
    record?: any;
    isCompetitorView?: boolean;
    showMenuButton?: boolean;
    isDeepBorderRight?: boolean;
}) => {
    const [ filterGender ] = useFilterGender()

    const sty: CSSProperties = {}
    if (isDeepBorderRight) {
      sty.borderRight = '1px solid #01A699'
    }

    return (
        <div
          test-id="table-category"
          style={{ width: `${width}px`, ...sty }}
          className={classnames(styles.tablecell1, 'text-ellipsis')}
        >
            { 
              isCompetitorView ? (
                <ClothesIcon size={16} clothes={text} targetGroup={filterGender.length === 1 ? filterGender[0] : 'All Options'} />
              ) : null 
            }
            { isCompetitorView ? text : 
              (
                <div style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                  <TagWithTips width={`${width}px`} label={formatVendorName(text).name} />
                  <div style={{
                    fontSize: '11px', transform: 'scale(0.9)',
                    transformOrigin: 'left',
                    whiteSpace: 'nowrap',
                  }}
                  >
                    {formatVendorName(text).platform}
                  </div>
                </div>
              )
            }
        </div>
    )
}
