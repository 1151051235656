import React, { useCallback, useEffect, useRef, useState } from 'react'
import { RoutesObj } from 'global/Routes'
import { useHistory } from 'react-router-dom'
import { AccountIcon, CompanyLicense } from 'assets/icons/Account'
import { EjectIcon } from 'assets/icons/Eject'
import { useAuthenticationDataContext } from 'components/guards/AuthenticationContext'
import { CaretIcon, ReleaseIcon } from 'assets/icons'
import classnames from 'classnames'
import { MenuDropdown, MenuDropdownItem } from 'componentsv2/MenuDropdown/'
import { REQUEST_UPDATE_ACCOUNT } from 'consts'
import { useFetchs } from 'libs/hookRequest'
import { downloadByUrl } from 'utils/file'
import { useLogin } from 'hooks/useLogin'
import { sortBy } from 'lodash'
import styles from './styles.module.scss'
import { getCurrentUser } from '../../../../../features/filters/filtersSlice'
import { ReleaseNote } from './components'
import { MockData } from './utils'
import { useSelector } from 'react-redux'
import { getVersionRead } from 'features/filters/globalDataSlice'
import { Badge } from 'antd'
import { storage } from 'utils/storage'

export const Account = () => {
  const history = useHistory()
  const { getFn, patchFn, ...restData } = useFetchs()
  const companyRef = useRef<Array<any>>([])
  const versionRead = useSelector(getVersionRead)
  const accountId = storage.getAccountId()
  const email = storage.getEmail()

  const data = storage.getLicenses()
  const [ userInitials, setUserInitials ]: any = useState()
  const { user, onLogout }: any = useAuthenticationDataContext()

  const [ currentCompany, setCurrentCompany ] = useState<any>({ list: [], active: '' })
  const [ releaseVisble, setReleaseVisble ] = useState(false)
  const setCurrentCompanyFn = React.useCallback(data => {
    if (data && data.length && currentCompany.list.length === 0) {
      setCurrentCompany({
        list: data.map(item => ({
          key: item.id,
          isSelected: item.active,
          description: item.name,
        })),
        active: data.filter(item => item.active)?.[0]?.id,
      })
    }
  }, [ currentCompany ])

  const { initNewUser } = useLogin()

  const toggleData = restData[REQUEST_UPDATE_ACCOUNT]?.data
  const toggleFn = useCallback(async toggleData => {
    if (toggleData) {
      const [ newCompanyId ] = companyRef.current
      initNewUser(toggleData.access_token, email, newCompanyId, accountId)
      window.location.href = '/' // refresh page
    }
  }, [ accountId, email, initNewUser,companyRef ])

  useEffect(() => {
    toggleFn(toggleData)
  }, [toggleData]);//eslint-disable-line

  useEffect(() => {
    data && setCurrentCompanyFn(data)
  }, [data]);//eslint-disable-line

  useEffect(() => {
    if (user) {
      setUserInitials(user.email.split('@')[0].match(/\b\w/g).join(''))
    }
  }, [ user, history ])

  /**
   * @todo:
   * add auto resize when change the screen size manually
   */
  let licenseHeight = window.innerHeight - 65 - 50 - 40 * 2
  licenseHeight = licenseHeight < 0 ? 100 : licenseHeight
  licenseHeight = licenseHeight > 300 ? 300 : licenseHeight

  const onRelease = async () => {
    setReleaseVisble(true)
  }

  const licenseList = MockData(currentCompany.list, user)

  return (
    <>
      {
        releaseVisble && <ReleaseNote onClose={() => setReleaseVisble(false)} />
      }
      <MenuDropdown
        panelSty={{ paddingTop: 10 }}
        customClass="cell-mouse-effect-nav-no-within"
        list={(
          <>
            <MenuDropdownItem
              customClass="cell-mouse-effect-no-display"
              onClick={() => history.push(RoutesObj.PROFILE.URL)}
            >
              <AccountIcon /> Account
            </MenuDropdownItem>
            <div
              className={classnames(styles.licenseContainer)}
              onScroll={e => e.stopPropagation()}
              test-id="license-list"
            >
              {
                licenseList.length ? sortBy(licenseList,'description').filter(item => item?.description !== 'All').map(selection => (
                  <div
                    test-id={selection.key}
                    className={classnames(selection.isSelected ? styles.active : '', 'cell-mouse-effect-no-display')}
                    key={selection.key}
                    onClick={async () => {
                      if (currentCompany.active === selection.key) return
                      const accountAndCompanyId = getCurrentUser()
                      const newCompanyId = selection.key

                      if (accountAndCompanyId) {
                        await patchFn(REQUEST_UPDATE_ACCOUNT, {
                          query: {
                            company_id: newCompanyId,
                          }, data: {},
                        }) // update the current company license in db
                        companyRef.current = [ newCompanyId, accountAndCompanyId ]
                      }
                    }}
                                                                                          >
                    <CompanyLicense /> {selection.description}
                  </div>
                )) : null
              }
            </div>
            <MenuDropdownItem customClass="cell-mouse-effect-no-display" onClick={() => downloadByUrl('/docs/Manual_Norna.pdf')}>
              <ReleaseIcon /> Manual
            </MenuDropdownItem>
            <MenuDropdownItem customClass="cell-mouse-effect-no-display" onClick={onRelease} sty={{ position: 'relative' }}>
              <ReleaseIcon /> Release notes
              {
                !versionRead && (
                  <Badge color="#E22F21" style={{ position: 'absolute', left: 21, top: 7 }} />
                )
              }
            </MenuDropdownItem>
            <MenuDropdownItem customClass="cell-mouse-effect-no-display" onClick={onLogout}>
              <EjectIcon /> Sign Out
            </MenuDropdownItem>
          </>
        )}
      >
        <div style={{ position: 'relative' }}>
          <div className={styles['account-initials']}>
            {userInitials}
          </div>
          {
            !versionRead && (
              <Badge color="#E22F21" style={{ position: 'absolute', right: 0, top: 0 }} />
            )
          }
        </div>
        <div className={styles['account-initials-arrow']}>
          <CaretIcon />
        </div>
      </MenuDropdown>
    </>
  )
}
Account.displayName='Account' 
