import { Button, Modal, Table, Tag } from 'antd'
import { loadingBar } from 'hooks';
import { useDynamicDashboardReportData } from 'hooks/api/useDynamicDashboardReportData';
import React, { useEffect } from 'react'

const handleDataSource = (data = {}) => {
    const dateList = Object.keys(data || {})
    const dataSource = dateList.map(date => {
        return {
            date,
            status: data[date]?.status || '',
        }
    })
    return dataSource
}

const PdfModal = ({
    orderId,
    onCancel,
}: {
    orderId: string;
    onCancel?: () => void;
}) => {
    const {
        listPdfs,
        listPdfsData = {},
        listPdfsLoading,
        downloadPdf,
    } = useDynamicDashboardReportData()

    useEffect(() => {
        listPdfs(orderId)
    }, [ orderId ])

    const onDownloadPDF = async (record) => {
        const date = record.date as string
        loadingBar.start()
        await downloadPdf(orderId, date)
        loadingBar.done()
    }

    const columns: any[] = [
        {
            dataIndex: 'date',
            title: 'Date',
        },
        {
            dataIndex: 'status',
            title: 'Status',
            render(text) {
                return <Tag color={ text === 'done' ? 'green' : 'red' }>{text}</Tag>
            },
        },
        {
            dateIndex: 'action',
            title: 'Action',
            align: 'center',
            render(_, record) {
                if (record.status !== 'done') return null
                return (
                    <Button 
                        type="link"
                        onClick={() => onDownloadPDF(record)}
                    >
                        Download PDF
                    </Button>
                )
            },
        },
    ]

    return (
        <Modal
            width={500}
            title="PDF"
            open={true}
            footer={false}
            onCancel={onCancel}
        >
            <Table 
                columns={columns} 
                dataSource={handleDataSource(listPdfsData)}
                loading={listPdfsLoading} 
                rowKey="date"
            />
        </Modal>
    )
}

export default PdfModal
