import React, { CSSProperties, useState } from 'react'
import classnames from 'classnames'
import { CloseIcon } from 'assets/icons'
import { Button } from 'components/form-elements/Button'
import { FILTER_DIALOG_CLASS, NO_SIZE_IGNORE_NO_AVAILABLE_SIZE, NO_SIZE_NO_AVAILABLE_SIZE_UPDATE, NO_SIZE_NO_AVAILABLE_SIZE_UPDATE_V2, NO_SIZE_NO_HARMONIZED_SIZE, NO_SIZE_NO_SIZE_HISTORY, NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z, NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE, NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE_V2 } from 'consts'
import styles from './styles.module.scss'
import { Tooltip } from 'antd'
import InfoOutlined from 'pages/DynamicDashboard/components/InfoOutlined'
import { useNoSizeFilter } from 'hooks/useFilter'

export const RadioItem = ({ checked = false, setChecked }: any) => (
    <button
      className={classnames(styles.radio, checked ? styles.active : '')}
      onClick={() => {
        setChecked(!checked)
      }}
    >
      <div />
    </button>
  )
export const NoSizeDialog = ({ 
  onClose, 
  onOk,
  modalMaskStyle = {}, 
}: {
  onClose?: () => void;
  onOk?: (value: any) => void;
  modalMaskStyle?: CSSProperties;
}) => {
  const [ noSizeFilter, setNoSizeFilter ] = useNoSizeFilter()

  const [ noSelectionDatesSize, setNoSelectionDatesSize ] = useState(noSizeFilter.no_selection_dates_size)
  const [ noSelectionDatesSizeV2, setNoSelectionDatesSizeV2 ] = useState(noSizeFilter.no_selection_dates_size_v2)
  const [ noUnavailableSizeUpdate, setNoUnavailableSizeUpdate ] = useState(noSizeFilter.no_unavailable_size_update)
  const [ noUnavailableSizeUpdateV2, setNoUnavailableSizeUpdateV2 ] = useState(noSizeFilter.no_unavailable_size_update_v2)
  const [ noHistoricalSize, setNoHistoricalSize ] = useState(noSizeFilter.no_historical_size)
  const [ noHarmonizedSize, setNoHarmonizedSize ] = useState(noSizeFilter.no_harmonized_size)
  const [ zalandodeepShopEmptyString, setZalandodeepShopEmptyString ] = useState(noSizeFilter.zalandodeep_shop_empty_string)
  const [ ignoreNoAvailableSize, setIgnoreNoAvailableSize ] = useState(noSizeFilter.ignore_no_available_size)

  return (
    <div className={`material-filter ${FILTER_DIALOG_CLASS}`}>
      <div
        className={styles.modal}
        style={modalMaskStyle}
        onClick={() => onClose?.()}
      />
      <div className={classnames(styles['material-filter'])}>
        <div className={styles.close}>
          <CloseIcon onClick={onClose} />
        </div>
        <div className={styles.content}>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoSelectionDatesSize(value)
              }}
              checked={noSelectionDatesSize}
            />
            <div>{NO_SIZE_NO_AVAILABLE_SIZE_UPDATE}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no available size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoSelectionDatesSizeV2(value)
              }}
              checked={noSelectionDatesSizeV2}
            />
            <div>{NO_SIZE_NO_AVAILABLE_SIZE_UPDATE_V2}</div>
            {/* <Tooltip
              title="From the products in the current filter selection, there is no available size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoUnavailableSizeUpdate(value)
              }}
              checked={noUnavailableSizeUpdate}
            />
            <div>{NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no unavailable size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoUnavailableSizeUpdateV2(value)
              }}
              checked={noUnavailableSizeUpdateV2}
            />
            <div>{NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE_V2}</div>
            {/* <Tooltip
              title="From the products in the current filter selection, there is no unavailable size update in 14 days from the start of the date range that is selected."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip> */}
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoHistoricalSize(value)
              }}
              checked={noHistoricalSize}
            />
            <div>{NO_SIZE_NO_SIZE_HISTORY}</div>
            <Tooltip
              title="From the products in the current filter selection, there is no size history of the products with respect to the cut date - 8 days."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setNoHarmonizedSize(value)
              }}
              checked={noHarmonizedSize}
            />
            <div>{NO_SIZE_NO_HARMONIZED_SIZE}</div>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setZalandodeepShopEmptyString(value)
              }}
              checked={zalandodeepShopEmptyString}
            />
            <div>{NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z}</div>
            <Tooltip
              title="No seller information on any date of any size."
              zIndex={9999992}
              placement="right"
            >
              <InfoOutlined 
                size={16}
                color="#bbb"
              />
            </Tooltip>
          </div>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20, gap: 4 }}>
            <RadioItem
              setChecked={value => {
                setIgnoreNoAvailableSize(value)
              }}
              checked={ignoreNoAvailableSize}
            />
            <div>{NO_SIZE_IGNORE_NO_AVAILABLE_SIZE}</div>
          </div>
          <div>
            <Button
              primary
              mini
              style={{ paddingInline: 40 }}
              className={styles.btn}
              onClick={() => {
                const value = {
                  no_selection_dates_size: noSelectionDatesSize,                // No available size update
                  no_selection_dates_size_v2: noSelectionDatesSizeV2,                // No available size update
                  no_unavailable_size_update: noUnavailableSizeUpdate,          // No unavailable size update
                  no_unavailable_size_update_v2: noUnavailableSizeUpdateV2,          // No unavailable size update
                  no_historical_size: noHistoricalSize,                         // No size history
                  no_harmonized_size: noHarmonizedSize,                         // No harmonized size
                  zalandodeep_shop_empty_string: zalandodeepShopEmptyString,    // No true seller information (Z)
                  ignore_no_available_size: ignoreNoAvailableSize,              // Exclude sold out
                }
                setNoSizeFilter(value)
                onOk?.(value)
                onClose?.()
              }}
            > 
              Ok
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

NoSizeDialog.displayName = 'NoSizeDialog'
