import React, { CSSProperties } from 'react'
import classnames from 'classnames'
import { Button } from 'components/form-elements/Button/Button'
import { useDashboardFetchProducts } from 'hooks/api/useDashboard'
import { appendDialogTitlePriceFilter } from 'utils'
import { useFilterPricePerspective, useFilterSortBy } from 'hooks/useFilter'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { ProductsModal } from 'components/ProductsModalV2'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { TableCell } from 'componentsv2/TradingTableV2/TableCell'
import { DisplayText } from 'componentsv2/DisplayText'
import { CATEGORY_TOTAL, NA, SELECTED_CATEGORY } from 'consts'
import { isMostPopularLaunchDaysPage } from 'global/Routes'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const TableOptionsCell = ({
    text,
    changePercent,
    record,
    width,
    metricName,
    isDeepBorderRight = false,
    onClick,
}: {
    text?: string;
    changePercent?: string;
    record?: any;
    width?: number;
    metricName: 'All';
    isDeepBorderRight?: boolean;
    onClick?: Function;
}) => {
    const [ filterSortBy ] = useFilterSortBy()

    const sty: CSSProperties = {}
    if (isDeepBorderRight) {
        sty.borderRight = '1px solid #01A699'
    }

    const label = record?.Numbers?.value || record?.options?.value || '0'
    const value = String(Number(label)) !== 'NaN' ? Number(label) : 0

    const [ filterPricePerspective ] = useFilterPricePerspective()
    const { pageDate } = usePageDate()

    const { fetchData: fetchProducts } = useDashboardFetchProducts()
    const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

    let isClickable = true
    if (filterSortBy?.sortField === 'seller' && [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(record?.categoryName)) {
        isClickable = false
    }
    if (Number(value) === 0) {
        isClickable = false
    }

    const showProductsModal = async ({ categoryName, vendorCode, vendorName, metricName }) => {
        const ids = await fetchProducts({ metric: metricName, category: categoryName, competitor: vendorCode })
        if (!ids.length) return

        const dialogId = `Options-${vendorCode}-${categoryName}`
        const closeFn = dialogRef.current?.closeDialog
        const title = [ vendorName, categoryName, 'Options' ].filter(item => item).join(', ')
        dialogRef.current?.openDialog(dialogId, (
            <ProductsModal
                productUrls={ids}
                onClose={() => closeFn(dialogId)}
                headerLeading={numberUtils.formatNumberByComma(ids.length)}
                headerTitle={`${appendDialogTitlePriceFilter(title, filterPricePerspective)}`}
                headerDate={formatDateStr2(pageDate, true)}
            />
        ))
    }

    if (!record.queryDateValid && isMostPopularLaunchDaysPage()) {
        return (
            <TableCell isDeepBorderRight={isDeepBorderRight} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <div className={classnames(styles.tableCellTwo)} style={{ width: '100%' }}>
                    {NA}
                </div>
            </TableCell>
        )
    }

    if (!record.queryDateValid || Number(label) === 0) {
        return (
            <TableCell isDeepBorderRight={isDeepBorderRight} style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <div className={classnames(styles.tableCellTwo)} style={{ width: '100%' }}>
                    0
                </div>
            </TableCell>
        )
    }

    return (
        <Button
            enableClickLoading={true}
            loadingSty={{ position: 'absolute', marginRight: '0' }}
            style={{ borderRadius: 0, padding: '2px 0px', width: width + 'px', ...sty }}
            contentSty={{ border: 'none', fontSize: '14px', fontWeight: 'normal', padding: '2px 0px', cursor: !isClickable ? 'auto' : 'pointer' }}
            onClick={async () => {
                // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/799#note_1284418365
                if (!isClickable) return 
                if (typeof onClick === 'function') {
                    await onClick?.()
                    return
                }
                await showProductsModal({
                    categoryName: record.categoryName,
                    vendorCode: record.vendorCode,
                    vendorName: record.vendorName,
                    metricName,
                })
            }}
            className={classnames(styles.tablecell1, 'text-ellipsis', !isClickable ? '' : 'transparent-btn')}
        >
            <div className={styles.cellBorder} style={{ width: '100%', borderBottom: 'none' }}>
                <div className={classnames(styles.tableCellTwo)}>
                    <span 
                        style={{ fontSize: '14px' }}
                        data-vendor={record.vendorCode} 
                        test-id="table-option-count"
                    >
                        {text}
                    </span>
                    {
                        typeof changePercent !== 'undefined' ? (
                            <span test-id="table-option-change-percent">
                                <DisplayText value={record?.queryComparisonDateValid ? changePercent : NA} />
                            </span>
                        ) : null
                    }
                </div>
            </div>
            <Dialog ref={dialogRef} />
        </Button>
    )
}
