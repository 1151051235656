import React, { useEffect, useRef, useState } from 'react'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { CATEGORY_TOTAL, SELECTED_CATEGORY } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { Spin } from 'componentsv2'
import { TableCategoryCell, TableCell, TableCellWithSymbol, TableOptionsCell } from 'componentsv2/TradingTableV2'
import { NornaTab, NornaTable } from 'componentsv2/TradingTableV3'
import { SizedBox } from 'componentsv2/SizedBox'
import { useLoadingBar } from 'hooks/useLoadingBar'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { PRICE_INSIGHT_TABLE_NAME } from 'configs'
import { useDashboardComparison, useDashboardMarkInfo } from 'hooks/api/useDashboardTableData'
import { appendDialogTitlePriceFilter, getExportId, getFractionDigits } from 'utils'
import { DashboardTableForm } from 'componentsv2/business/DashboardTableForm'
import { useModuleData } from 'hooks/useModuleData'
import { storage } from 'utils/storage'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterPricePerspective, useFilterSortBy } from 'hooks/useFilter'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { useDashboardAvgPriceChanges } from 'hooks/api/useDashboard'
import { ProductsModal } from 'components/ProductsModalV2'
import { formatDateStr2 } from 'utils/dateUtils'
import { usePageDate } from 'hooks/usePageDate'
import { useLookbook } from 'hooks/useLookbook'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import { useWindowZoom } from 'hooks/useWindow'
import { isFirstDashboardTableAsCategoryViewpoint } from 'utils/feConfig'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import styles from './styles.module.scss'
import * as config from './config'
import { geneCsvData, handleTableData, handleTopKpiData } from './utils'
import { PriceInsightTopKpi } from '../PriceInsightTopKpi'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_PRICE_INSIGHTS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'
import Thead from './Thead'
import { useScrollBar2 } from 'hooks/useScrollBar'
import classNames from 'classnames'
import { priceInsightsColumns, priceInsightsOptions } from 'consts/dashboardTableFields'
import { getDashboardColumnOrder } from 'utils/vendorOrderUtils'
import { Dropdown, DropdownType } from 'componentsv2/form-elements/Dropdown'

const exportId = getExportId(PRICE_INSIGHT_TABLE_NAME)

export const PriceInsightTable = () => {
  useLoadingBar()
  const zoom = useWindowZoom()
  const [ filterSortBy ] = useFilterSortBy()
  const [ filterCountry ] = useFilterCountry()
  const [ filterPricePerspective ] = useFilterPricePerspective()
  const [ filterCategory ] = useFilterCategory()
  const [ filterCompetitor ] = useFilterCompetitor()
  const { pageDate } = usePageDate()
  const { isLookbook } = useLookbook()
  const expandedRowKeysRef = useRef<string[]>([])
  const subExpandedRowKeysRef = useRef<string[]>([])
  const [ , setRefresh ] = useState({})

  const [ moduleData, setModuleData ] = useModuleData(PRICE_INSIGHT_TABLE_NAME)
  const { isCompetitorView, switchLoading } = moduleData

  useEffect(() => {
    setModuleData({ isCompetitorView: !isFirstDashboardTableAsCategoryViewpoint() })
    // eslint-disable-next-line
  }, [])

  const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

  /* ********************************* field ************************************** */
  const [ fieldValue, setFieldValue ] = useState(storage.getPriceInsightsFields())
  const [ sortValue, setSortValue ] = useState(storage.getPriceInsightsSort())

  /* ************************************************************ */
  const { fetchData: fetchAvgPriceChangesData } = useDashboardAvgPriceChanges()

  const onShowAvgPriceModal = async (record: any) => {
    const categoryName = record.categoryName
    const vendorCode = record.vendorCode
    const vendorName = record.vendorName
    const ids = await fetchAvgPriceChangesData({ category: categoryName, competitor: vendorCode })
    if (!ids.length) return
    const dialogId = `Options-${vendorCode}-${categoryName}`
    const closeFn = dialogRef.current?.closeDialog
    const title = [ vendorName, categoryName, 'Average price' ].filter(item => item).join(', ')
    dialogRef.current?.openDialog(dialogId, (
      <ProductsModal
        productUrls={ids}
        onClose={() => closeFn(dialogId)}
        headerLeading={numberUtils.formatNumberByComma(ids.length)}
        headerTitle={`${appendDialogTitlePriceFilter(title, filterPricePerspective)}`}
        headerDate={formatDateStr2(pageDate, true)}
      />
    ))
  }

  const columns: any[] = [
    {
      dataIndex: 'options',
      width: config.optionsWidth,
      left: config.tabWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
        const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        return (
          <TableOptionsCell
            text={options}
            changePercent={changePercent}
            record={record}
            width={config.optionsWidth}
            metricName="All"
            isDeepBorderRight
          />
        )
      },
    },
    {
      dataIndex: 'categoryKey',
      width: config.categoryWidth,
      left: config.tabWidth + config.optionsWidth,
      render: (text, record) => {
        return (
          <TableCategoryCell
            text={isCompetitorView ? record.categoryName : record.vendorName}
            record={record}
            width={config.categoryWidth}
            isCompetitorView={isCompetitorView}
            isDeepBorderRight
          />
        )
      },
    },

    {
      dataIndex: 'avgPriceValue',
      width: config.avgPriceValueWidth + config.avgPriceChangePercentWidth + config.avgPriceChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell 
              isDeepBorderRight
            />
          )
        }

        const value = numberUtils.formatNumber(record?.avg_price?.value, { isCommaSymbol: true, decimal: getFractionDigits() })

        const isDisableClick = filterSortBy?.sortField === 'seller' && [ CATEGORY_TOTAL, SELECTED_CATEGORY ].includes(record?.categoryName)
        const avgPriceChangePercentValue = numberUtils.formatNumber(record?.avg_price?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        const avgPriceChangeValue = numberUtils.formatNumber(record?.avg_price?.change, { decimal: getFractionDigits(), isCommaSymbol: true })

        return (
          <TableCell
            width={config.avgPriceValueWidth + config.avgPriceChangePercentWidth + config.avgPriceChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.avgPriceValueWidth} 
              isFirstMetric
              testId="avgPriceValue"
            />
            <TableCellWithSymbol
              isComparisonField
              isShadowBg
              text={avgPriceChangePercentValue}
              record={record}
              width={config.avgPriceChangePercentWidth}
              isClickable={record?.avg_price?.change_percent && !isDisableClick}
              onClick={async () => {
                await onShowAvgPriceModal(record)
              }}
              testId="avgPriceChangePercent"
            />
            <TableCellWithSymbol
              isComparisonField
              text={avgPriceChangeValue}
              record={record}
              width={config.avgPriceChangeWidth}
              isEmptyBorderRight
              isClickable={record?.avg_price?.change && !isDisableClick}
              onClick={async () => {
                await onShowAvgPriceModal(record)
              }}
              testId="avgPriceChange"
            />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'index',
      width: config.indexWidth + config.indexChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.index?.value, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })

        const isCustomer = record.vendorCode === storage.getCustomerVendor()
        const indexChangeValue = numberUtils.formatNumber(record?.index?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        return (
          <TableCell
            width={config.indexWidth + config.indexChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.indexWidth} 
              testId="indexValue"
            />
            {
              isCustomer ? (
                <TableCell 
                  isComparisonField 
                  isEmptyBorderRight 
                  isShadowBg 
                  width={config.indexChangeWidth} 
                  testId="indexChange"
                />
              ) : (
                <TableCellWithSymbol 
                  isComparisonField 
                  isEmptyBorderRight 
                  isShadowBg 
                  text={indexChangeValue} 
                  record={record} 
                  width={config.indexChangeWidth} 
                  testId="indexChange"
                />
              )
            }
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'frequentPriceValue',
      width: config.frequentPriceValueWidth + config.frequentPriceChangePercentWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.most_frequent?.value, { isCommaSymbol: true, decimal: getFractionDigits() })

        const frequentPriceChangePercentValue = numberUtils.formatNumber(record?.most_frequent?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })

        return (
          <TableCell
            width={config.frequentPriceValueWidth + config.frequentPriceChangePercentWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.frequentPriceValueWidth} 
              testId="frequentPriceValue"
            />
            <TableCellWithSymbol 
              isComparisonField 
              isEmptyBorderRight 
              isShadowBg 
              text={frequentPriceChangePercentValue} 
              record={record} 
              width={config.frequentPriceChangePercentWidth} 
              testId="frequentPriceChangePercent"
            />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'disEffectValue',
      width: config.disEffectValueWidth + config.disEffectChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.discount_effect?.value, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })
        const disEffectChangeValue = numberUtils.formatNumber(record?.discount_effect?.change, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.disEffectValueWidth + config.disEffectChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.disEffectValueWidth} 
              testId="disEffectValue"
            />
            <TableCellWithSymbol 
              isComparisonField 
              isEmptyBorderRight 
              isShadowBg 
              text={disEffectChangeValue} 
              record={record} 
              width={config.disEffectChangeWidth} 
              testId="disEffectChange"
            />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'disDepthValue',
      width: config.disDepthValueWidth + config.disDepthChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.discount_depth?.value, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })
        const disDepthChangeValue = numberUtils.formatNumber(record?.discount_depth?.change, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.disDepthValueWidth + config.disDepthChangeWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.disDepthValueWidth}
              testId="disDepthValue"
            />
            <TableCellWithSymbol 
              isComparisonField 
              isEmptyBorderRight 
              isShadowBg 
              text={disDepthChangeValue} 
              record={record} 
              width={config.disDepthChangeWidth}
              testId="disDepthChange"
            />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'disWidthValue',
      width: config.disWidthValueWidth + config.disWidthChangeWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.discount_width?.value, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })
        const disWidthChangeValue = numberUtils.formatNumber(record?.discount_width?.change, { isCentuple: true, isPercentDecimal: true, isCommaSymbol: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.disWidthValueWidth + config.disWidthChangeWidth}
            isEmptyBorderBottom
            isDeepBorderRight
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.disWidthValueWidth} 
              testId="disWidthValue" 
            />
            <TableCellWithSymbol 
              isComparisonField 
              isEmptyBorderRight 
              isShadowBg 
              text={disWidthChangeValue} 
              record={record} 
              width={config.disWidthChangeWidth} 
              testId="disWidthChange" 
            />
          </TableCell>
        )
      },
    },
  ]
  .filter(item => [ ...fieldValue, priceInsightsColumns.OPTIONS, priceInsightsColumns.CATEGORY_KEY ].includes(item.dataIndex))
  .sort((a, b) => sortValue.indexOf(a.dataIndex) - sortValue.indexOf(b.dataIndex))

  const onDrag = (dragKey, dropKey) => {
    const newSortValue = getDashboardColumnOrder({
      dragKey,
      dropKey,
      dataSource: sortValue,
    })
    setSortValue([ ...newSortValue ])
    storage.setPriceInsightsSort([ ...newSortValue ])
  }

  /* ******************************************************************* */
  const { data, loading } = useDashboardComparison({ moduleName: PRICE_INSIGHT_TABLE_NAME })

  const [ topKpiData, setTopKpiData ] = useState({ marketData: {}, customerData: {} })
  const { data: marketInfoData, loading: marketInfoLoading } = useDashboardMarkInfo({ moduleName: PRICE_INSIGHT_TABLE_NAME })

  const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

  const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const handleData = (data: any) => {
    // 数据不存在直接返回
    if (!data) {
      return {}
    }

    if (isCompetitorView) {
      const {
        tabledata: tabledata1,
        metricsTab: metricsTab1,
      } = handleTableData({
        apiData: data,
        isCompetitorView: true,
        competitorValue: filterCompetitor.map(seller => seller.vendor),
        categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
        expandedRowKeys: expandedRowKeysRef.current,
        subExpandedRowKeysRef: subExpandedRowKeysRef.current,
        region: filterCountry,
      })
      setTabledata1(tabledata1)
      setMetricsTab1(metricsTab1)
      setCsvData({
        [CSV_DATA_PRICE_INSIGHTS]: geneCsvData({ dataSource: tabledata1 }),
      })
      return
    }
    
    const {
      tabledata: tabledata2,
      metricsTab: metricsTab2,
    } = handleTableData({
      apiData: data,
      isCompetitorView: false,
      competitorValue: filterCompetitor.map(seller => seller.vendor),
      categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
      expandedRowKeys: expandedRowKeysRef.current,
      subExpandedRowKeysRef: subExpandedRowKeysRef.current,
      region: filterCountry,
    })
    
    setTabledata2(tabledata2)
    setMetricsTab2(metricsTab2)
    setCsvData({
      [CSV_DATA_PRICE_INSIGHTS]: geneCsvData({ dataSource: tabledata2, isCompetitorView: false }),
    })
  }

  useDeepCompareEffect(() => {
    if (!data) return

    handleData(data)
  }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

  useDeepCompareEffect(() => {
    if (!marketInfoData) return
    setTopKpiData(handleTopKpiData({ data: marketInfoData, filterCountry }))
  }, [ marketInfoData, filterCountry ])

  /* ************************ 表格展开项 ************************** */
  useDeepCompareEffect(() => {
    if (expandedRowKeysRef.current?.length) {
      const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
      firstLevelCategory.push(SELECTED_CATEGORY)
      const vendorList = filterCompetitor.map(f => f.vendor)
      let expandedRowKeys = expandedRowKeysRef.current
      expandedRowKeys = expandedRowKeys.filter(item => {
        const code = item.split('_')[0]
        if (isCompetitorView) {
          return vendorList.includes(code)
        }
        return firstLevelCategory.includes(code)
      })

      expandedRowKeysRef.current = [ ...expandedRowKeys ]
      setRefresh({})
    }
    if (subExpandedRowKeysRef.current?.length) {
      let subExpandedRowKeys = subExpandedRowKeysRef.current
      subExpandedRowKeys = subExpandedRowKeys.filter(item => {
        const arr = item.split('__')
        if (arr.length === 1) {
          return true
        }
        return filterCategory.includes(arr[1])
      })

      subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
      setRefresh({})
    }
    // eslint-disable-next-line
  }, [filterCategory, filterCompetitor, isCompetitorView])

  useScrollBar2('.price-insights-table-header', '.price-insights-table-content', {})

  const tableWidth = config.getTableScrollWidth(fieldValue)

  return (
    <div id={exportId}>
      <Spin spinning={loading || marketInfoLoading || !data}>
        <PriceInsightTopKpi
          marketData={topKpiData?.marketData || {}}
          customerData={topKpiData?.customerData || {}}
        />
        <SizedBox height={40} />
        <ModuleTitle
          type="Dashboard"
          title={PRICE_INSIGHT_TABLE_NAME}
          showComparisonDate
        />
        <SizedBox height={10} />
        <Dialog ref={dialogRef} />
        <Spin spinning={switchLoading}>
          <DashboardTableForm 
            moduleName={PRICE_INSIGHT_TABLE_NAME} 
            fieldElement={(
              <Dropdown
                label="Columns"
                placeholder="Columns"
                title="Columns"
                type={DropdownType.MULTI}
                options={priceInsightsOptions}
                value={fieldValue}
                onOk={value => {
                  setFieldValue(value)
                  storage.setPriceInsightsFields(value as string[])
                }}
              />
            )}
          />
          <div style={{ height: 20, width: 1 }} />
          <Thead
            width={tableWidth}
            isCompetitorView={isCompetitorView}
            fieldList={fieldValue}
            sortList={sortValue}
            onDrag={onDrag}
          />
          {
            isCompetitorView ? (
              <CompetitorView
                tableWidth={tableWidth - config.tabWidth}
                columns={columns}
                data={tabledata1}
                tab={metricsTab1}
                setMetricsTab={setMetricsTab1}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            ) : (
              <CategoryView
                tableWidth={tableWidth - config.tabWidth}
                columns={columns}
                data={tabledata2}
                tab={metricsTab2}
                setMetricsTab={setMetricsTab2}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            )
          }
        </Spin>
        <SizedBox height={zoom > 1 ? 100 * zoom : 80} />
      </Spin>
    </div>
  )
}

export const CompetitorView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
  tableWidth,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, [])
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classNames([ styles.metricsContent, 'price-insights-table-content' ])}>
      <NornaTab
        showHeader={false}

        categoryToVendor={true}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        style={{ width: tableWidth }}
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={true}
        key="competitorview"
      />
    </div>
  )
}

export const CategoryView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
  tableWidth,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, subExpandedKeys)
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classNames([ styles.metricsContent, 'price-insights-table-content' ])}>
      <NornaTab
        showHeader={false}

        categoryToVendor={false}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        style={{ width: tableWidth }}
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={false}
        key="categoryview"
      />
    </div>
  )
}
