
export const releaseNote = {
    version: '2.2.14',
    date: 'November 25th, 2024',
    content: [
        '• Addition of my Norna function to create shortcut menus',
        '• New metrics about unique products are added to the assortment dashboard',
        '• Ability to hide and move columns in all dashboards',
    ],
}
