import { createSlice } from '@reduxjs/toolkit'
import { storage } from 'utils/storage';
import { intersection } from 'utils/array';
import { getCompetitorOptions as getCompetitorOptionsV2 } from 'utils/optionsUtils'
import { cloneDeep, sortBy } from 'lodash';
import { DynamicDashboardState } from './dynamicDashboardSlice.interface';
import { lsGet } from './filtersSlice';

export const PERIOD_LAST_WEEK = 'Last week'
export const PERIOD_LAST_MONTH = 'Last month'
export const PERIOD_LAST_QUARTER = 'Last quarter'
export const COMPARE_TO_YEAR_ON_YEAR = 'Year on Year'
export const COMPARE_TO_PREVIOUS_PERIOD = 'Previous period'

const initialState: DynamicDashboardState = {
  povValue: '',
  competitorOptions: [],
  competitorValue: [],
  settingOptions: [],
  settingValue: [],
  settingCategoryOptions: [],
  settingCategoryValue: [],
  settingCategoryImages: {},
  settingKpiValue: [],
  categoryValue: [],
  modalExportDisabled: false,
  modalRequests: {},
  modalVisible: false,
  
  apiData: {},
  fetchPayload: {},
  apiLoading: false,

  exportPdfVisible: false,
  exportPdfLoadingObj: {},
  exportPdfSource: 'button',

  // 弹窗
  requests: {
    newnessWeeklyApiData: {},
    newnessWeeklyApiPayload: {},
    newnessWeeklyApiLoading: false,

    newnessProductsApiData: {},
    newnessProductsApiPayload: {},
    newnessProductsApiLoading: false,

    assortmentSizeOfLineApiData: {},
    assortmentSizeOfLineApiPayload: {},
    assortmentSizeOfLineApiLoading: false,

    assortmentSplitLineGraphApiData: {},
    assortmentSplitLineGraphApiPayload: {},
    assortmentSplitLineGraphApiLoading: false,

    assortmentProductsApiData: {},
    assortmentProductsApiPayload: {},
    assortmentProductsApiLoading: false,

    discountEffectLineGraphApiData: {},
    discountEffectLineGraphApiPayload: {},
    discountEffectLineGraphApiLoading: false,

    discountEffectProductsApiData: {},
    discountEffectProductsApiPayload: {},
    discountEffectProductsApiLoading: false,

    soldOutProductsApiData: {},
    soldOutProductsApiPayload: {},
    soldOutProductsApiLoading: false,

    salesPriceLineGraphApiData: {},
    salesPriceLineGraphApiPayload: {},
    salesPriceLineGraphApiLoading: false,

    salesPriceProductsApiData: {},
    salesPriceProductsApiPayload: {},
    salesPriceProductsApiLoading: false,

    fullPriceLineGraphApiData: {},
    fullPriceLineGraphApiPayload: {},
    fullPriceLineGraphApiLoading: false,

    fullPriceProductsApiData: {},
    fullPriceProductsApiPayload: {},
    fullPriceProductsApiLoading: false,
  },
}

export const dynamicDashboardSlice = createSlice({
  name: 'dynamicDashboard',
  initialState,
  reducers: {
    initDynamicDashboardFormData(state, action) {
      const settingsConfig = storage.getDynamicDashboardSettings() as any
      const region = lsGet()?.comparisonQuery?.collection?.query?.regions?.key || action.payload.region
      const competitorOptions = geneCompetitorOptions(region, false)
      state.competitorOptions = [ ...competitorOptions ]
      state.povValue = action.payload.customer
      state.competitorValue = [ competitorOptions?.filter(item => item.key !== action.payload.customer)?.[0]?.key ].filter(item => item)
      state.settingOptions = geneSettingOptions()
      state.settingCategoryOptions = geneSettingCategoryOptions()
      const settingValue = geneSettingValue()
      state.settingValue = settingValue
      const { categories, kpis } = geneCategoryAndKpiBySettingValue(settingValue)
      state.settingCategoryValue = categories
      state.settingKpiValue = kpis
      // 已选 category
      state.categoryValue = categories.slice(0, 1)
      const selectedCategories = filterSettingCategoryValue(settingsConfig?.selected_categories)
      if (Array.isArray(selectedCategories) && !selectedCategories?.length) {
        state.categoryValue = categories.slice(0, 1)
      } else {
        state.categoryValue = selectedCategories
      }
    },
    updateCompetitorOptions(state, action) {
      state.competitorOptions = action.payload
    },
    updateCompetitorValue(state, action) {
      state.competitorValue = action.payload
    },
    updatePovValue(state, action) {
      state.povValue = action.payload
    },
    updateCategoryValue(state, action) {
      state.categoryValue = action.payload
    },
    updateSettingKpiValue(state, action) {
      state.settingKpiValue = action.payload
    },
    updateSettingValue(state, action) {
      const settingValue = action.payload
      state.settingValue = [ ...settingValue ]
      const { categories, kpis } = geneCategoryAndKpiBySettingValue([ ...settingValue ])
      state.settingCategoryValue = categories
      state.settingKpiValue = kpis
      const categoryValue = intersection(cloneDeep(state.categoryValue), categories)
      state.categoryValue = categoryValue
    },
    updateCategoryImages(state, action) {
      state.settingCategoryImages = action.payload || {}
    },
    updateModalExportDisabled(state, action) {
      state.modalExportDisabled = action.payload
    },
    updateModalRequests(state, action) {
      state.modalRequests = {
        ...state.modalRequests,
        ...action.payload,
      }
    },
    updateModalVisible(state, action) {
      state.modalVisible = action.payload
    },
    updateApiData(state, action) {
      state.apiData = action.payload
    },
    updateApiLoading(state, action) {
      state.apiLoading = action.payload
    },
    updateFetchPayload(state, action) {
      state.fetchPayload = action.payload
    },
    updateExportPdfVisible(state, action) {
      state.exportPdfVisible = action.payload
    },
    updateExportPdfLoadingObj(state, action) {
      state.exportPdfLoadingObj = {
        ...state.exportPdfLoadingObj,
        ...action.payload,
      }
    },
    clearExportPdfLoadingObj(state) {
      state.exportPdfLoadingObj = {}
    },
    updateExportPdfSource(state, action) {
      state.exportPdfSource = action.payload
    },
    updateRequests(state, action) {
      state.requests = { 
        ...state.requests, 
        ...action.payload, 
      }
    },
  },
})

export const {
  initDynamicDashboardFormData,
  updateCompetitorOptions,
  updateCompetitorValue,
  updatePovValue,
  updateCategoryValue,
  updateSettingValue,
  updateSettingKpiValue,
  updateCategoryImages,
  updateModalExportDisabled,
  updateModalRequests,
  updateModalVisible,
  updateApiData,
  updateApiLoading,
  updateFetchPayload,
  updateExportPdfVisible,
  updateExportPdfLoadingObj,
  clearExportPdfLoadingObj,
  updateExportPdfSource,
  updateRequests,
} = dynamicDashboardSlice.actions

export const getCompetitorOptions = (state) => state.dynamicDashboard.competitorOptions || []
export const getCompetitorValue = (state) => state.dynamicDashboard.competitorValue
export const getPovValue = (state) => state.dynamicDashboard.povValue
export const getCategoryValue = (state) => state.dynamicDashboard.categoryValue
export const getSettingOptions = (state) => state.dynamicDashboard.settingOptions
export const getSettingValue = (state) => state.dynamicDashboard.settingValue
export const getSettingCategoryValue = (state) => state.dynamicDashboard.settingCategoryValue || []
export const getSettingCategoryOptions = (state) => state.dynamicDashboard.settingCategoryOptions || []
export const getSettingCategoryImages = (state) => state.dynamicDashboard.settingCategoryImages || {}
export const getSettingKpiValue = (state) => state.dynamicDashboard.settingKpiValue || []
export const getModalExportDisabled = (state) => state.dynamicDashboard.modalExportDisabled || false
export const getModalRequests = (state) => state.dynamicDashboard.modalRequests || {}
export const getModalVisible = (state) => state.dynamicDashboard.modalVisible || false
export const getApiData = (state) => state.dynamicDashboard.apiData || {}
export const getApiLoading = (state) => state.dynamicDashboard.apiLoading || false
export const getFetchPayload = (state) => state.dynamicDashboard.fetchPayload || {}
export const getExportPdfVisible = (state) => state.dynamicDashboard.exportPdfVisible || false
export const getExportPdfLoadingObj = (state) => state.dynamicDashboard.exportPdfLoadingObj || {}
export const getExportPdfSource = (state) => state.dynamicDashboard.exportPdfSource || 'button'
export const getRequests = (state) => state.dynamicDashboard.requests || {}

export default dynamicDashboardSlice.reducer

export const PRODUCT_NEWNESS = 'Product newness'
export const ASSORTMENT_MIX = 'Assortment mix'
export const DISCOUNT_EFFECT = 'Discount effect'
export const SOLD_OUT_STATUS = 'Sold out status'
export const SALES_PRICE_PERFORMANCE = 'Sales price performance'
export const FULL_PRICE_PERFORMANCE = 'Full price performance'

export const DYNAMIC_DASHBOARD_KPIS = [ 
  PRODUCT_NEWNESS,
  ASSORTMENT_MIX,
  DISCOUNT_EFFECT,
  SOLD_OUT_STATUS,
  SALES_PRICE_PERFORMANCE,
  FULL_PRICE_PERFORMANCE,
]

export const SETTING_CATEGORY_PREFIX = 'CATEGORY_'
export const SETTING_KPI_PREFIX = 'KPI_'

export function geneCompetitorOptions(region: string, excludeSelf = true) {
  const competitorOptons = getCompetitorOptionsV2({
    region,
    excludeSelf,
    excludeMarket: true,
    excludeSelectedVendor: true,
  })
  return competitorOptons
}

function geneSettingOptions() {
  const categoryList = storage.getCategoryTreeList()
  const settingOptions: {label: string; value?: string; disabled?: boolean}[] = []
  settingOptions.push({ label: 'Settings for categories' })
  sortBy(categoryList, [ 'label' ]).forEach(item => {
    settingOptions.push({ label: item.name, value: `${SETTING_CATEGORY_PREFIX}${item.name}` })
  })
  settingOptions.push({ label: 'Settings KPI' })
  DYNAMIC_DASHBOARD_KPIS.forEach(item => {
    settingOptions.push({ label: item, value: `${SETTING_KPI_PREFIX}${item}` })
  })
  return settingOptions
}

function geneSettingCategoryOptions() {
  const categoryList = storage.getCategoryTreeList()
  let categoryOptions = categoryList.map(item => {
      const newItem: any = {
          value: item.name,
          label: item.name,
      }
      if (Array.isArray(item.list) && item.list?.length) {
          newItem.children = item.list.map(item2 => ({
              value: item2.name,
              label: item2.name,
          }))
      }
      return newItem
  })
  categoryOptions = sortBy(categoryOptions, [ 'label' ])
  return categoryOptions
}

function geneSettingValue() {
  const settings = storage.getDynamicDashboardSettings() || {}
  const categories = settings?.categories || []
  const kpis = settings?.KPIs || []
  const value: string[] = []
  if (Array.isArray(categories) && categories.length) {
    categories.forEach(c => {
      value.push(`${c}`)
    })
  } else {
    const categoryOptions = geneSettingCategoryOptions()
    categoryOptions?.map(item => item.value)?.slice(0, 12)
      .forEach(c => {
        value.push(`CATEGORY_${c}`)
      })
  }
  if (Array.isArray(kpis) && kpis.length) {
    kpis.forEach(k => {
      value.push(`${k}`)
    })
  } else {
    DYNAMIC_DASHBOARD_KPIS.slice(0,6).forEach(c => {
      value.push(`${SETTING_KPI_PREFIX}${c}`)
    })
  }
  return value
}

export function initSettingValue() {
  let value: string[] = []
  const categoryList = storage.getCategoryTreeList()
  value = categoryList.map(item => `${SETTING_CATEGORY_PREFIX}${item.name}`).slice(0, 12)
  value = value.concat(DYNAMIC_DASHBOARD_KPIS.map(item => `${SETTING_KPI_PREFIX}${item}`).slice(0, 6))
  return value
}

function geneCategoryAndKpiBySettingValue(settingValue: string[] = []) {
  const categoryList = settingValue.filter(item => String(item).startsWith(SETTING_CATEGORY_PREFIX))
  const categories: string[] = categoryList.map(item => item.replace(SETTING_CATEGORY_PREFIX, ''))
  const kpiList = settingValue.filter(item => String(item).startsWith(SETTING_KPI_PREFIX))
  let kpis: string[] = []
  if (kpiList.length) {
    kpis = kpiList.map(item => item.replace(SETTING_KPI_PREFIX, ''))
    kpis = kpis.filter(item => DYNAMIC_DASHBOARD_KPIS.includes(item))
    kpis = kpis.sort((a, b) => DYNAMIC_DASHBOARD_KPIS.indexOf(a) - DYNAMIC_DASHBOARD_KPIS.indexOf(b))
  }
  return { categories: Array.from(new Set(filterSettingCategoryValue(categories))), kpis: Array.from(new Set(kpis)) }
}

function filterSettingCategoryValue(categories): string[] {
  const categoryTreeList = storage.getCategoryTreeList()
  const allCategories = categoryTreeList?.map(item => {
    let arr: string[] = []
    if (item?.list?.length) {
      arr = [ ...arr, ...item?.list?.map(item2 => item2?.name) ]
    }
    arr.push(item?.name)
    return arr
  }).flat(3)
  const newCategories = categories.filter(item => allCategories?.includes(item))
  return newCategories
}
