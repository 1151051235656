import { argFnDashboardGraphComparison } from 'graphql/nornaapi'
import { useFetch } from 'libs'
import { useFilterCountry, useFilterCurrency, useFilterGender, useFilterPricePerspective, useFilterSize, useFilterTax, useNoSizeFilter } from 'hooks/useFilter'
import { getMinimumDate, getToday } from 'utils/dateUtils'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { useCompetitorOptions, useRegionOptions } from 'hooks/useOptions'
import { isEqual } from 'lodash'
import { getCategoriesPayload, getDashboardPropertiesPayload, getGroupsPayload, getMarketPayload, getPriceFilterPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { useExcludeProductsComparisonQuery } from 'hooks'
import { useLookbook } from 'hooks/useLookbook'
import { getDisabledCategoryListForSizeFilter } from 'utils/feConfig'
import { getDisabledCategoriesForColorSplitPage } from 'components/layout/Header/components/HeaderFilter/category.util'
import { usePageDate } from 'hooks/usePageDate'
import { useCrossRegionSoldOutModuleData, useGraphButtonModuleData, useSoldOutVendorsModuleData } from 'features/filters/moduleDataSlice.hook'
import { getCompetitorOptionsForGraphModal } from 'utils/optionsUtils'
import { dateRangeUtils } from 'norna-uikit'

export const useDashboardLineGraphData2 = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks, isLookbook } = useLookbook()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { postFn: fetch, data, loading } = useFetch()
    const [ moduleData, setModuleData ] = useSoldOutVendorsModuleData()
    const { graphApiData, graphApiPayload, graphCategoryValue } = moduleData

    /**
     * 发送请求
     */
    const fetchData = () => {
        if (!isLookbook && !graphCategoryValue?.length) return
        if (!competitorOptions.length) return
        const dateRange = `${getMinimumDate()}_${getToday()}`

        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const filterCompetitor = competitorOptions
            .filter(item => ({ vendor: item.vendor, region: item.region }))
            .filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor))

        const competitors = getCompetitorOptionsForGraphModal(filterCountry)
            .map(item => item.value)

        const market = getMarketPayload({
            competitorSellers: filterCompetitor,
            competitorOptions,
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: filterCompetitor,
        })

        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})

        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        
        const query = {
            region: filterCountry,
            targetGroup: targetGroup.filter(item => [ 'Men', 'Women' ].includes(item)),
            currency: filterCurrency,
            inclDiscount: filterPricePerspective,
            competitors,
            // competitors: [ 'calvinklein','tommyhilfiger','boss','lacoste','ralphlauren' ],
            market,
            groups,
            date: dateRangeUtils.from(dateRange),
            comparisonDate: dateRangeUtils.from(dateRange),
            metrics: [ '<S sold out', 'S sold out', 'M sold out', 'L sold out', 'XL sold out', '>XL sold out', 'Sold out', 'Harmonized sold out' ],
            allCategory: graphCategoryValue,
            export: false,
            taxFilter: filterTax,
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        }

        const payload = argFnDashboardGraphComparison(query)

        if (isEqual(payload, graphApiPayload) && Object.keys(graphApiData || {}).length) {
            return
        }

        setModuleData({ 
            graphApiPayload: payload,
            graphApiLoading: true,
        })
        fetch(payload.url, payload)
    }

    useDeepCompareEffect(() => {
        if (!data || isEqual(data, graphApiData)) return
        setModuleData({
            graphApiData: data,
            graphApiLoading: false,
        })
    }, [ data, {} ])

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, comparisonDate, competitorOptions, lookbooks, graphCategoryValue ])

    return {
        fetchData,
        data,
        loading: loading,
    }
}

export const useDashboardLineGraphData3 = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks } = useLookbook()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { regionOptions } = useRegionOptions()
    const { postFn: fetch, data, loading } = useFetch()
    const [ moduleData, setModuleData ] = useCrossRegionSoldOutModuleData()
    const { graphApiData, graphApiPayload, graphCategoryValue } = moduleData

    /**
     * 发送请求
     */
    const fetchData = () => {
        if (!graphCategoryValue.length) return
        if (!competitorOptions.length) return
        if (!regionOptions.length) return
        const dateRange = `${getMinimumDate()}_${getToday()}`

        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const filterCompetitor = competitorOptions
            .filter(item => ({ vendor: item.vendor, region: item.region }))
            .filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor))

        const competitors = getCompetitorOptionsForGraphModal(filterCountry)
            .map(item => item.value)

        const market = getMarketPayload({
            competitorSellers: filterCompetitor,
            competitorOptions,
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers: filterCompetitor,
        })

        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})

        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const query = {
            region: filterCountry,
            targetGroup: targetGroup,
            currency: filterCurrency,
            inclDiscount: filterPricePerspective,
            competitors,
            // competitors: [ 'calvinklein','tommyhilfiger','boss' ],
            market,
            groups,
            date: dateRangeUtils.from(dateRange),
            comparisonDate: dateRangeUtils.from(dateRange),
            metrics: regionOptions.map(item => `${item.key} sold out`),
            allCategory: graphCategoryValue,
            export: false,
            taxFilter: filterTax,
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        }

        const payload = argFnDashboardGraphComparison(query)

        if (isEqual(payload, graphApiPayload) && Object.keys(graphApiData || {}).length) {
            return
        }

        setModuleData({ 
            graphApiPayload: payload,
            graphApiLoading: true,
        })
        fetch(payload.url, payload)
    }

    useDeepCompareEffect(() => {
        if (!data || isEqual(data, graphApiData)) return
        setModuleData({
            graphApiData: data,
            graphApiLoading: false,
        })
    }, [ data, {} ])

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, comparisonDate, competitorOptions, regionOptions, lookbooks, graphCategoryValue ])

    return {
        fetchData,
        data,
        loading: loading,
    }
}

export const useDashboardLineGraphData4 = () => {
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterSize ] = useFilterSize()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks } = useLookbook()
    const { competitorOptions } = useCompetitorOptions()
    const { postFn: fetch, data, loading } = useFetch()

    const [ moduleData, setModuleData ] = useGraphButtonModuleData()
    const { 
        apiData, apiPayload, categoryValue, metricValue, regionValue, dateRangeValue, competitorValue,
    } = moduleData

    /**
     * 发送请求
     */
    const fetchData = (tmpCompetitorValue?: any[]) => {
        if (!metricValue?.length || !dateRangeValue) return
        if (!competitorOptions.length) return

        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const competitors = (tmpCompetitorValue || competitorValue).map(c => c.vendor)
        const competitorSellers = competitors.map(c => ({ vendor: c, region: regionValue }))

        const market = getMarketPayload({
            competitorSellers,
            competitorOptions,
            returnType: 'vendor',
        })

        const groups = getGroupsPayload({
            competitorSellers,
        })

        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})

        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        
        let allCategories = [ ...categoryValue ]
        if (metricValue.some(item => (item || '').startsWith('Color_'))) {
            allCategories = [ ...categoryValue ]
                .filter(item => {
                    return !getDisabledCategoriesForColorSplitPage().includes(item)
                })
                .filter(item => {
                    if (!filterSize?.checked) {
                        return item
                    }
                    return !getDisabledCategoryListForSizeFilter().includes(item)
                })
        }

        const query = {
            region: regionValue,
            targetGroup,
            currency: filterCurrency,
            inclDiscount: filterPricePerspective,
            competitors,
            market,
            groups,
            date: dateRangeUtils.from(dateRangeValue),
            comparisonDate: dateRangeUtils.from(dateRangeValue),
            metrics: metricValue,
            allCategory: getCategoriesPayload(allCategories),
            export: false,
            taxFilter: filterTax,
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        }

        const payload = argFnDashboardGraphComparison(query)
        payload.url = 'dashboard/graph_comparison_alias'

        if (isEqual(payload, apiPayload) && Object.keys(apiData || {}).length) {
            return
        }

        setModuleData({ 
            apiPayload: payload,
            apiLoading: true,
        })
        fetch(payload.url, payload)
    }

    useDeepCompareEffect(() => {
        if (!data || isEqual(data, apiData)) return
        setModuleData({
            apiData: data,
            apiLoading: false,
        })
    }, [ data, {} ])

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [
        comparisonQuery, 
        dateRangeValue, 
        categoryValue, 
        metricValue, 
        regionValue, 
        competitorOptions, 
        {},
    ])

    return {
        fetchData,
        data,
        loading: loading,
    }
}
