export const tabWidth = 148
export const optionsWidth = 100
export const categoryWidth = 195
export const weekdayWidth = 120
export const arrowWidth = 40

export const getTableScrollWidth = (fieldList: string[] = []) => {
    const width = tabWidth + arrowWidth + optionsWidth + categoryWidth + weekdayWidth * fieldList.length
    return width
}

