import React, { useCallback, useContext, useEffect, useImperativeHandle, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IS_LIVE_ENV, regionCurrency } from 'consts'
import { initFilter, updateFilerFn, updateFilters } from 'features/filters/filtersSlice'
import { getCacheData, useRegionsFn } from 'features/filters/cacheDataSlice'
import { Tree, TreeInteractiveType } from 'componentsv2/Tree/'
import { FILTER_LABELS, getTreeVals } from 'componentsv2/Tree/utils'
import { useLookbook } from 'hooks/useLookbook'
import { cloneDeep } from 'lodash'
import { updateFilterTags } from 'features/filters/globalDataSlice'
import { useCreateCollection, useCollectionList } from 'hooks/api/useCollectionData'
import { ActionBoxContext } from '../../../../common/InfoBox/context'
import { findDistinct, formatCollection, genFilterTreeItem, useTreeData } from './utils'
import { HeaderFilterContentType, TreeRefProps } from './HeaderFilter.type'
import { useUrlParam } from '../../../../common'
import { getFilterGenderChildrenValue } from './gender.util'
import { handleFilterCategoryMaxCount } from './category.util'
import { loadingBar } from 'hooks'

export const HeaderFilterContent = React.forwardRef(({
  hideFilter,
  regions,
  flatQuery,
  sellers, filterVendorEuropeOrCountry,
  interactiveType = TreeInteractiveType.HOVER,
  cacheData,
  noticeFn,
  onClickMask,
}: HeaderFilterContentType, ref) => {
  const dispatch = useDispatch()
  const searchObj = useUrlParam()
  const saveCbRef = React.useRef<Function>()

  const { isLookbook } = useLookbook()
  const { ourSeller } = useSelector(getCacheData)
  const lastSelectedSizeFilter = flatQuery?.sizeFilter || {}

  /* ************************************* My saved filters ******************************************* */
  const { collectionList, refreshCollectionList } = useCollectionList()
  const { createCollection } = useCreateCollection()
  const [ optionSelectionFilter, setOptionSelectionFilter ] = useState<any[]>([])

  /**
   * Load filters 点击具体 filter 进入该方法
   */
  const initFilterFn = React.useCallback(filter => { // init filter value from filter list item which is from api
    if (!filter) return
    const comparison = formatCollection(filter)
    dispatch(initFilter(comparison))
    /**
     * noticeFn(null) 将 Filter 上方的 Current filter 置空，从而取最新的 filter 选中值
     * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/62
     */
    noticeFn(null)
  }, [ dispatch, noticeFn ])

  useEffect(() => {
    if (collectionList.length) {
      const optionSelectionFilter: any = []
      // eslint-disable-next-line array-callback-return
      collectionList.map(item => {
        optionSelectionFilter.push({
          key: item.id,
          description: item.name, ...item,
        })
      })
      setOptionSelectionFilter(optionSelectionFilter)
      saveCbRef.current?.({ children: genFilterTreeItem(optionSelectionFilter, initFilterFn, hideFilter) }) // re-set filter tree item for saving when  saveCbRef exists
    }
  }, [ collectionList, setOptionSelectionFilter, initFilterFn, hideFilter ])

  const actionBox = useContext(ActionBoxContext)

  const cbGetVals = React.useCallback((data, onlyDisplayable = false) => {
    const newData = data || treeRef.current?.getVals?.()
    const treeRes = getTreeVals(newData, isLookbook, onlyDisplayable)
    if (data && onlyDisplayable) {
      if (newData.find(item => item.label === FILTER_LABELS.CATEGORY)?.active !== false) {
        const newData = cloneDeep(data)
        /**
         * 2022-09-27
         * A maximum of three categories can be selected for the production environment
         */
        const sizeFilter = newData.find(item => item.label === FILTER_LABELS.SIZE_FILTER)
        newData[0].children = handleFilterCategoryMaxCount({
          categoryChildren: newData[0].children,
          sizeFilterChecked: sizeFilter?.active,
        })
        treeRef.current?.updateTreeData(newData)
      }
    }

    treeRes.region = treeRes.region && treeRes.region.length ? treeRes.region[0] : ''

    return treeRes
  }, [isLookbook]); // eslint-disable-line

  const saveFilterFn = React.useCallback(async saveObj => {
    try {
      saveCbRef.current = saveObj.setTreeItem
      loadingBar.start()
      const title = saveObj.name || saveObj.title
      const res = cbGetVals(null)
      const query = {
        ...updateFilerFn(res, flatQuery.comparisonQuery),
        name: title,
      }

      await createCollection({
        name: title, 
        query: query?.collection?.query, 
        sellers: res.vendor,
      })

      /**
       * Save filter 操作后，将当前 save 的选中值更新到 redux 中的 comparisonQuery 大变量中
       */
      dispatch(updateFilters(res))
      // 刷新 collection
      refreshCollectionList()
      loadingBar.done()
      hideFilter?.()
    } catch (e) {
      console.log(e)
    }
  }, [ loadingBar, cbGetVals, flatQuery.comparisonQuery, createCollection, refreshCollectionList, hideFilter, dispatch ])//

  useImperativeHandle(ref, () => ({ onChange: e => cbGetVals(null) }), [ cbGetVals ])

  const distinctRegions = useRegionsFn(findDistinct(regions, 'region'))
  const regionsFn = useCallback(() => (distinctRegions || []).map(region => ({
    ...region,
    currency: regionCurrency[region.key] || (regions || []).find(reg => reg.region === region.key).currency,
    isSelected: true,
  })), [ distinctRegions, regions ])

  const treeRef = React.useRef<TreeRefProps>()

  const onUpdateFilterTags = (data: any) => {
    if (!data) return
    const newData = cbGetVals(data)
    dispatch(updateFilterTags(newData))
  }

  const onTreeChange = (data: any) => {
    if (data.find(item => item.label === FILTER_LABELS.LOOKBOOK_FILTER)?.active) {
      return
    }
    if (!data) data = treeRef.current?.getVals?.()
    const selected = cbGetVals(data, false)
    if (selected.gender.length && lastSelectedSizeFilter?.checked) {
      const childrenValue = getFilterGenderChildrenValue()
      selected.sizeFilter.checked = !selected.gender.filter(item => !childrenValue.includes(item)).length
    }
    noticeFn(selected)
  }

  const { treeData } = useTreeData({
    flatQuery,
    optionSelectionFilter,
    actionBox, ourSeller, searchObj, cacheData, regionsFn, sellers,
    filterVendorEuropeOrCountry, initFilterFn, saveFilterFn,
    hideFilter,
    onClickMask,
    onUpdateFilterTags,
    onMaterialFilterChange: (value: any = {}) => {
      const data = treeRef.current?.getVals?.()
      data.forEach(item => {
        if (item.label === FILTER_LABELS.MATERIAL_FILTER) {
          item.active = value.checked
          item.val = { ...item.val, ...value }
        }
      })
      onTreeChange(data)
    },
    onColorFilterChange: (value: any = {}) => {
      const data = treeRef.current?.getVals?.()
      data.forEach(item => {
        if (item.label === FILTER_LABELS.COLOR_FILTER) {
          item.active = value.checked
          item.val = { ...item.val, ...value }
        }
      })
      onTreeChange(data)
    },
  })

  return (
    <Tree
      ref={treeRef}
      lineAfterIndexArr={IS_LIVE_ENV ? [ FILTER_LABELS.COMPETITOR, FILTER_LABELS.MATERIAL_FILTER ] : [ FILTER_LABELS.COMPETITOR, FILTER_LABELS.NO_SIZE ]}
      treeData={treeData}
      interactiveType={interactiveType}
      changeNotice={onTreeChange}
      anchor="right"
      onClickMask={onClickMask}
    />
  )
})
HeaderFilterContent.displayName = 'HeaderFilterContent'
