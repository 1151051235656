import React, { useEffect, useRef, useState } from 'react'
import { TabItemWithChildProps } from 'componentsv2/TradingTable/types'
import { SELECTED_CATEGORY } from 'consts'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import { Spin } from 'componentsv2'
import { TableCategoryCell, TableCell, TableCellWithSymbol, TableOptionsCell } from 'componentsv2/TradingTableV2'
import { NornaTab, NornaTable } from 'componentsv2/TradingTableV3'
import { SizedBox } from 'componentsv2/SizedBox'
import { useLoadingBar } from 'hooks/useLoadingBar'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ASSORTMENT_INSIGHT_TABLE_NAME } from 'configs'
import { useDashboardComparison, useDashboardMarkInfo } from 'hooks/api/useDashboardTableData'
import { getExportId } from 'utils'
import { DashboardTableForm } from 'componentsv2/business/DashboardTableForm'
import { useModuleData } from 'hooks/useModuleData'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { useLookbook } from 'hooks/useLookbook'
import { getAllFilterCategoryValue } from 'utils/filterUtils'
import { getExpandedKeysByMetricTab, getSubExpandedKeysByMetricTab } from 'utils/dashboardPageUtils'
import styles from './styles.module.scss'
import * as config from './config'
import { geneCsvData, handleTableData, handleTopKpiData } from './utils'
import { AssortmentInsightTopKpi } from '../AssortmentInsightTopKpi'
import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { TableLockCell } from 'componentsv2/TradingTableV2/TableCell/TableLockCell'
import { useCsvDataModuleData } from 'features/filters/moduleDataSlice.hook'
import { CSV_DATA_ASSORTMENT_INSIGHTS } from 'features/filters/moduleDataSlice.const'
import { numberUtils } from 'norna-uikit'
import Thead from './Thead'
import { useScrollBar2 } from 'hooks/useScrollBar'
import classNames from 'classnames'
import { assortmentInsightsColumns, assortmentInsightsOptions } from 'consts/dashboardTableFields'
import { getDashboardColumnOrder } from 'utils/vendorOrderUtils'
import { Dropdown, DropdownType } from 'componentsv2/form-elements/Dropdown'
import { storage } from 'utils/storage'

const exportId = getExportId(ASSORTMENT_INSIGHT_TABLE_NAME)

export const AssortmentInsightTable = () => {
  useLoadingBar()
  const [ filterCountry ] = useFilterCountry()
  const [ filterCategory ] = useFilterCategory()
  const [ filterCompetitor ] = useFilterCompetitor()
  const { isLookbook } = useLookbook()
  const expandedRowKeysRef = useRef<string[]>([])
  const subExpandedRowKeysRef = useRef<string[]>([])
  const [ , setRefresh ] = useState({})

  // eslint-disable-next-line
  const [ moduleData, setModuleData ] = useModuleData(ASSORTMENT_INSIGHT_TABLE_NAME)
  const { isCompetitorView, switchLoading } = moduleData

  /* ********************************* field ************************************** */
  const [ fieldValue, setFieldValue ] = useState(storage.getAssortmentInsightsFields())
  const [ sortValue, setSortValue ] = useState(storage.getAssortmentInsightsSort())

  /* ******************************** 表格 *********************************** */
  const dialogRef = React.useRef<DialogRefType>({} as DialogRefType)

  const columns: any[] = [
    {
      dataIndex: 'options',
      width: config.optionsWidth,
      left: config.tabWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }
        const options = numberUtils.formatNumber(record?.Numbers?.value, { isCommaSymbol: true, decimal: 0 })
        const changePercent = numberUtils.formatNumber(record?.Numbers?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        return (
          <TableOptionsCell
            text={options}
            changePercent={changePercent}
            record={record}
            width={config.optionsWidth}
            metricName="All"
            isDeepBorderRight
          />
        )
      },
    },
    {
      dataIndex: 'categoryKey',
      width: config.categoryWidth,
      left: config.tabWidth + config.optionsWidth,
      render: (text, record) => {
        return (
          <TableCategoryCell 
            text={isCompetitorView ? record.categoryName : record.vendorName}
            record={record}
            width={config.categoryWidth}
            isCompetitorView={isCompetitorView}
            isDeepBorderRight
          />
        )
      },
    },

    {
      dataIndex: 'categorySplitValue',
      width: config.categorySplitWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.category_fraction?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const categorySplitChangeValue = numberUtils.formatNumber(record?.category_fraction?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.categorySplitWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.categorySplitValueWidth} isFirstMetric />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={categorySplitChangeValue} record={record} width={config.categorySplitChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'sizeOfLineValue',
      width: config.sizeOfLineWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.size_of_line?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true, isCommaSymbol: true })
        const sizeOfLineChangeValue = numberUtils.formatNumber(record?.size_of_line?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.sizeOfLineWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.sizeOfLineValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={sizeOfLineChangeValue} record={record} width={config.sizeOfLineChangeWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'sizeRatioValue',
      width: config.sizeRatioWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.size_ratio?.value, { decimal: 1 })
        const sizeRatioChangePercentValue = numberUtils.formatNumber(record?.size_ratio?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.sizeRatioWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.sizeRatioValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={sizeRatioChangePercentValue} record={record} width={config.sizeRatioChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'optionRatioValue',
      width: config.optionRatioWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        let value = numberUtils.formatNumber(record?.option_ratio?.value, { decimal: 1 })
        /**
         * 硬编码: zzegna && China, option ratio value = 1
         */
        if (record.vendorCode === 'zzegna' && filterCountry === 'China') {
          value = numberUtils.formatNumber(1, { decimal: 1 })
        }

        let optionRatioChangePercentValue = numberUtils.formatNumber(record?.option_ratio?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        /**
         * 硬编码: zzegna && China, option ratio change = 0
         */
        if (record.vendorCode === 'zzegna' && filterCountry === 'China') {
          optionRatioChangePercentValue = numberUtils.formatNumber(0, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        }

        return (
          <TableCell
            width={config.optionRatioWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.optionRatioValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={optionRatioChangePercentValue} record={record} width={config.optionRatioChangePercentWidth} />
          </TableCell>
        )
      },
    },
    
    {
      dataIndex: 'soldOutValue',
      width: config.soldOutWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.out_of_stock_percentage?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const soldOutChangeValue = numberUtils.formatNumber(record?.out_of_stock_percentage?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.soldOutWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.soldOutValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={soldOutChangeValue} record={record} width={config.soldOutChangeWidth} />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'uniqueOptions',
      width: config.uniqueOptionsWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.unique_options?.value, { isCommaSymbol: true, decimal: 0 })
        const ecoLabelChangeValue = numberUtils.formatNumber(record?.unique_options?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.uniqueOptionsWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.width01} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={ecoLabelChangeValue} record={record} width={config.width02} />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'optionRotationRatio',
      width: config.optionRotationRatioWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.option_rotation_ratio?.value, { isCommaSymbol: true, decimal: 1 })
        const solidPatternChangeValue = numberUtils.formatNumber(record?.option_rotation_ratio?.change_percent, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.optionRotationRatioWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.width01} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={solidPatternChangeValue} record={record} width={config.width02} />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'lifeSpan',
      width: config.lifeSpanWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(
          record?.option_lifespan?.value, 
          { isCommaSymbol: true, decimal: 1 },
        )
        const changePercentValue = numberUtils.formatNumber(
          record?.option_lifespan?.change_percent, 
          { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true },
        )

        return (
          <TableCell
            width={config.lifeSpanWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell 
              text={value} 
              record={record} 
              width={config.width01} 
            />
            <TableCellWithSymbol 
              isComparisonField 
              isEmptyBorderRight 
              isShadowBg 
              text={changePercentValue} 
              record={record} 
              width={config.width02} 
            />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'ecoLabelValue',
      width: config.ecoLabelWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.eco_label?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const ecoLabelChangeValue = numberUtils.formatNumber(record?.eco_label?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.ecoLabelWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.ecoLabelValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={ecoLabelChangeValue} record={record} width={config.ecoLabelChangeWidth} />
          </TableCell>
        )
      },
    },

    {
      dataIndex: 'solidValue',
      width: config.solidWidth,
      render: (text, record) => {
        if (record?.isLock) {
          return (
            <TableLockCell isDeepBorderRight />
          )
        }

        const value = numberUtils.formatNumber(record?.solid_pattern?.value, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })
        const solidPatternChangeValue = numberUtils.formatNumber(record?.solid_pattern?.change, { isCentuple: true, isPercentDecimal: true, isPercentSymbol: true })

        return (
          <TableCell
            width={config.solidWidth}
            isDeepBorderRight
            isEmptyBorderBottom
            style={{ padding: 0 }}
          >
            <TableCell text={value} record={record} width={config.solidPatternValueWidth} />
            <TableCellWithSymbol isComparisonField isEmptyBorderRight isShadowBg text={solidPatternChangeValue} record={record} width={config.solidPatternChangeWidth} />
          </TableCell>
        )
      },
    },
  ]
  .filter(item => [ ...fieldValue, assortmentInsightsColumns.OPTIONS, assortmentInsightsColumns.CATEGORY_KEY ].includes(item.dataIndex))
  .sort((a, b) => sortValue.indexOf(a.dataIndex) - sortValue.indexOf(b.dataIndex))

  const onDrag = (dragKey, dropKey) => {
    const newSortValue = getDashboardColumnOrder({
      dragKey,
      dropKey,
      dataSource: sortValue,
    })
    setSortValue([ ...newSortValue ])
    storage.setAssortmentInsightsSort([ ...newSortValue ])
  }

  /* ******************************************************************* */
  const { data, loading } = useDashboardComparison({ moduleName: ASSORTMENT_INSIGHT_TABLE_NAME })
  const [ topKpiData, setTopKpiData ] = useState({ marketData: {}, customerData: {} })

  const { data: marketInfoData, loading: marketInfoLoading } = useDashboardMarkInfo({ moduleName: ASSORTMENT_INSIGHT_TABLE_NAME })

  const [ metricsTab1, setMetricsTab1 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata1, setTabledata1 ] = useState<Array<any>>([])

  const [ metricsTab2, setMetricsTab2 ] = useState<TabItemWithChildProps[]>([])
  const [ tabledata2, setTabledata2 ] = useState<Array<any>>([])
  const [ , setCsvData ] = useCsvDataModuleData()

  const handleData = (data: any) => {
    // 数据不存在直接返回
    if (!Object.keys(data || {})?.length) {
      return {}
    }

    if (isCompetitorView) {
      const {
        tabledata: tabledata1,
        metricsTab: metricsTab1,
      } = handleTableData({
        apiData: data,
        isCompetitorView: true,
        competitorValue: filterCompetitor.map(seller => seller.vendor),
        categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
        expandedRowKeys: expandedRowKeysRef.current,
        subExpandedRowKeysRef: subExpandedRowKeysRef.current,
        region: filterCountry,
      })
      setTabledata1(tabledata1)
      setMetricsTab1(metricsTab1)
      setCsvData({ 
        [CSV_DATA_ASSORTMENT_INSIGHTS]: geneCsvData({ dataSource: tabledata1 }),
      })
      return
    }
    
    const {
      tabledata: tabledata2,
      metricsTab: metricsTab2,
    } = handleTableData({
      apiData: data,
      isCompetitorView: false,
      competitorValue: filterCompetitor.map(seller => seller.vendor),
      categoryValue: isLookbook ? getAllFilterCategoryValue() : filterCategory,
      expandedRowKeys: expandedRowKeysRef.current,
      subExpandedRowKeysRef: subExpandedRowKeysRef.current,
      region: filterCountry,
    })
    
    setTabledata2(tabledata2)
    setMetricsTab2(metricsTab2)
    setCsvData({ 
      [CSV_DATA_ASSORTMENT_INSIGHTS]: geneCsvData({ dataSource: tabledata2, isCompetitorView: false }), 
    })
  }

  useDeepCompareEffect(() => {
    if (!data) return

    handleData(cloneDeep(data))
  }, [ data, filterCompetitor, isCompetitorView, expandedRowKeysRef.current, subExpandedRowKeysRef.current, {} ])

  useDeepCompareEffect(() => {
    if (!marketInfoData) return
    setTopKpiData(handleTopKpiData({ data: marketInfoData, filterCountry }))
  }, [ marketInfoData, filterCountry ])

  /* ************************ 表格展开项 ************************** */
  useDeepCompareEffect(() => {
    if (expandedRowKeysRef.current?.length) {
      const firstLevelCategory = getFirstLevelCategories({ selectedCategories: filterCategory })
      firstLevelCategory.push(SELECTED_CATEGORY)
      const vendorList = filterCompetitor.map(f => f.vendor)
      let expandedRowKeys = expandedRowKeysRef.current
      expandedRowKeys = expandedRowKeys.filter(item => {
        const code = item.split('_')[0]
        if (isCompetitorView) {
          return vendorList.includes(code)
        }
        return firstLevelCategory.includes(code)
      })

      expandedRowKeysRef.current = [ ...expandedRowKeys ]
      setRefresh({})
    }
    if (subExpandedRowKeysRef.current?.length) {
      let subExpandedRowKeys = subExpandedRowKeysRef.current
      subExpandedRowKeys = subExpandedRowKeys.filter(item => {
        const arr = item.split('__')
        if (arr.length === 1) {
          return true
        }
        return filterCategory.includes(arr[1])
      })

      subExpandedRowKeysRef.current = [ ...subExpandedRowKeys ]
      setRefresh({})
    }
    // eslint-disable-next-line
  }, [filterCategory, filterCompetitor, isCompetitorView])

  useScrollBar2('.assortment-insights-table-header', '.assortment-insights-table-content', {})

  const tableWidth = config.getTableScrollWidth(fieldValue)

  return (
    <div id={exportId}>
      <Spin spinning={loading || marketInfoLoading || !data}>
        <AssortmentInsightTopKpi
          marketData={topKpiData?.marketData || {}}
          customerData={topKpiData?.customerData || {}}
        />
        <SizedBox height={40} />
        <ModuleTitle
          type="Dashboard"
          title={ASSORTMENT_INSIGHT_TABLE_NAME}
          showComparisonDate
        />
        <SizedBox height={10} />
        <Dialog ref={dialogRef} />
        <Spin spinning={switchLoading}>
          <DashboardTableForm 
            moduleName={ASSORTMENT_INSIGHT_TABLE_NAME}
            fieldElement={(
              <Dropdown
                label="Columns"
                placeholder="Columns"
                title="Columns"
                type={DropdownType.MULTI}
                options={assortmentInsightsOptions}
                value={fieldValue}
                onOk={value => {
                  setFieldValue(value)
                  storage.setAssortmentInsightsFields(value as string[])
                }}
              />
            )} 
          />
          <div style={{ height: 20, width: 1 }} />
          <Thead
            width={tableWidth}
            isCompetitorView={isCompetitorView}
            fieldList={fieldValue}
            sortList={sortValue}
            onDrag={onDrag}
          />
          {
            isCompetitorView ? (
              <CompetitorView
                tableWidth={tableWidth - config.tabWidth}
                columns={columns}
                data={tabledata1}
                tab={metricsTab1}
                setMetricsTab={setMetricsTab1}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            ) : (
              <CategoryView
                tableWidth={tableWidth - config.tabWidth}
                columns={columns}
                data={tabledata2}
                tab={metricsTab2}
                setMetricsTab={setMetricsTab2}
                onTabChange={(keys, subKeys) => {
                  expandedRowKeysRef.current = keys
                  subExpandedRowKeysRef.current = subKeys
                }}
              />
            )
          }
        </Spin>
      </Spin>
      <div style={{ width: 1, height: 30, background: 'transparent' }} />
    </div>
  )
}

export const CompetitorView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
  tableWidth,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, [])
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classNames([ styles.metricsContent, 'assortment-insights-table-content' ])}>
      <NornaTab
        showHeader={false}

        categoryToVendor={true}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        style={{ width: tableWidth }}
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={true}
        key="competitorview"
      />
    </div>
  )
}

export const CategoryView = ({
  columns,
  data,
  tab,
  setMetricsTab,
  onTabChange,
  tableWidth,
}) => {
  const [ cateHeight, setCateHeight ] = useState(0)
  const [ expendHeight, setExpendHeight ] = useState(0)
  const [ closeHeight, setCloseHeight ] = useState(0)

  const metricTabFn = a => {
    const metricsTab1: Array<TabItemWithChildProps> = a || tab
    const exHeight = document.querySelectorAll('.expentRow') as NodeListOf<HTMLElement>
    let closeHeightA = closeHeight
    if (exHeight.length >= 1) {
      if (exHeight[0].getBoundingClientRect().height < 10) {
        closeHeightA = exHeight[0].getBoundingClientRect().height
      }
    }
    let index = metricsTab1.findIndex(n => n.active === true)
    setCateHeight(43)
    let subIndex = 0
    if (index > -1) {
      metricsTab1[index].children.filter(n => n.active).forEach(item => {
        subIndex += item.categorycount
      })
    }
    index = index === -1 ? 1 : metricsTab1[index].categorycount - 1
    subIndex += index
    setExpendHeight((subIndex + 1) * 43)
    setCloseHeight(closeHeightA)
    setMetricsTab(metricsTab1)

    const expandedKeys = getExpandedKeysByMetricTab(metricsTab1)
    const subExpandedKeys = getSubExpandedKeysByMetricTab(metricsTab1)
    onTabChange?.(expandedKeys, subExpandedKeys)
  }

  useEffect(() => {
    metricTabFn(null)
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classNames([ styles.metricsContent, 'assortment-insights-table-content' ])}>
      <NornaTab
        showHeader={false}

        categoryToVendor={false}
        metricsTab={tab}
        cateHeight={cateHeight}
        expendHeight={expendHeight}
        metricTabFn={metricTabFn}
        isDashboard={true}
      />
      <NornaTable
        style={{ width: tableWidth }}
        showHeader={false}
        dataSource={data}
        columns={columns}

        isTd={true}
        metricTabFn={metricTabFn}
        metricsTab={tab}
        categoryToVendor={false}
        key="categoryview"
      />
    </div>
  )
}
