import { assortmentInsightsColumns } from 'consts/dashboardTableFields'

export const width01 = 80
export const width02 = 85

export const tabWidth = 148
export const optionsWidth = 115
export const categoryWidth = 195
export const arrowWidth = 40

export const categorySplitValueWidth = width01
export const categorySplitChangeWidth = width02
export const sizeOfLineValueWidth = width01
export const sizeOfLineChangeWidth = width02
export const sizeRatioValueWidth = width01
export const sizeRatioChangePercentWidth = width02
export const optionRatioValueWidth = width01
export const optionRatioChangePercentWidth = width02
export const soldOutValueWidth = width01
export const soldOutChangeWidth = width02
export const ecoLabelValueWidth = width01
export const ecoLabelChangeWidth = width02
export const solidPatternValueWidth = width01
export const solidPatternChangeWidth = width02

export const categorySplitWidth = categorySplitValueWidth + categorySplitChangeWidth
export const sizeOfLineWidth = sizeOfLineValueWidth + sizeOfLineChangeWidth
export const sizeRatioWidth = sizeRatioValueWidth + sizeRatioChangePercentWidth
export const optionRatioWidth = optionRatioValueWidth + optionRatioChangePercentWidth
export const soldOutWidth = soldOutValueWidth + soldOutChangeWidth
export const ecoLabelWidth = ecoLabelValueWidth + ecoLabelChangeWidth
export const solidWidth = solidPatternValueWidth + solidPatternChangeWidth
export const uniqueOptionsWidth = width01 + width02
export const optionRotationRatioWidth = width01 + width02
export const lifeSpanWidth = width01 + width02

export const getTableScrollWidth = (fieldList: string[] = []) => {
    let width = tabWidth + arrowWidth + optionsWidth + categoryWidth
    if (fieldList.includes(assortmentInsightsColumns.CATEGORY_SPLIT_VALUE)) {
        width += categorySplitWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.SIZE_OF_LINE_VALUE)) {
        width += sizeOfLineWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.SIZE_RATIO_VALUE)) {
        width += sizeRatioWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.OPTION_RATIO_VALUE)) {
        width += optionRatioWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.SOLD_OUT_VALUE)) {
        width += soldOutWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.UNIQUE_OPTIONS_VALUE)) {
        width += uniqueOptionsWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.ROTATION_RATIO_VALUE)) {
        width += optionRotationRatioWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.LIFE_SPAN_VALUE)) {
        width += lifeSpanWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.ECO_LABEL_VALUE)) {
        width += ecoLabelWidth
    }
    if (fieldList.includes(assortmentInsightsColumns.SOLID_VALUE)) {
        width += solidWidth
    }
    return width
}
