import { Flex, Input } from 'antd'
import { MultiCategoryDropdown } from 'componentsv2/business/CategoryDropdown'
import { CurrencyDropdown } from 'componentsv2/business/CurrencyDropdown'
import { GenderDropdown } from 'componentsv2/business/GenderDropdown'
import { RegionDropdown } from 'componentsv2/business/RegionDropdown'
import { Button, MultiSelect } from 'druikit'
import { DYNAMIC_DASHBOARD_KPIS, geneCompetitorOptions } from 'features/filters/dynamicDashboardSlice'
import { useFilterCategory, useFilterCountry, useFilterGender } from 'hooks/useFilter'
import { cloneDeep } from 'lodash'
import { arrayUtils } from 'norna-uikit'
import CompetitorTreeSelect from 'pages/DynamicDashboard/components/QueryForm/CompetitorTreeSelect'
import React, { useState } from 'react'
import { getCurrencyByRegion } from 'utils'
import { storage } from 'utils/storage'
import styles from './OrderForm.module.scss'
import classNames from 'classnames'

export interface OrderData {
    povValue: string;
    competitorValue: string[];
    regionValue: string;
    currencyValue: string;
    genderValue: string[];
    categoryValue: string[];
    kpiValue: string[];
    frequencyValue: string[];
    receiveEmailValue: string;
}

const OrderForm = ({
    onOrder,
    loading = false,
}: {
    onOrder: (data: OrderData) => void;
    loading?: boolean;
}) => {
    const [ filterGender ] = useFilterGender()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCategory ] = useFilterCategory()

    const [ povValue, setPovValue ] = useState(storage.getCustomerVendor())
    const [ regionValue, setRegionValue ] = useState(filterCountry)
    const [ currencyValue, setCurrencyValue ] = useState(getCurrencyByRegion(filterCountry))
    const [ genderValue, setGenderValue ] = useState<string[]>(filterGender)
    const [ competitorValue, setCompetitorValue ] = useState<string[]>(geneCompetitorOptions(regionValue, true)?.slice(0, 1)?.map(item => item.vendor))
    const [ categoryValue, setCategoryValue ] = useState(filterCategory)
    const kpiOptions = DYNAMIC_DASHBOARD_KPIS.map(item => ({ value: item, label: item }))
    const [ kpiValue, setKpiValue ] = useState<string[]>(DYNAMIC_DASHBOARD_KPIS)
    const [ frequencyValue, setFrequencyValue ] = useState([ '* * Mon' ])
    const [ receiveEmailValue, setReceiveEmailValue ] = useState('')

    return (
        <Flex wrap="wrap" gap={10} align="end">
                <CompetitorTreeSelect
                    region={regionValue}
                    multiple={false}
                    label="Point of view"
                    placeholder="Point of view"
                    style={{ marginRight: 0 }}
                    value={[ povValue ]}
                    onChange={value => {
                        setPovValue(value[0])

                        const newCompetitorValue = cloneDeep(competitorValue)
                        if (newCompetitorValue.includes(value[0])) {
                            if (newCompetitorValue.length === 1) {
                                const competitorOptions = geneCompetitorOptions(regionValue, false)
                                setCompetitorValue([ competitorOptions.filter(item => item?.key !== value[0])?.[0]?.key ])
                            } else {
                                setCompetitorValue(newCompetitorValue.filter(item => item !== value[0]))
                            }
                        }
                    }}
                />
                <CompetitorTreeSelect
                    region={regionValue}
                    style={{ marginRight: 0 }}
                    excludeVendor={povValue}
                    value={competitorValue}
                    onChange={value => {
                        setCompetitorValue(value)
                    }}
                />
                <RegionDropdown
                    value={regionValue}
                    whetherUpdateCurrency
                    onChange={(value) => {
                        const competitorOptions = geneCompetitorOptions(value, false)

                        let newPovValue = povValue
                        if (!competitorOptions.find(item => item.key === povValue)) {
                            newPovValue = competitorOptions[0]?.key
                        }

                        const oldCompetitorValue = cloneDeep(competitorValue)?.filter(item => item !== povValue)
                        let newCompetitorValue = arrayUtils.intersection(oldCompetitorValue, competitorOptions.map(item => item.key))
                        if (!newCompetitorValue.length) {
                            newCompetitorValue = [ competitorOptions?.filter(item => item.key !== newPovValue)?.[0]?.key ].filter(item => item)
                        }
                        setCompetitorValue([ ...newCompetitorValue ].filter(item => item))

                        // currency
                        const currency = getCurrencyByRegion(value as string)
                        setCurrencyValue(currency)

                        setRegionValue(value)
                    }}      
                />
                <GenderDropdown
                    value={genderValue}
                    onChange={setGenderValue}
                />
                <CurrencyDropdown
                    value={currencyValue}
                    onChange={setCurrencyValue}
                />
                <MultiCategoryDropdown
                    excludeTotal
                    multiple
                    minCount={1}
                    value={categoryValue}
                    onChange={value => {
                        setCategoryValue(value)
                    }}
                />
                <MultiSelect
                    label="KPI"
                    placeholder="KPI"
                    options={kpiOptions}
                    value={kpiValue}
                    onChange={setKpiValue}
                />
                <MultiSelect 
                    label="Frequency"
                    placeholder="Frequency"
                    options={[ {
                        label: 'Weekly',
                        value: '* * Mon',
                    }, {
                        label: 'Monthly',
                        value: '1 * *',
                    } ]}
                    value={frequencyValue}
                    onChange={setFrequencyValue}
                />
                <div className={styles.formItem}>
                    <div className={classNames([ styles.label, styles.frequencyLabel ])}>
                        Receive email
                    </div>
                    <Input
                        className={classNames([ styles.input, styles.emailInput ])}
                        style={{ width: 200 }}
                        placeholder="Receive email"
                        value={receiveEmailValue}
                        onChange={e => setReceiveEmailValue(e.target.value)}
                    />
                </div>
                <Button 
                    type="danger"
                    loading={loading}
                    disabled={Boolean(!frequencyValue || !receiveEmailValue)}
                    onClick={() => {
                        onOrder({
                            povValue,
                            competitorValue,
                            regionValue,
                            currencyValue,
                            genderValue,
                            categoryValue,
                            kpiValue,
                            frequencyValue,
                            receiveEmailValue,
                        })
                    }}
                >
                    Order
                </Button>
        </Flex>
    )
}

export default OrderForm
