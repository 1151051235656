import { getIsIncludeChildrenFromApi } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { ALL_COUNTRIES, CATEGORY_TOTAL, IS_LIVE_ENV, SELECTED_VENDORS } from 'consts'
import { GENDER_CHILDREN, QA_BUTTON_TARGET_PRODUCT_DATA, QA_BUTTON_TARGET_TO_DB3, QA_BUTTON_TARGET_TO_SIZE_PRICE_MONITOR, QA_BUTTON_TARGET_UNDO_DB3, QA_BUTTON_TARGET_UNDO_SIZE_PRICE_MONITOR } from 'consts/strings'
import { isSearchForOptionsPage } from 'global/Routes'
import { sortBy, uniq, uniqBy } from 'lodash'
import { CompetitorOption, DropdownOptionProps } from 'types'
import { storage } from 'utils/storage'
import { getGenderChildrenOptions } from './feConfig'
import { sortVendorList } from './array'
import { getVendorNameByCode } from 'utils'

/**
 * 获取 Gender 下拉框选项值
 */
export function getGenderOptions() {
    const { untargets = [] } = storage.getConfigs()
    let targets: string[] = storage.getConfigs()?.targets || []

    /**
     * Browse options 页面并且选中 Gate
     * 如果没有 Children 选项，要硬编码加上 Children 选项
     */
    const isGate = storage.getIsGate()
    if (isSearchForOptionsPage() && isGate && !getIsIncludeChildrenFromApi()) {
        targets.push(GENDER_CHILDREN)
    }

    // Gender 里如果有 Children, 放到最后一位
    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/833#note_1303472121
    const isIncludeChildren = targets.includes(GENDER_CHILDREN)
    if (isIncludeChildren) {
        targets = targets.filter(t => t !== GENDER_CHILDREN)
        targets.push(GENDER_CHILDREN)
    }

    let options: DropdownOptionProps[] = []
    options = [ ...targets, ...untargets ].map(t => {
        return {
            key: t,
            isDisabled: untargets.includes(t),
            description: t,
        }
    })

    const sizeGroupings = getGenderChildrenOptions()
    if (Array.isArray(sizeGroupings) && sizeGroupings?.length) {
        options.forEach(option => {
            if (option.key === GENDER_CHILDREN) {
                option.children = sizeGroupings.map(sizeGrouping => {
                    return {
                        key: sizeGrouping.key,
                        isDisabled: false,
                        description: sizeGrouping.description,
                    }
                })
            }
        })
    }

    return options
}

export function getRegionOptions() {
    const { regions = [], unregions = [] } = storage.getConfigs()
    return [ ...regions, ...unregions ].map(item => ({ key: item, description: item }))
}

export function getCategoryOptions(opt?: { excludeTotal?: boolean }) {
    const categoryTreeList = storage.getCategoryTreeList()

    const categoryOptions = categoryTreeList.map(c1 => {
        const o: any = {
            key: c1.name,
            description: c1.name,
        }

        if (Array.isArray(c1?.list) && c1?.list?.length) {
            o.children = c1.list.map(c2 => ({ key: c2.name, description: c2.name }))
        }

        return o
    })

    if (opt?.excludeTotal !== true) {
        categoryOptions.push({ key: CATEGORY_TOTAL, description: CATEGORY_TOTAL })
    }

    return categoryOptions
}

export function getColorOptions() {
    const properties = storage.getProperties()
    let colorList: string[] = []
    Object.values(properties).forEach((p: any) => {
        colorList = uniq([ ...colorList, ...p?.Colors?.single_values || [] ])
    })
    colorList = sortBy(colorList)

    const colorOptions = colorList.map(c => {
        return {
            key: c,
            description: c,
        }
    })

    return colorOptions
}

export function getMaterialOptions() {
    const properties = storage.getProperties()
    let materialList: string[] = []
    Object.values(properties).forEach((p: any) => {
        materialList = uniq([ 
            ...materialList,  
            ...p?.Material?.single_values || [],
            ...Object.keys(p?.Material?.first_level_grouped_values || {}) || [],
        ])
    })
    materialList = sortBy(materialList)

    const materialOptions = materialList.map(c => {
        return {
            key: c,
            description: c,
        }
    })

    return materialOptions
}

export function getCompetitorOptions({
    region,
    excludeMarket,
    excludeSelf,
    excludeSelectedVendor,
}: {
    region: string;
    excludeMarket?: boolean;
    excludeSelf?: boolean; 
    excludeSelectedVendor?: boolean;
}) {
    if (!region) return []
    let sellers = storage.getSellers()
    if (region === ALL_COUNTRIES) {
        sellers = uniqBy(sellers, 'vendor').map((item: any) => {
            item.region = ALL_COUNTRIES
            return item
        })
    } else {
        sellers = sellers.filter(item => item.region === region)
    }
    let competitorsData = sellers.map(item => {
        return {
            key: item.vendor,
            description: item.name,
            region: item.region,
            vendor: item.vendor,
        }
    })

    if (excludeMarket === true || IS_LIVE_ENV) {
        competitorsData = competitorsData.filter(item => item.key !== 'Market')
    }

    if (excludeSelf === true) {
        competitorsData = competitorsData.filter(item => item.key !== storage.getCustomerVendor())
    }

    if (!excludeSelectedVendor) {
        competitorsData.push({
            key: SELECTED_VENDORS,
            description: SELECTED_VENDORS,
            region: ALL_COUNTRIES,
            vendor: SELECTED_VENDORS,
        } as CompetitorOption)
    }

    competitorsData = uniqBy(competitorsData, 'key')

    competitorsData = sortVendorList({
        vendorList: competitorsData,
        vendorField: 'key',
    })

    return competitorsData
}

/**
 * 获取 Graph modal 里 vendor 的数据源
 */
export function getCompetitorOptionsForGraphModal(region, all = false) {
    let options = getCompetitorOptions({ region, excludeMarket: true, excludeSelectedVendor: true })
    options = options.map(item => ({
        value: item.key,
        label: getVendorNameByCode(item.key),
    }))
    return options
}

export function getQaButtonTargetOptions() {
    return [
        { value: QA_BUTTON_TARGET_TO_DB3, label: 'To DB3' },
        { value: QA_BUTTON_TARGET_UNDO_DB3, label: 'Undo DB3' },
        { value: QA_BUTTON_TARGET_PRODUCT_DATA, label: 'Product data' },
        { value: QA_BUTTON_TARGET_TO_SIZE_PRICE_MONITOR, label: 'To size price monitor' },
        { value: QA_BUTTON_TARGET_UNDO_SIZE_PRICE_MONITOR, label: 'Undo size price monitor' },
    ]
}
