/**
 * Dynamic dashboard report
 */

import axios from 'axios';
import { getPdfBlobDataForDynamicDashboardReport } from 'componentsv2/ExportDropdown/utils';
import { IS_LIVE_ENV } from 'consts';
import { downloadFileByAxios } from 'export';
import { useFilterCountry, useFilterCurrency, useFilterGender } from 'hooks/useFilter';
import { useFetch } from 'libs'
import { CONST_VARS } from 'libs/const';
import { cloneDeep } from 'lodash';
import { dateUtils } from 'norna-uikit';
import { useCategoryValue, useCompetitorValue, useExportPdfLoadingObj, useExportPdfSource, useExportPdfVisible, usePovValue, useSettingKpiValue } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData';
import { useEffect } from 'react';
import useDeepCompareEffect from 'use-deep-compare-effect';
import AuthToken from 'utils/AuthToken';
import { storage } from 'utils/storage';
import qs from 'qs'

export const useDynamicDashboardReportData = () => {
    const { postFn: createConfigFn, loading: createConfigLoading, data: createConfigData } = useFetch()

    const createConfig = async ({
        frequency,
        receiveEmail,
        query,
    }: {
        frequency: string;
        receiveEmail: string;
        query: Record<string, any>;
    }) => {
        const payload = {
            url: '/dynamic_report_config',
            query: {
                frequency,
                receive_email: receiveEmail,
            },
            data: {
                query,
            },
        }
        await createConfigFn(payload.url, payload)
    }

    const { getFn: listConfigsFn, loading: listConfigsLoading, data: listConfigsData } = useFetch()

    const listConfigs = async () => {
        const payload = {
            url: '/dynamic_report_config',
            query: {
                // receive_email: storage.getEmail(),
            },
        }
        await listConfigsFn(payload.url, payload.query)
    }

    const { patchFn: modifyConfigFn } = useFetch()

    const modifyConfig = async ({
        orderId,
        frequency,
        receiveEmail,
        status,
        query,
    }: {
        orderId: string;
        frequency: string;
        receiveEmail: string;
        status: 'active' | 'pause' | 'inactive';
        query: Record<string, any>;
    }) => {
        const payload = {
            url: '/dynamic_report_config',
            query: {
                order_id: orderId,
                frequency,
                receive_email: receiveEmail, 
                status,
            },
            data: {
                query,
            },
        }
        await modifyConfigFn(payload.url, payload)
    }

    const { getFn: listDailyScheduleFn } = useFetch()

    const listDailySchedule = async () => {
        const payload = {
            url: '/dynamic_reports_daily_schedule',
            query: { date: dateUtils.format(new Date(), 'yyyy-MM-dd') },
        }
        const result = await listDailyScheduleFn(payload.url, payload.query)
        return result?.data || []
    }

    const { patchFn } = useFetch()

    const modifyDailySchedule = async (orderId: string, status: 'doing' | 'done' | 'pending') => {
        const payload = {
            url: '/start_to_export_file',
            query: {
                order_id: orderId,
                date: dateUtils.today(), 
            },
            data: {
                status,
            },
        }
        await patchFn(payload.url, payload)
    }

    const uploadPdf = async (orderId, pdfBlob) => {
        const fileOfBlob = new File([ pdfBlob ], new Date() + '.pdf')
        const formData = new FormData()
        formData.append('file', fileOfBlob)

        await axios.request({
            method: 'post',
            url: CONST_VARS.API_URL + '/upload_dynamic_exported_file',
            params: {
                order_id: orderId,
                date: dateUtils.today(),
            },
            data: formData,
            headers: {
                'Content-Type':'multipart/form-data',
                authorization: 'Bearer ' + AuthToken.getAccessToken(),
            },
        })
    }

    const { getFn: listPdfsFn, loading: listPdfsLoading, data: listPdfsData } = useFetch()

    const listPdfs = async (orderId: string) => {
        const payload = {
            url: '/dynamic_reports_historical',
            query: { order_id: orderId },
        }
        await listPdfsFn(payload.url, payload.query)
    }

    const downloadPdf = async (orderId: string, date: string) => {
        const payload = {
            url: 'download_dynamic_exported_pdf',
            query: { order_id: orderId, date },
        }
        await downloadFileByAxios({
            filename: `Norna_Analytics_Dynamic_Dashboard_Report_${date.replaceAll('-', '_')}.pdf`,
            payload,
            method: 'GET',
        })
    }

    return {
        createConfig,
        createConfigLoading,
        createConfigData,
        listConfigs,
        listConfigsLoading,
        listConfigsData,
        modifyConfig,
        listDailySchedule,
        modifyDailySchedule,
        uploadPdf,
        listPdfs,
        listPdfsData,
        listPdfsLoading,
        downloadPdf,
    }
}

export const useDynamicDashboardPolling = () => {
    const getConfig = (test = true) => {
        return {
            seconds: test ? 10 : 60 * 5,
            monitoringEmail: test ? 'zhicheng.bao@norna.ai' : 'testing@norna.ai',
        }
    }
    
    const {
        listDailySchedule,
        uploadPdf,
    } = useDynamicDashboardReportData()

    const [ , setPovValue ] = usePovValue()
    const [ , setCompetitorValue ] = useCompetitorValue()
    const [ , setRegionValue ] = useFilterCountry()
    const [ , setFilterGender ] = useFilterGender()
    const [ , setFilterCurrency ] = useFilterCurrency()
    const [ , setCategoryValue ] = useCategoryValue()
    const [ , setExportPdfVisible ] = useExportPdfVisible()
    const [ exportPdfLoadingObj, setExportPdfLoadingObj, clearExportPdfLoadingObj ] = useExportPdfLoadingObj()
    const [ exportPdfSource, setExportPdfSource ] = useExportPdfSource()
    const [ settingKapiValue, setSettingKapiValue ] = useSettingKpiValue()

    /**
     * 设置状态
     */
    const setScheduleStatus = (orderId) => {
        sessionStorage.setItem('dailyScheduleDoing', orderId)
        sessionStorage.setItem('dailyScheduleStartTime', String(new Date().getTime()))
    }

    const hasDoingSchedule = (dailySchedules) => {
        const dailyScheduleDoing = sessionStorage.getItem('dailyScheduleDoing')
        const dailyScheduleStartTime = sessionStorage.getItem('dailyScheduleStartTime') || 0
        const now = new Date().getTime()
        const diff = Number(now) - Number(dailyScheduleStartTime)
        return dailySchedules
                .filter(item => item.status !== 'done')
                .some(item => item.order_id === dailyScheduleDoing) && diff < 10 * 60 * 1000
    }

    const polling = async () => {
        if (new Date().getHours() < 9) {
            return
        }
        // 请求 configs
        const dailySchedules = await listDailySchedule()
        // 有状态是 doing 的，并且持续时间小于 10 分钟，表示当前正在运行，直接返回
        if (hasDoingSchedule(dailySchedules)) {
            return
        }
        // 找到第一个状态是 pending 的
        const firstPendingItem = dailySchedules.find(item => item.status !== 'done')
        if (!firstPendingItem) {
            return
        }
        // 处理 pending 的 schedule
        setScheduleStatus(firstPendingItem.order_id)

        if (!firstPendingItem?.query?.regionValue) {
            return
        }
        
        // 修改查询条件
        setRegionValue(firstPendingItem.query.regionValue)
        setFilterGender(firstPendingItem.query?.genderValue || [])
        setFilterCurrency(firstPendingItem.query.currencyValue)
        setPovValue(firstPendingItem.query.povValue)
        setCompetitorValue(firstPendingItem.query?.competitorValue || [])
        setCategoryValue(firstPendingItem.query?.categoryValue || [])
        setSettingKapiValue(firstPendingItem.query?.kpiValue || [])
    
        // 导出 pdf
        setExportPdfVisible(true)
        setExportPdfLoadingObj({ export_pdf: true })
        setExportPdfSource('script')
    }

    const handleUploadPdf = async () => {
        if (!exportPdfLoadingObj?.export_pdf || exportPdfSource !== 'script') {
            return
        }
        // 获取 pdf blob 数据
        const newExportPdfLoadingObj = cloneDeep(exportPdfLoadingObj)
        delete newExportPdfLoadingObj.export_pdf
        if (!Object.keys(newExportPdfLoadingObj || {})?.length) return

        const finished = Object.values(newExportPdfLoadingObj).every(item => !item)
        if (!finished) return
        
        const selectors = [ '#expand-dynamic-dashboard-wrapper' ]
        settingKapiValue.forEach(kpi => {
            selectors.push(`.expand-dynamic-dashboard-kpi-${kpi.replaceAll(' ', '_')}`)
        })
        const pdfBlob = await getPdfBlobDataForDynamicDashboardReport({ selectors })
        setExportPdfVisible(false)
        clearExportPdfLoadingObj()

        // 上传 pdf blob 到服务端
        const orderId = sessionStorage.getItem('dailyScheduleDoing')
        await uploadPdf(orderId, pdfBlob)

        // 上传成功后，清除状态
    }

    const updateToken = async () => {
        const [ username, password ] = atob('dGVzdGluZ0Bub3JuYS5haS9UZXN0MjAyNA==').split('/')
        const result = await axios.request({
            url: CONST_VARS.API_URL + '/token',
            method: 'POST',
            data: qs.stringify({ username, password }),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        })
        const accessToken = result?.data?.access_token
        if (accessToken) {
            AuthToken.setAccessToken(accessToken)
        }
    }

    useDeepCompareEffect(() => {
        const config = getConfig(false)
        if (storage.getEmail() !== config.monitoringEmail) {
            return
        }
        handleUploadPdf()
    }, [ exportPdfLoadingObj, {} ])

    useEffect(() => {
        const config = getConfig(false)
        if (storage.getEmail() !== config.monitoringEmail || IS_LIVE_ENV) {
            return
        }

        // 轮询，每5分钟执行一次
        const timer = setInterval(polling, config.seconds * 1000)
        // 4h 更新一次 token
        const tokenTimer = setInterval(updateToken, 1000 * 60 * 60 * 4)

        return () => {
            timer && clearInterval(timer)
            tokenTimer && clearInterval(tokenTimer)
        }
    }, [])
}
