import { cloneDeep } from 'lodash'

/**
 * 获取拖动后的 vendor 排序
 * 
 * Price architecture 和 Discounts 两个页面使用
 * 
 * ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/779
 */
export const getDragVendorOrder = ({
    dragVendor,
    dropVendor,
    dataSource,
}: {
    dragVendor: string;
    dropVendor: string;
    dataSource: { vendor: string; }[];
}): any => {
    let newBusinessData = cloneDeep(dataSource)

    const dragIndex = newBusinessData.findIndex(item => item.vendor === dragVendor)
    const dropIndex = newBusinessData.findIndex(item => item.vendor === dropVendor)
    const dragData = newBusinessData[dragIndex]
    if (!dragData) return
    newBusinessData = newBusinessData.filter(item => item.vendor !== dragVendor)
    const newDropIndex = newBusinessData.findIndex(item => item.vendor === dropVendor)

    // 拖动后面的往前面插入
    if (dragIndex > dropIndex) {
        newBusinessData.splice(newDropIndex, 0, dragData)
    }

    // 拖动前面的往后面插入 
    else {
        newBusinessData.splice(newDropIndex + 1, 0, dragData)
    }

    return newBusinessData
}

export const getDashboardColumnOrder = ({
    dragKey,
    dropKey,
    dataSource,
}: {
    dragKey: string;
    dropKey: string;
    dataSource: string[];
}): string[] => {
    let newBusinessData = cloneDeep(dataSource)

    const dragIndex = newBusinessData.findIndex(item => item === dragKey)
    const dropIndex = newBusinessData.findIndex(item => item === dropKey)
    const dragData = newBusinessData[dragIndex]
    if (!dragData) return []
    newBusinessData = newBusinessData.filter(item => item !== dragKey)
    const newDropIndex = newBusinessData.findIndex(item => item === dropKey)

    // 拖动后面的往前面插入
    if (dragIndex > dropIndex) {
        newBusinessData.splice(newDropIndex, 0, dragData)
    }

    // 拖动前面的往后面插入 
    else {
        newBusinessData.splice(newDropIndex + 1, 0, dragData)
    }

    return newBusinessData
}
