import React from 'react'
import styles from './index.module.scss'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { PLATFORM_ANALYTICS_NAME } from 'configs'
import { MultiCategoryDropdown } from 'componentsv2/business/CategoryDropdown'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import Leaderboard from './components/Leaderboard'
import PlatformAnalyticsTable from './components/PlatformAnalyticsTable'
import LineGraph from './components/LineGraph'
import { MultiSelect } from 'druikit'
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook'
import { cloneDeep } from 'lodash'
import classNames from 'classnames'
import { downloadFileByAxios } from 'export'
import { CategoryCompetitorSwitch } from 'componentsv2/business/CategoryCompetitorSwitch'
import { competitorOptions } from './hardcode'
import { usePlatformAnalyticsTableApiData } from './hooks/usePlatformAnalytics'
import { useFilterCategory, useFilterCountry, useFilterPricePerspective } from 'hooks/useFilter'
import { useLoadingBar } from 'hooks'
import { platformAnalyticsExportFilename } from 'utils/exportUtils'
import { PricePerspectiveDropdown } from 'componentsv2/business/PricePerspectiveDropdown'
import { isBestSellerLicense, SELECTED_CATEGORY } from 'consts'
import { Modal } from './components/Modal'
import LineGraphModal from './components/LineGraphModal'
import { CompetitorDropdown } from 'componentsv2/business/CompetitorDropdown'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { arrayUtils } from 'norna-uikit'
import { getCompetitorOptions } from 'utils/optionsUtils'

const exportFilename = platformAnalyticsExportFilename
const exportClassName = 'platform-analytics-export-selector'
const exportSelector = `.${exportClassName}`

const PlatformAnalytics = () => {
    useLoadingBar()    
    usePlatformAnalyticsTableApiData()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCategory, setFilterCategory ] = useFilterCategory()
    const [ filterPricePerspective, setFilterPricePerspective ] = useFilterPricePerspective()
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        tableApiPayload,
        isCompetitorView,
        competitorValue,
        rankingModalVisible,
        rankingApiData,
        rankingApiPayload,
        graphModalVisible,
    } = moduleData

    useDeepCompareEffect(() => {
        if (isBestSellerLicense() && arrayUtils.isEqual([ 'Calvin Klein@zalandodeep', 'Tommy Hilfiger@zalandodeep' ], competitorValue)) {
            const options = getCompetitorOptions({ region: filterCountry })
            setModuleData({ competitorValue: [ ...options.map(item => item.key) ] })
        }
    }, [ competitorValue, [] ])

    const onExportExcel = async () => {
        const payload = cloneDeep(tableApiPayload)
        payload.url = payload.url.replace('/dashboard', 'dashboard')
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    return (
        <div className={classNames(styles.wrapper, exportClassName)}>
            <div className={styles.moduleTitle}>
                <ModuleTitle
                    category="ASSORTMENT" 
                    title={PLATFORM_ANALYTICS_NAME}
                />
            </div>
            <div className={styles.form}>
                <MultiCategoryDropdown
                    multiple
                    value={cloneDeep(filterCategory)}
                    onChange={value => {
                        setFilterCategory(value)
                    }}
                />
                {
                    isBestSellerLicense() ? (
                        <CompetitorDropdown
                            minCount={1}
                            value={cloneDeep(competitorValue)}
                            onChange={value => {
                                setModuleData({ competitorValue: value })
                            }}
                        />
                    ) : (
                        <MultiSelect
                            label="Competitors"
                            dropdownTitle="Competitors"
                            minCount={1}
                            options={competitorOptions}
                            value={cloneDeep(competitorValue)}
                            onChange={value => setModuleData({ competitorValue: value })}
                        />
                    )
                }
                <PricePerspectiveDropdown
                    enableFilter={false}
                    enableLoadingBar
                    value={filterPricePerspective}
                    onChange={value => {
                        setFilterPricePerspective(value)
                    }}
                />
                <ExportDropdown 
                    fileName={exportFilename}
                    selector={exportSelector}
                    onExportExcel={onExportExcel}
                />
                <div className={styles.flex1} />
                <CategoryCompetitorSwitch 
                    value={isCompetitorView}
                    onChange={value => {
                        setModuleData({ isCompetitorView: value })
                    }}
                />
            </div>
            <div className={styles.topInfo}>
                <div className={classNames(styles.leaderboardCard, styles.cardWrapper)}>
                    <Leaderboard />
                </div>
                <div className={classNames(styles.lineGraphCard, styles.cardWrapper)}>
                    <LineGraph />
                </div>
            </div>
            <PlatformAnalyticsTable />
            {
                rankingModalVisible ? (
                    <Modal 
                        onClose={() => {
                            setModuleData({ rankingModalVisible: false })
                        }}
                        apiData={rankingApiData}
                        apiPayload={rankingApiPayload}
                        categoryName={SELECTED_CATEGORY}
                    />
                ) : null
            }
            {
                graphModalVisible && (
                    <LineGraphModal 
                        onClose={() => {
                            setModuleData({ graphModalVisible: false })
                        }}
                    />
                )
            }
        </div>
    )
}

export default PlatformAnalytics
