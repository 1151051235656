import { getFirstLevelCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { CATEGORY_TOTAL, SELECTED_CATEGORY, SELECTED_VENDORS, SUMMARY } from 'consts'
import { usePlatformAnalyticsModuleData } from 'features/filters/moduleDataSlice.hook'
import { argFnDashboardComparison, argFnDashboardGraphComparison, argFnDashboardShopsSalesOptionsAndPercentage } from 'graphql/nornaapi'
import { useExcludeProductsComparisonQuery } from 'hooks'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterGender, useFilterPricePerspective, useFilterTax, useNoSizeFilter } from 'hooks/useFilter'
import { useLookbook } from 'hooks/useLookbook'
import { useCompetitorOptions } from 'hooks/useOptions'
import { usePageDate } from 'hooks/usePageDate'
import { useFetch } from 'libs'
import { cloneDeep, isEqual, uniq } from 'lodash'
import { dateRangeUtils } from 'norna-uikit'
import { useEffect, useState } from 'react'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { getMinimumDateForPlatformAnalytics, getToday } from 'utils/dateUtils'
import { getCategoriesPayload, getCompetitorPayload, getDashboardPropertiesPayload, getGroupsPayload, getPriceFilterPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { storage } from 'utils/storage'

/**
 * 表格查询接口
 * @param param0 
 * @returns 
 */
export const usePlatformAnalyticsTableApiData = () => {
    const [ filterGender ] = useFilterGender()
    const [ filterTax ] = useFilterTax()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const [ noSizeFilter ] = useNoSizeFilter()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks, isLookbook } = useLookbook()

    const { postFn: fetch, data, loading } = useFetch()
    const [ filteredApiData, setFilteredApiData ] = useState<any>({})

    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        competitorValue,
    } = moduleData

    const fetchData = async () => {
        if (!filterCompetitor.length || !competitorOptions?.length) return
        const priceFilter = getPriceFilterPayload({ ...comparisonQuery.collection.query })
        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const competitorVendors = [ ...competitorValue, SELECTED_VENDORS ]
        const competitorSellers = uniq(competitorVendors).map(vendor => ({ vendor, region: filterCountry }))

        const market = []

        const groups = getGroupsPayload({
            competitorSellers: [ ...competitorSellers ],
        })

        const competitors = getCompetitorPayload({
            competitorSellers: [ ...competitorSellers ],
            returnType: 'vendor',
            isDashboard: true,
        })

        let target = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const payload = argFnDashboardComparison({
            customer: storage.getCustomerVendor(),
            region: filterCountry,
            target,
            currency: filterCurrency,
            competitors,
            market,
            groups,
            date: dateRangeUtils.from(date),
            comparisonDate: dateRangeUtils.from(comparisonDate),
            metric: 'Zalando Shops',
            export: false,
            categories: [ ...filterCategory ],
            inclDiscount: filterPricePerspective,
            taxFilter: filterTax,
            priceRange: '',
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        })

        setModuleData({ 
            tableApiPayload: cloneDeep(payload),
            tableApiLoading: true, 
        })
        await fetch(payload.url, payload)
        setModuleData({ 
            tableApiLoading: false, 
        })
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, competitorValue, date, comparisonDate, competitorOptions, lookbooks ])

    useDeepCompareEffect(() => {
        if (!data) return

        const newData = cloneDeep(data)

        if (!isLookbook) {
            // 是否选中全部一级 category, 全部选中显示 All categories, 否则显示 Selected categories
            const categoryTreeList = storage.getCategoryTreeList()
            if (getFirstLevelCategories({ selectedCategories: filterCategory }).length !== categoryTreeList.length) {
                Object.keys(newData).forEach(vendor => {
                    newData[vendor][SELECTED_CATEGORY] = cloneDeep(newData[vendor][CATEGORY_TOTAL])
                    delete newData[vendor][CATEGORY_TOTAL]
                })
            }
        }

        setFilteredApiData({ ...newData })
        setModuleData({ tableApiData: { ...newData } })
    }, [ data, filterCategory, competitorValue, {} ])

    return {
        fetchData,
        data: filteredApiData,
        loading,
    }
}

/**
 * 线图查询接口
 */
export const usePlatformAnalyticsGraphApiData = () => {
    const [ filterGender ] = useFilterGender()
    const [ filterTax ] = useFilterTax()
    const [ filterCategory ] = useFilterCategory()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const { checked: lookbooks } = useLookbook()
    const { pageDate: date } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { postFn: fetch, data, loading } = useFetch()

    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        graphMetricValue,
        graphApiData,
        graphApiPayload,
        competitorValue,
    } = moduleData

    /**
     * 发送请求
     */
    const fetchData = async () => {
        if (!graphMetricValue) return
        if (!competitorOptions.length) return
        const dateRange = `${getMinimumDateForPlatformAnalytics()}_${getToday()}`

        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const groups = getGroupsPayload({
            competitorSellers: [
                ...competitorValue.map(c => ({ vendor: c, region: filterCountry })),
                { vendor: SELECTED_VENDORS, region: filterCountry },
            ],
        })

        const priceFilter = getPriceFilterPayload({ ...comparisonQuery.collection.query })

        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        
        const query = {
            region: filterCountry,
            targetGroup,
            currency: filterCurrency,
            inclDiscount: filterPricePerspective,
            competitors: [],
            market: [],
            groups,
            date: dateRangeUtils.from(dateRange),
            comparisonDate: dateRangeUtils.from(dateRange),
            metrics: graphMetricValue.map(item => `${item} Sales Percent`),
            allCategory: getCategoriesPayload(filterCategory),
            export: false,
            taxFilter: filterTax,
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        }

        const payload = argFnDashboardGraphComparison(query)
        payload.url = 'dashboard/graph_comparison'

        setModuleData({ 
            graphApiLoading: true,
        })

        if (isEqual(payload, graphApiPayload) && Object.keys(graphApiData || {}).length) {
            setModuleData({
                graphApiLoading: false,
            })
            return
        }

        setModuleData({ 
            graphApiPayload: payload,
        })
        const result = await fetch(payload.url, payload)
        setModuleData({
            graphApiData: result?.data || {},
            graphApiLoading: false,
        })
    }

    /**
     * 其它变量变化触发发送请求
     */
    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, competitorValue, date, graphMetricValue, competitorOptions, lookbooks, {} ])

    return {
        fetchData,
        data,
        loading: loading,
    }
}

/**
 * 排行榜接口
 */
export const useShopsSalesCountRanking = () => {
    const [ filterGender ] = useFilterGender()
    const [ filterTax ] = useFilterTax()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const [ noSizeFilter ] = useNoSizeFilter()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery()
    const { checked: lookbooks } = useLookbook()
    const { postFn: fetch, loading } = useFetch()
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        competitorValue,
        rankingApiPayload,
    } = moduleData

    useEffect(() => {
        setModuleData({
            rankingApiLoading: loading,
        })
    }, [ loading ])

    const fetchData = async () => {
        if (!filterCompetitor.length || !competitorOptions?.length) return
        const priceFilter = getPriceFilterPayload({ ...comparisonQuery.collection.query })
        const properties = getDashboardPropertiesPayload(comparisonQuery)
        const categoryName = SELECTED_CATEGORY
        const vendorCode = SELECTED_VENDORS

        const target = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        let competitors: string[] = []
        let groups = {}
        if ([ SELECTED_VENDORS, SUMMARY ].includes(vendorCode)) {
            competitors = [ ...competitorValue ]
            groups = getGroupsPayload({
                competitorSellers: [
                    ...competitorValue.map(c => ({ vendor: c, region: filterCountry })),
                    { vendor: SELECTED_VENDORS, region: filterCountry },
                ],
            })
        }

        const payload = argFnDashboardShopsSalesOptionsAndPercentage({
            customer: storage.getCustomerVendor(),
            region: filterCountry,
            target,
            currency: filterCurrency,
            market: [],
            groups,
            vendor: vendorCode,
            category: categoryName,
            date: dateRangeUtils.from(date),
            comparisonDate: dateRangeUtils.from(comparisonDate),
            export: false,
            competitors,
            categories: [ ...filterCategory ],
            inclDiscount: filterPricePerspective,
            taxFilter: filterTax,
            priceRange: '',
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        })

        if (isEqual(payload, rankingApiPayload)) {
            return
        }

        const result = await fetch(payload.url, payload)
        setModuleData({
            rankingApiData: result?.data || {},
            rankingApiPayload: payload,
        })
        return result?.data || {}
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, competitorValue, comparisonDate, competitorOptions, lookbooks ])
}

export const useShopsSalesOptionsAndPercentae = () => {
    const [ filterGender ] = useFilterGender()
    const [ filterTax ] = useFilterTax()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCurrency ] = useFilterCurrency()
    const [ noSizeFilter ] = useNoSizeFilter()
    const { pageDate: date, comparisonPageDate: comparisonDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const comparisonQuery = useExcludeProductsComparisonQuery()
    const { checked: lookbooks } = useLookbook()
    const { postFn: fetch } = useFetch()
    const [ moduleData, setModuleData ] = usePlatformAnalyticsModuleData()
    const {
        competitorValue,
    } = moduleData

    const fetchShopsSalesOptionsAndPercentae = async (query: {
        categoryName: string;
        vendorCode: string;
        region: string;
    }) => {
        if (!filterCompetitor.length || !competitorOptions?.length) return
        const priceFilter = getPriceFilterPayload({ ...comparisonQuery.collection.query })
        const properties = getDashboardPropertiesPayload(comparisonQuery)

        const target = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        let competitors: string[] = []
        let groups = {}
        if ([ SELECTED_VENDORS, SUMMARY ].includes(query.vendorCode)) {
            competitors = [ ...competitorValue ]
            groups = getGroupsPayload({
                competitorSellers: [
                    ...competitorValue.map(c => ({ vendor: c, region: filterCountry })),
                    { vendor: SELECTED_VENDORS, region: filterCountry },
                ],
            })
        }

        const payload = argFnDashboardShopsSalesOptionsAndPercentage({
            customer: storage.getCustomerVendor(),
            region: query.region,
            target,
            currency: filterCurrency,
            market: [],
            groups,
            vendor: query.vendorCode,
            category: query.categoryName,
            date: dateRangeUtils.from(date),
            comparisonDate: dateRangeUtils.from(comparisonDate),
            export: false,
            competitors,
            categories: [ ...filterCategory ],
            inclDiscount: filterPricePerspective,
            taxFilter: filterTax,
            priceRange: '',
            properties,
            priceFilter,
            lookbooks,
            noSizeFilter,
        })

        setModuleData({ 
            modalApiPayload: payload,
            modalApiLoading: true, 
        })
        const result = await fetch(payload.url, payload)
        setModuleData({
            modalApiData: result?.data || {},
            modalApiLoading: false,
        })
        return result?.data || {}
    }

    return {
        fetchShopsSalesOptionsAndPercentae,
    }
}
