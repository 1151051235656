import React, { CSSProperties, useRef } from 'react'
import { Flex, Flex1 } from 'druikit'
import { CurrencyDropdown } from 'componentsv2/business/CurrencyDropdown'
import { GenderDropdown } from 'componentsv2/business/GenderDropdown'
import { RegionDropdown } from 'componentsv2/business/RegionDropdown'
import { useCompetitorValue, useFetchPayload, useSettingValue, useCompetitorOptions, usePovValue, useSettingKpiValue, useExportPdfVisible, useExportPdfLoadingObj, useExportPdfSource } from 'pages/DynamicDashboard/hooks/useDynamicDashbordData'
import { ExportDropdown, exportPdfForDynamicDashboardReport, getPdfBlobDataForDynamicDashboardReport } from 'componentsv2/ExportDropdown'
import { dynamicDashboardExportFilename, dynamicDashboardReportExportFilename } from 'utils/exportUtils'
import { getExportSelector } from 'utils'
import { exportSelector } from 'pages/DynamicDashboard/utils'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import SettingTreeSelect from './SettingTreeSelect/SettingTreeSelect'
import { geneCompetitorOptions } from 'features/filters/dynamicDashboardSlice'
import CompetitorTreeSelect from './CompetitorTreeSelect'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { intersection } from 'utils/array'
import { loadingBar } from 'hooks/useLoadingBar'
import { useFilterCountry } from 'hooks/useFilter'
import { RoutesObj } from 'global/Routes'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { useHistory } from 'react-router-dom'
import { IS_LIVE_ENV } from 'consts'
import styles from './QueryForm.module.scss'

export const QueryForm = ({
    style,
    className,
    loading,
}: {
    style?: CSSProperties;
    className?: string;
    loading?: boolean;
}) => {
    const history = useHistory()
    const [ povValue, setPovValue ] = usePovValue()
    const [ competitorValue, setCompetitorValue ] = useCompetitorValue()
    const [ regionValue ] = useFilterCountry()
    const [ , setCompetitorOptions ] = useCompetitorOptions()
    const [ , setSettingValue ] = useSettingValue()
    const [ fetchPayload ] = useFetchPayload()
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    /* ****************************** Export *********************************** */
    const exportFilename = dynamicDashboardExportFilename
    const exportFilename2 = dynamicDashboardReportExportFilename

    const onExportExcel = async () => {
        const payload = cloneDeep(fetchPayload)
        payload.url = 'dashboard/export_dynamic_dashboard'
        payload.query.metric = 'Dashboard'
        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    const [ settingKapiValue ] = useSettingKpiValue()
    const [ , setExportPdfVisible ] = useExportPdfVisible()
    const [ exportPdfSource, setExportPdfSource ] = useExportPdfSource()

    const [ exportPdfLoadingObj, setExportPdfLoadingObj, clearExportPdfLoadingObj ] = useExportPdfLoadingObj()

    const onExportPdf = () => {
        setExportPdfVisible(true)
        setExportPdfLoadingObj({ export_pdf: true })
        setExportPdfSource('button')
    }

    useDeepCompareEffect(() => {
        if (!exportPdfLoadingObj?.export_pdf || exportPdfSource !== 'button') {
            return
        }
        const newExportPdfLoadingObj = cloneDeep(exportPdfLoadingObj)
        delete newExportPdfLoadingObj.export_pdf
        if (!Object.keys(newExportPdfLoadingObj || {})?.length) return

        const finished = Object.values(newExportPdfLoadingObj).every(item => !item)
        if (!finished) return
        
        const selectors = [ '#expand-dynamic-dashboard-wrapper' ]
        settingKapiValue.forEach(kpi => {
            selectors.push(`.expand-dynamic-dashboard-kpi-${kpi.replaceAll(' ', '_')}`)
        })
        exportPdfForDynamicDashboardReport({
            selectors,
            fileName: exportFilename2,
            onFinish: () => {
                setExportPdfVisible(false)
            },
        })
        clearExportPdfLoadingObj()
    }, [ exportPdfLoadingObj, {} ])

    const isExpandDynamicDashboard = window.location.pathname === RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL

    return (
        <Flex
            alignItems="flex-end" 
            className={className}
            style={{ position: 'relative', zIndex: 110, ...style }}
        >
            <CompetitorTreeSelect
                className={styles.competitorTreeSelect}
                multiple={false}
                label="Point of view"
                placeholder="Point of view"
                value={[ povValue ]}
                onChange={value => {
                    setPovValue(value[0])

                    const newCompetitorValue = cloneDeep(competitorValue)
                    if (newCompetitorValue.includes(value[0])) {
                        if (newCompetitorValue.length === 1) {
                            const competitorOptions = geneCompetitorOptions(regionValue, false)
                            setCompetitorValue([ competitorOptions.filter(item => item?.key !== value[0])?.[0]?.key ])
                        } else {
                            setCompetitorValue(newCompetitorValue.filter(item => item !== value[0]))
                        }
                    }

                    loadingBar.restart()
                }}
            />
            <CompetitorTreeSelect
                className={styles.competitorTreeSelect}
                excludeVendor={povValue}
                value={competitorValue}
                onChange={value => {
                    setCompetitorValue(value)
                    loadingBar.restart()
                }}
            />
            <RegionDropdown
                right
                enableFilter
                enableLoadingBar
                whetherUpdateCurrency
                onChange={(value) => {
                    const competitorOptions = geneCompetitorOptions(value, false)

                    let newPovValue = povValue
                    if (!competitorOptions.find(item => item.key === povValue)) {
                        newPovValue = competitorOptions[0]?.key
                    }

                    const oldCompetitorValue = cloneDeep(competitorValue)?.filter(item => item !== povValue)
                    let newCompetitorValue = intersection(oldCompetitorValue, competitorOptions.map(item => item.key))
                    if (!newCompetitorValue.length) {
                        newCompetitorValue = [ competitorOptions?.filter(item => item.key !== newPovValue)?.[0]?.key ].filter(item => item)
                    }
                    setCompetitorOptions([ ...competitorOptions ])
                    setCompetitorValue([ ...newCompetitorValue ].filter(item => item))
                }}      
            />
            <GenderDropdown
                right
                enableFilter
                enableLoadingBar
            />
            <CurrencyDropdown
                right
                enableFilter
                enableLoadingBar
            />
            {
                isExpandDynamicDashboard ? (
                    <ExportDropdown
                        right
                        selector="#expand-dynamic-dashboard-wrapper"
                        fileName={exportFilename2}
                        showPDF
                        onExportPdf={onExportPdf}
                        showJPG={false}
                        showCLIPBOARD={false}
                        showExcel={false}
                        disabled={loading}
                    />
                ) : (
                    <ExportDropdown
                        right
                        selector={getExportSelector(exportSelector)}
                        fileName={exportFilename}
                        onExportExcel={() => onExportExcel?.()}
                        showPDF
                        pdfAlias="Report"
                        onExportPdf={onExportPdf}
                        disabled={loading}
                        showEmailReport={!IS_LIVE_ENV}
                        onExportEmailReport={() => {
                            history.push(RoutesObj.DYNAMIC_DASHBOARD_REPORT.URL)
                        }}
                    />
                )
            }
            <SettingTreeSelect 
                labelStyle={{ fontSize: 10 }}
                className={styles.settingTreeSelect}
                onChange={value => {
                    setSettingValue(value)
                }}
            />
            <Flex1 />
            <Dialog ref={dialogRef} />
        </Flex>
    )
}
