import React, { FC, useState, useEffect, useRef, useCallback } from 'react'
import { filterVendor } from 'features/filters/filtersSlice'
import { isSubsetPayload, SEARCH_FOR_OPTIONS_COMPETITOR, UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE, UPDATE_NEWCOMPETITORVALUE } from 'features/filters/moduleDataSlice'
import { useSelector, useDispatch } from 'react-redux'
import { Spin } from 'componentsv2/Spin'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { ProductClassify } from 'pages/SearchForOptions/components/ProductClassify'
import { Checkbox } from 'componentsv2/form-elements/Checkbox'
import { useCategories } from 'pages/SearchForOptions/hooks/useCategories'
import { Dialog, DialogRefType } from 'components/common/InfoBox/Dialog'
import { ReportProductModalV2 } from 'componentsv2/ReportProductModal'
import { UseDialogType } from 'hooks/useDialog'
import { ReportType } from 'componentsv2/ReportProductModal/types'
import { getComeptitorValueByGroup } from 'componentsv2/business/CompetitorDropdown'
import { useReport } from 'hooks/useReport'
import { useQa } from 'hooks/useQa'
import { useExcludeProductsComparisonQuery, useManualLoading } from 'hooks'
import { getQaData, updateCurrentNornaIds } from 'features/filters/qaDataSlice'
import emitter from 'utils/event'
import { CUSTOM_EVENTS, CATEGORY_TOTAL, SELECTED_VENDORS, ALL_VENDORS, IS_LIVE_ENV, START_DATE, TODAYD_DATE, ALL_COUNTRIES } from 'consts'
import { getVendorNameByCode, isPovVendor } from 'utils'
import { getFractionDigits } from 'utils'
import { isSuperArray, sortVendorList } from 'utils/array'
import { useScrollBar2 } from 'hooks/useScrollBar'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { cloneDeep } from 'lodash'
import { isCompetitorGroupChanged } from 'utils/group'
import { useFetch } from 'libs'
import { matchAllCategories } from 'graphql/nornaapi'
import { getCompetitorPayload, getGroupsPayload, getMarketPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { useModuleData } from 'hooks/useModuleData'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterProperty } from 'hooks/useFilter'
import { storage } from 'utils/storage'
import { isQueryDateValid } from 'utils/isQueryDateValid'
import { SEARCH_FOR_OPTIONS_NAME } from 'configs/moduleName'
import { getPropertyLabelByValue } from 'components/layout/Header/components/HeaderFilter/property.util'
import styles from './styles.module.scss'
import { PageCompetitorProps, CategoryObjProps, VendorObjProps } from './types'
import { CompareCategory, CompareCategoryProps } from './components/CompareCategory'
import { CompareVendorHeader, CompareVendorContent, VendorProps } from './components/CompareVendor'
import { usePageDate } from 'hooks/usePageDate'
import { getCompetitorOptions } from 'utils/optionsUtils'
import { TopScrollbar, numberUtils } from 'norna-uikit'
import classNames from 'classnames'

export const PageCompetitor: FC<PageCompetitorProps> = ({ date, showOldProducts = false, multiGender = false, multiMetaCategory = false, historicalSizeFilter = [], onFetchPayloadChange }: PageCompetitorProps) => {
    const { manualLoading, showManualLoading } = useManualLoading()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true })
    const [ filterCompetitor ] = useFilterCompetitor()
    const { competitorOptions } = useCompetitorOptions()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterCountry ] = useFilterCountry()
    const [ filterProperty ] = useFilterProperty()
    const [ filterCategory ] = useFilterCategory()
    const { pageDate } = usePageDate()
    const dispatch = useDispatch()
    const povVendor = storage.getPovVendor()

    const [ moduleData, setModuleData ] = useModuleData(SEARCH_FOR_OPTIONS_COMPETITOR)
    const {
        fetchPayload = {},
        apiData = {},
        oldCompetitorValue = [],
        newCompetitorValue = [],
    } = moduleData

    const [ searchModuleData ] = useModuleData(SEARCH_FOR_OPTIONS_NAME)
    const { isGroupByProperty = false } = searchModuleData

    /* ************************* Form **************************** */
    useDeepCompareEffect(() => {
        setModuleData({
            type: UPDATE_NEWCOMPETITORVALUE,
            payload: {
                competitorValue: cloneDeep(filterCompetitor.map(item => item.vendor)),
            },
        })
    }, [ filterCompetitor ])

    /* ************************* Data **************************** */
    const { postFn: fetch, data, loading, setData } = useFetch()
    const { categories } = useCategories()
    const qaData = useSelector(getQaData)

    const fetchData = () => {
        if (!competitorOptions?.length) return

        let category: string[] = []
        if (filterCategory?.length === 0) {
            category = []
        } 
        else if (filterCategory?.includes(CATEGORY_TOTAL)) {
            const categoryList = storage.getCategoryTreeList()
            category = categoryList.map(item => item.name)
        }
        else if (filterCategory?.length !== categories.length) {
            category = [ ...categories ]
        } 
        else {
            category = [ ...filterCategory ]
        }

        const query = cloneDeep(comparisonQuery)
        query.sellers = getCompetitorPayload({ competitorSellers: [ ...filterCompetitor ] })
        query.market = getMarketPayload({ competitorSellers: filterCompetitor, competitorOptions: getCompetitorOptions({ region: filterCountry }) })
        query.groups = getGroupsPayload({ competitorSellers: [ ...filterCompetitor ] })

        if (filterCountry === ALL_COUNTRIES) {
            query.sellers = cloneDeep(query.sellers)?.map(item => {
                item.region = ALL_COUNTRIES
                return item
            })
            query.market = cloneDeep(query.market)?.map(item => {
                item.region = ALL_COUNTRIES
                return item
            })
            Object.keys(query.groups || {}).forEach(key => {
                query.groups[key] = cloneDeep(query.groups)[key].map(item => {
                    item.region = ALL_COUNTRIES
                    return item
                })
            })
        }

        const payload: any = matchAllCategories(query, date, date, category, showOldProducts, historicalSizeFilter, multiGender, multiMetaCategory)

        if (qaData.qaPage) {
            payload.query.qa_page = qaData.qaPage
            payload.data.query.start_date.iso8601 = START_DATE
            payload.data.query.end_date.iso8601 = TODAYD_DATE()
        }

        if (isSubsetPayload({ newPayload: payload, oldPayload: fetchPayload, pageType: 'searchForOptions' }) && Object.keys(apiData || {}).length) {
            showManualLoading()
            setData?.(apiData)
            return
        }

        setModuleData({
            type: UPDATE_FETCHPAYLOADANDOLDCOMPETITORVALUE,
            payload: cloneDeep(payload),
        })
        fetch(payload.url, payload)
    }

    const handleData = apiData => {
        if (!apiData || Object.prototype.toString.call(apiData) !== '[object Object]') return
        setCategoryList([])

        const result = apiData || {}
        const vendorSortList = sortVendorList({
            vendorList: newCompetitorValue,
        })

        const vendorObj: VendorObjProps = {}
        const categoryObj: CategoryObjProps = {}
        Object.keys(result).forEach(region => {
            Object.keys(result[region]).forEach(vendor => {
                const queryDateValid = isQueryDateValid({
                    vendor,
                    region: filterCountry,
                    competitorValue: newCompetitorValue,
                    date: pageDate,
                })

                /**
                 * 一个 vendor 可能会勾选多个 category（Salsa Jeans 这个 License 就有子分类）
                 *
                 * 假设服务端返回数据如下：
                 * {
                 *      'category1': { option_count: 97, avg_price: 214 },
                 *      'category2': { option_count: 62, avg_price: 89 },
                 * }
                 */
                // 当前 Vendor 所有 cateogry 的产品数量之和
                let totalCount = 0
                // 当前 Vendor 所有 category 的产品的价格之和
                let averagePrice = 0

                const regionVendor = result[region][vendor]

                // kill `_` suffix of key
                if (vendor.endsWith('_')) {
                    vendor = vendor.replace('_', '')
                }
                categoryObj[vendor] = []

                Object.keys(regionVendor).forEach(category => {
                    // eslint-disable-next-line

                    const { products = [] } = regionVendor[category]
                    totalCount = regionVendor[CATEGORY_TOTAL].option_count
                    averagePrice = regionVendor[CATEGORY_TOTAL].avg_price

                    const productList = queryDateValid ? products.map(product => ({
                        image: product?.image_name,
                        vendor: getVendorNameByCode(vendor),
                        fullyConfirmed: product.fully_confirmed, //eslint-disable-line
                        brand: product?.brand,
                        seller: product.seller,
                        name: product?.title,
                        lookbooks: product?.lookbooks ?? [],
                        leftPrice: product?.last_price_info?.original_price,
                        rightPrice: product?.last_price_info?.discounted_price || product?.last_price_info?.original_price,
                        nornaid: product?.product?.nornaid,
                        currency: product?.currency || filterCurrency,
                        sizePriceRange: product?.size_price_range,
                        latestSizePrice: product?.latest_size_price,
                        property: product.property,
                    })) : []

                    // 接口返回了 All categories 字段，只是为了让前端获取 total_options 和 average_price，在 CompareVendor 里显示
                    if (category !== CATEGORY_TOTAL) {
                        categoryObj[vendor].push({
                            categoryName: category,
                            vendor,
                            productList: productList || [],
                        })
                    }
                })

                /* ****************************** 构造 vendorList 数据结构 ****************************** */
                /**
                 * 当前遍历的 vendor 是否是登录 License 的 vendor （customerSellerVendor）
                 * customerSellerVendor 和其它 vendor 在页面上展示有些不同，通过几个变量处理这些变化
                 */
                const vendorIsCustomer = isPovVendor(vendor)

                /**
                 * 计算 vendor 的 image 和 currency 逻辑：
                 * 一个 vendor 可能有多个 category，假设一个 vendor 有两个 category
                 *
                 * 如果第一个 category 有产品，那么 image 和 currency 就是第一个 category 的第一个产品的图片地址和币种
                 * 如果第一个 category 没有产品，那么 image 和 currency 就是第二个 category 的第一个产品的图片地址和币种
                 * 如果第二个 category 也没有产品，那么 image 就没有值，currency 取 Filter 中选中 Country 对应的币种值
                 */
                let vendorImage = ''
                let vendorCurrency = ''
                categoryObj[vendor].some(({ productList }) => {
                    if (productList?.length) {
                        vendorImage = productList[0]?.image
                        vendorCurrency = productList[0]?.currency || filterCurrency
                        return true
                    }
                    return false
                })

                vendorObj[vendor] = {
                    // 格式化之后的值
                    vendor: getVendorNameByCode(vendor),

                    vendorOrigin: vendor,
                    // 格式化之后的值，字符串类型，如 '1,299'，用于页面上展示使用
                    options: numberUtils.formatNumberByComma(totalCount),
                    // 页面上显示的平均价格
                    averagePrice: numberUtils.formatNumberByComma(averagePrice.toFixed(getFractionDigits())),
                    averagePriceOrigin: Number(Number(averagePrice).toFixed(0)),
                    percent: '0',
                    percentOrigin: 0,
                    // 页面上显示的封面图
                    image: vendorImage,
                    // 币种
                    currency: vendorCurrency,
                    // 页面上是否显示关闭图片按钮
                    showCloseBtn: !vendorIsCustomer,
                    // 页面上是否显示放大图片按钮
                    showExpandBtn: true,
                }
            })      // vendor 遍历结束

            /**
             * 计算 vendorList 中 percent 值
             * 01 找到 customerSellerVendor 的 averagePrice 作为基准值 refAvgPrice
             * 02 遍历 vendorObj 对象，基于基准值计算每个 vendor 的 percent 值
             *
             * 举例：
             * 基准值 refAvgPrice = 50
             * 某个 vendor 的 averagePrice = 70，该 vendor 的 percent = (70 / 50) * 100 = 140，结果保留 0 位小数，还需要转换为数值类型
             */
            const refAvgPrice = vendorObj[povVendor]?.averagePriceOrigin
            Object.keys(vendorObj).forEach(vendor => {
                /**
                 * refAvgPrice 也可能等于 0，比如 Filter > Launched after 选择了当天，e.g
                 * averagePriceOrigin / refAvgPrice = averagePriceOrigin / 0 = NaN 要避免这种情况
                 */
                let percent = 0
                if (refAvgPrice !== 0) {
                    percent = Number(Number((vendorObj[vendor].averagePriceOrigin / refAvgPrice) * 100).toFixed(0))
                }
                vendorObj[vendor].percent = numberUtils.formatNumberByComma(percent)
                vendorObj[vendor].percentOrigin = percent
            })
        })  // region 遍历结束

        const vendorList = vendorSortList.map(vendor => {
            if (vendor.endsWith('_')) { return vendorObj[vendor.replace('_', '')] }
            return vendorObj[vendor]
        })
        const updatedVendorList = vendorList.filter(item => item)

        // 返回结果中存在的vendors列表
        const vendorsExistInResult = vendorSortList.filter(item => updatedVendorList.map(item => item.vendorOrigin).includes(item))
        setVendorList(updatedVendorList)

        /* ****************************** 构造 categoryList 数据结构 ****************************** */
        if (vendorsExistInResult.length) {
            const categoryList: CompareCategoryProps[] = []
            categories.forEach(category => {
                const data: CompareCategoryProps = {
                    categoryName: category,
                    vendorProductList: [],
                }
                vendorsExistInResult.forEach(vendor => {
                    data.vendorProductList.push({
                        vendor: getVendorNameByCode(vendor),
                        allProductList: categoryObj[vendor]?.find(item => item.categoryName === category)?.productList || [],
                        productList: categoryObj[vendor]?.find(item => item.categoryName === category)?.productList.slice(0, 10) || [],
                    })
                })
                categoryList.push(data)
            })
            setCategoryList(categoryList)
        }

        const displayedProperty = getPropertyLabelByValue({
            selectedCategories: filterCategory,
            selectedProperties: filterProperty,
        })

        if (isGroupByProperty) {
            const newCategoryObj = {}
            Object.keys(categoryObj || {}).forEach(vendor => {
                // 展开所有产品
                const products = categoryObj[vendor].map(item => item.productList).flat(2)
                const newCategoryObjItem = Object.values(displayedProperty)?.flat(10)?.map(property => {
                    return {
                        categoryName: property,
                        vendor,
                        productList: products.filter((product: any) => Object.values(product?.property || {})?.flat(10)?.includes(property)),
                    }
                })
                newCategoryObj[vendor] = newCategoryObjItem
            })
            setCategoryObj(newCategoryObj)
        } else {
            setCategoryObj(categoryObj)
        }
    }

    useDeepCompareEffect(() => {
        fetchData()
    }, [ comparisonQuery, date, showOldProducts, historicalSizeFilter, multiGender, multiMetaCategory, qaData.qaPage, competitorOptions ])

    useDeepCompareEffect(() => {
        if (isCompetitorGroupChanged({ oldCompetitorValue, newCompetitorValue })) {
            fetchData()
            return
        }

        if (isSuperArray(oldCompetitorValue, newCompetitorValue)) {
            showManualLoading()
            handleData({ ...apiData })
            return
        }

        fetchData()
    }, [ newCompetitorValue ])

    useEffect(() => {
        if (!data || Object.prototype.toString.call(data) !== '[object Object]') return

        handleData({ ...data })
        setModuleData({ apiData: { ...data } })
        // eslint-disable-next-line
    }, [data, isGroupByProperty, pageDate])

    /* ****************************** 主要维护的几个变量 ****************************** */
    // 供应商列表，页面第一行
    const [ vendorList, setVendorList ] = useState<VendorProps[]>([])
    const [ enableSortByVendor, setEnableSortByVendor ] = useState<boolean>(false)
    // 分类列表，根据每个分类展示产品
    const [ categoryList, setCategoryList ] = useState<CompareCategoryProps[]>([])

    /**
     * 点击 vendor 的 expand 图片进入该 vendor 的详情页时
     * 需要从 categoryObj 变量中获取详情页的数据源
     *
     * 详情页数据源格式
     * dataSource = {
     *      categoryName: 'Shorts',
     *      productList: [
     *          { image: '', vendor: '', brand: '', name: '', currency: '', leftPrice: 0, rightPrice: 0, nornaid: '' }
     *      ]
     * }
     *
     * categoryObj 数据结构如下：
     * categoryObj = {
     *      'salsajeans': {
     *          categoryName: 'Shorts',
     *          productList: [
     *              { image: '', vendor: '', brand: '', name: '', currency: '', leftPrice: 0, rightPrice: 0, nornaid: '' }
     *          ]
     *      },
     * }
     *
     * 要获取 salsajeans 这个 vendor 的详情页数据源，就很简单了：categoryObj['salsajeans']
     */
    const [ categoryObj, setCategoryObj ] = useState<CategoryObjProps>({})

    useDeepCompareEffect(() => {
        onFetchPayloadChange?.(fetchPayload || {})
    }, [ fetchPayload || {} ])

    /**
     * 每个 vendor 点击左上角放大(zoom)按钮，会进入这个 vendor 的详情页
     */
    const [ detailPageVisible, setDetailPageVisible ] = useState(false)
    const [ detailPageVendor, setDetailPageVendor ] = useState<string>('')
    const [ detailPageDataSource, setDetailPageDataSource ] = useState<any>([])

    const { enabledReport, snackOpen, reportedProducted, batchConfirmReportFn, getReportedFn } = useReport()
    const { clearCheckedProduct, checkedProductList } = useQa()
    /**
     * 在 DetailPage 页面改变日期会重新发请求获取数据
     * 此时通过判断 detailPageVisible 和 detailPageVendor 都有值说明是处于详情页的，那么需要更新详情页的数据源
     */
    useDeepCompareEffect(() => {
        // Filter.Competitor 选中项 + 自己
        let sellers: string[] = [ ...filterCompetitor ]
            .map(item => item.vendor)
        // bogner 如果选中 subbrand 大于 1 个, 页面会多显示一个 Bogner Group 的 vendor
        sellers = getComeptitorValueByGroup(sellers)

        if (detailPageVendor && !sellers.find(competitor => competitor === detailPageVendor)) {
            setDetailPageVendor('')
            setDetailPageDataSource([])
            dispatch(updateCurrentNornaIds([]))
            setDetailPageVisible(false)
        } else if (detailPageVisible && detailPageVendor) {
            setDetailPageDataSource(categoryObj[detailPageVendor])
            dispatch(updateCurrentNornaIds(categoryObj[detailPageVendor].map(item => item.productList.map(ele => ele.nornaid)).flat(2)))
            clearCheckedProduct()
        }
    }, [categoryObj, detailPageVisible, detailPageVendor, filterCompetitor, povVendor]) //eslint-disable-line

    const onExpandVendor = (item: VendorProps) => {
        const dataSource = categoryObj[item.vendorOrigin]
        if (!Array.isArray(dataSource)) return
        setDetailPageDataSource(dataSource)
        setDetailPageVendor(item.vendorOrigin)
        setDetailPageVisible(true)
        dispatch(updateCurrentNornaIds(dataSource.map(item => item.productList.map(ele => ele.nornaid)).flat(2)))
        clearCheckedProduct()
        // 滚动条位置恢复到顶部
        window.scrollTo(0, 0)
    }

    const onCloseVendor = (item: VendorProps) => {
        dispatch(filterVendor([ ...filterCompetitor ].filter(item2 => item2.vendor !== item.vendorOrigin)))
    }

    const onBack = () => {
        setDetailPageVisible(false)
        setDetailPageVendor('')
    }

    /**
     * Dialog for batch report
     */
    const dialogRef = useRef<DialogRefType>({} as DialogRefType)

    const report = useCallback(() => {
        if (!detailPageVisible) return snackOpen('please zoom in one certain vendor firstly')
        if (!checkedProductList.length) return snackOpen('please select one product at least~')  // no one is selected
        const closeFn = dialogRef.current?.closeDialog
        
        const products = detailPageDataSource
            .map(d => d.productList)
            .flat(2)
            .map(d => ({ nornaid: d.nornaid, vendor: d.seller.vendor }))
            .filter(d => checkedProductList.includes(d.nornaid))
        
        dialogRef.current?.openDialog?.('Batch Report Tool', (
            <ReportProductModalV2
                productUrl=""
                type={ReportType.MULTI_PRODUCT}
                nornaids={checkedProductList as Array<string>}
                isOpen={true}
                closeModal={() => {
                    closeFn?.()
                }}
                products={products}
            />
        ), UseDialogType.REPORT_DIALOG)
    }, [ checkedProductList, detailPageVisible, snackOpen, detailPageDataSource ])

    const confirm = useCallback(async () => {
        const res = await batchConfirmReportFn()
        if (res.data.success) {
            snackOpen('confirm successfully, congrats')
        } else {
            snackOpen('confirm failed, please try it latter ')
        }
        await getReportedFn()
    }, [ batchConfirmReportFn, snackOpen, getReportedFn ])

    const preliminaryConfirm = useCallback(async () => {
        const res = await batchConfirmReportFn(true)
        if (res.data.success) {
            snackOpen('preliminary-confirm successfully, congrats')
        } else {
            snackOpen('preliminary-confirm failed, please try it latter ')
        }
        await getReportedFn()
    }, [ batchConfirmReportFn, snackOpen, getReportedFn ])

    useEffect(() => {
        emitter.addListener(CUSTOM_EVENTS.QA_REPORT, report)
        emitter.addListener(CUSTOM_EVENTS.QA_CONFIRM_REPORT, confirm)
        emitter.addListener(CUSTOM_EVENTS.QA_PRELIMINARY_CONFIRM_REPORT, preliminaryConfirm)
        return () => {
            emitter.removeListener(CUSTOM_EVENTS.QA_REPORT, report)
            emitter.removeListener(CUSTOM_EVENTS.QA_CONFIRM_REPORT, confirm)
            emitter.removeListener(CUSTOM_EVENTS.QA_PRELIMINARY_CONFIRM_REPORT, preliminaryConfirm)
        }
    }, [report]) //eslint-disable-line

    // ------------------------------- sort by vendor action -------------------------------
    const sortByVendorFn = useCallback(() => setEnableSortByVendor(!enableSortByVendor), [ enableSortByVendor ])

    /* ***************************** 设置头部和内容滚动条联动 *********************************** */
    useScrollBar2('.search-header', '.searchForOptionsContent', [ detailPageDataSource, vendorList ])

    return (
        <>
            <ShouldRender shouldRender={!detailPageVisible}>
                <Spin spinning={loading || manualLoading} zIndex={120}>
                    <TopScrollbar className={classNames('search-header', styles.searchHeader)}>
                        <CompareVendorHeader
                            vendorList={vendorList}
                            onClose={onCloseVendor}
                            onExpand={onExpandVendor}
                        />
                    </TopScrollbar>

                    {/* className="searchForOptionsContent" 这个属性是用来处理 CompareStickyHeader 组件的，千万别误删了 */}
                    <div style={{ overflow: 'auto', scrollbarWidth: 'none' }} className="searchForOptionsContent">
                        <CompareVendorContent
                            vendorList={vendorList}
                        />
                        {
                            categoryList.map(item => (
                                <CompareCategory
                                    key={item.categoryName}
                                    categoryName={item.categoryName}
                                    vendorProductList={item.vendorProductList}
                                />
                            ))
                        }
                    </div>
                </Spin>
            </ShouldRender>
            {/* 每个 Vendor 的详情页 */}
            <ShouldRender shouldRender={detailPageVisible && Array.isArray(detailPageDataSource)}>
                <Spin spinning={loading} zIndex={99}>
                    <div className="flex-row flex flex-start" style={{ marginBottom: '20px' }}>
                        <div onClick={onBack} className={styles.backButton}>
                            &lt;&nbsp;&nbsp;Back
                        </div>
                        {/* 2022-08-30 live 环境不显示 Sort by vendor */}
                        {detailPageDataSource.length && [ SELECTED_VENDORS, ALL_VENDORS ].includes(detailPageDataSource[0].vendor) && !IS_LIVE_ENV ? <Checkbox style={{ marginLeft: 10 }} value={enableSortByVendor} onChange={sortByVendorFn} >Sort by vendor</Checkbox> : null}
                    </div>
                    {
                        detailPageDataSource.map(item => (
                            <ProductClassify
                                enableSortByVendor={enableSortByVendor && [ SELECTED_VENDORS, ALL_VENDORS ].includes(item.vendor)}
                                sortRules={vendorList.map(item => item.vendorOrigin)}
                                enabledQa={enabledReport}
                                taggedQaObj={reportedProducted}
                                key={item.categoryName}
                                classifyName={item.categoryName}
                                products={item.productList || []}
                            />
                        ))
                    }
                </Spin>
            </ShouldRender>
            <Dialog ref={dialogRef} />
        </>
    )
}
