import React, { CSSProperties, useEffect } from 'react'
import { CompetitorDropdown } from 'componentsv2/business/CompetitorDropdown'
import { useCompetitorOptions } from 'hooks/useOptions'
import { DropdownType } from 'componentsv2/form-elements/Dropdown'
import { useLookbook } from 'hooks/useLookbook'
import { useGraphButtonModuleData } from 'features/filters/moduleDataSlice.hook'
import { cloneDeep } from 'lodash'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const LineChartCompetitorDropdown = ({
    enableTwoYAxis = false,
    left,
    right,
    onChange,
    region,
    style,
    selectorClassName,
    showOkButton = true,
}: {
    enableTwoYAxis?: boolean;
    left?: number | boolean;
    right?: number | boolean;
    onChange?: (competitorValue: any[]) => void;
    region?: string;
    style?: CSSProperties;
    selectorClassName?: string;
    showOkButton?: boolean;
}) => {
    const { competitorOptions } = useCompetitorOptions({ region })
    const { isLookbook } = useLookbook()
    const [ moduleData, setModuleData ] = useGraphButtonModuleData()
    const { tmpCompetitorValue } = moduleData

    useEffect(() => {
        if (enableTwoYAxis === true) {
            let newTmpCompetitorValue = cloneDeep(tmpCompetitorValue)
            newTmpCompetitorValue = newTmpCompetitorValue.sort((a, b) => {
                const aIndex = competitorOptions.findIndex(item => item.vendor === a.vendor) || -1
                const bIndex = competitorOptions.findIndex(item => item.vendor === b.vendor) || -1
                return aIndex - bIndex
            })
            const value = newTmpCompetitorValue.slice(0, 1)
            setModuleData({
                tmpCompetitorValue: value,
            })
        }
        // eslint-disable-next-line
    }, [enableTwoYAxis])
    
    useDeepCompareEffect(() => {
        if (!competitorOptions?.length) return
        const vendors = competitorOptions.map(item => item.vendor)
        let newTmpCompetitorValue = cloneDeep(tmpCompetitorValue)
        newTmpCompetitorValue = newTmpCompetitorValue.filter(item => vendors.includes(item.vendor))
        setModuleData({ tmpCompetitorValue: newTmpCompetitorValue })
    }, [ region, competitorOptions, {} ])

    return (
        <CompetitorDropdown
            left={left}
            right={right}
            style={style}
            selectorClassName={selectorClassName}
            disabledFilter
            clearAllMinCount={1}
            clearAllType="empty"
            disabled={isLookbook}
            type={enableTwoYAxis ? DropdownType.SINGLE : DropdownType.MULTI}
            showSelectAll={true}
            value={tmpCompetitorValue.map(item => item.vendor)}
            onChange={value => {
                // const competitorValue = competitorOptions
                //     .filter(item => value.includes(item.vendor))
                //     .map(item => ({ vendor: item.vendor, region }))
                // setModuleData({ tmpCompetitorValue: competitorValue })
                // onChange?.()
            }}
            onItemChange={value => {
                const competitorValue = competitorOptions
                    .filter(item => value.includes(item.vendor))
                    .map(item => ({ vendor: item.vendor, region }))
                onChange?.(competitorValue)
            }}
            region={region}
            excludeVendorCodeList={[]}
            showOkButton={showOkButton}
        />
    )
}
