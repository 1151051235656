import React, { memo, useEffect, useState } from 'react'
import classNames from 'classnames'
import { CalendarOutlined, ClickAwayListener } from 'druikit'
import styles from './ShortcutDateGroupV2.module.scss'
import { dateRangeUtils } from './ShortcutDateGroupV2.util'
import DateRangePanel from './DateRangePanel'
import { getMaxDateRange, getThisYearStr } from 'utils/dateUtils'

enum DATE_ALIAS_ENUMS {
    LAST_WEEK = 'Last week',
    LAST_MONTH = 'Last month',
    LAST_QUARTER = 'Last quarter',
    LAST_6_MONTHS = 'Last 6 months',
    LAST_YEAR = 'Last year',
    THIS_YEAR = 'This year',
    MAX = 'Max',
}

const dateAliasList = [
    DATE_ALIAS_ENUMS.LAST_WEEK,
    DATE_ALIAS_ENUMS.LAST_MONTH,
    DATE_ALIAS_ENUMS.LAST_QUARTER,
    DATE_ALIAS_ENUMS.LAST_6_MONTHS,
    DATE_ALIAS_ENUMS.LAST_YEAR,
    DATE_ALIAS_ENUMS.THIS_YEAR,
    DATE_ALIAS_ENUMS.MAX,
]

interface ShortcutDateGroupProps {
    value: string;
    tmpDateRangeValue?: string;
    onChange: (value: string) => void;
}

const ShortcutDateGroup = ({
    value,
    tmpDateRangeValue,
    onChange,
}: ShortcutDateGroupProps) => {
    const maxDateRange = getMaxDateRange()
    const [ calendarOpen, setCalendarOpen ] = useState(false)
    const [ activeDateAlias, setActiveDateAlias ] = useState('')

    const dateMapper = {
        [DATE_ALIAS_ENUMS.LAST_WEEK]: () => dateRangeUtils.getWeek(1),
        [DATE_ALIAS_ENUMS.LAST_MONTH]: () => dateRangeUtils.getMonth(1),
        [DATE_ALIAS_ENUMS.LAST_QUARTER]: () => dateRangeUtils.getQuarter(1),
        [DATE_ALIAS_ENUMS.LAST_6_MONTHS]: () => {
            const lastSixMonths = [
                dateRangeUtils.getMonth(6).split('_')[0],
                dateRangeUtils.getMonth(1).split('_')[1],
            ].join('_')
            return lastSixMonths
        },
        [DATE_ALIAS_ENUMS.LAST_YEAR]: () => dateRangeUtils.getYear(1),
        [DATE_ALIAS_ENUMS.THIS_YEAR]: () => getThisYearStr(),
        [DATE_ALIAS_ENUMS.MAX]: () => maxDateRange,
    }

    const handleDateAlias = () => {
        const dateList = Object
            .values(dateMapper)
            .map(item => item())
        const dateAliasList = Object.keys(dateMapper)

        // 如果 tmpDateRangeValue 是随机值, dateAlias 直接设置为空, 表示此时修改了日历图标
        const tmpIndex = dateList.findIndex(d => d === tmpDateRangeValue)
        if (tmpIndex === -1) {
            setActiveDateAlias('')
            return
        } else {
            setActiveDateAlias(dateAliasList[tmpIndex])
            return
        }
    }

    useEffect(() => {
        handleDateAlias()
    }, [ tmpDateRangeValue ])

    const onClickShortcutDate = (dateAlias) => {
        setActiveDateAlias(dateAlias)
        if (!dateAlias) return
        const date = dateMapper[dateAlias]?.()
        onChange(date)
    }

    return (
        <div className={styles.wrapper}>
            {
                dateAliasList
                    .map(dateAlias => {
                    const disabled = false
                    return (
                        <div
                            key={dateAlias}
                            className={classNames({ 
                                [styles.shortcutDate]: true, 
                                [styles.shortcutDateActive]: activeDateAlias === dateAlias && tmpDateRangeValue === value,
                                [styles.shortcutDateActiveTmp]: activeDateAlias === dateAlias && tmpDateRangeValue !== value,
                                [styles.shortcutDateDisabled]: disabled,
                            })} 
                            onClick={() => {
                                if (disabled) return
                                onClickShortcutDate(dateAlias)
                            }}
                        >
                            {dateAlias}
                        </div>
                    )
                })
            }
            <div
                className={classNames({ 
                    [styles.shortcutDate]: true,
                    [styles.shortcutDateActive]: !activeDateAlias && tmpDateRangeValue === value,
                    [styles.shortcutDateActiveTmp]: !activeDateAlias && tmpDateRangeValue !== value,
                })}
                onClick={() => {
                    onClickShortcutDate('')
                    setCalendarOpen(true)
                }}
            >
                <CalendarOutlined size={16} color="#989898" style={{ marginTop: 16 }} />
            </div>
            {
                calendarOpen ? (
                    <ClickAwayListener
                        onClickAway={() => {
                            handleDateAlias()
                            setCalendarOpen(false)
                        }}
                    >
                        <div className={styles.calendarWrapper}>
                            <DateRangePanel
                                value={tmpDateRangeValue}
                                onChange={value => {
                                    onChange(value)
                                    handleDateAlias()
                                }}
                                maxDateRange={maxDateRange}
                            />
                        </div>
                    </ClickAwayListener>
                ) : null
            }
        </div>
    )
}

export default memo(ShortcutDateGroup)
