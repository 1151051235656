import React from 'react'
import classnames from 'classnames'
import { getFractionDigits } from 'utils'
import { NAN } from 'consts'
import { useFilterCurrency } from 'hooks/useFilter'
import styles from './styles.module.scss'
import { numberUtils } from 'norna-uikit'

export const Matrics = props => {
    const [ filterCurrency ] = useFilterCurrency()
    const isJpy = filterCurrency === 'JPY'
    const len = props.len || props.max
    return (
        <div
            className={styles.metrics}
            style={{ 
                paddingLeft: isJpy ? '25px' : '12px', 
                paddingRight: isJpy ? '36px' : '26px',
                cursor: typeof props?.onClick === 'function' ? 'pointer' : 'default', 
            }}
            onClick={props?.onClick}
        >
            <div>
                {
                    // eslint-disable-next-line
                    props.max === 0 && props.min === 0 && props.avg === 0 || String(props.max) === NAN ? null : (
                        <>
                            <div
                                className={styles.tangleLeft}
                                style={{
                                    left: `${(props.min / len) * 100}%`,
                                    right: `${(1 - props.max / len) * 100}%`, //eslint-disable-line
                                }}
                            />
                            <div
                                className={classnames(styles['matrics-label'], styles['min-label'])}
                                style={{ right: `${((len - props.min) / len) * 100}%` }}
                            > {numberUtils.formatNumberByComma(props.min.toFixed(getFractionDigits()))}
                            </div>
                            <div
                                className={classnames(styles['matrics-label'], styles['max-label'])}
                                style={{ left: `${(props.max / len) * 100}%` }}
                            > {numberUtils.formatNumberByComma(props.max.toFixed(getFractionDigits()))}
                            </div>
                            <div
                                className={classnames(styles['matrics-label'], styles['avg-label'])}
                                style={{ left: `${(props.avg / len) * 100}%` }}
                            > {numberUtils.formatNumberByComma(props.avg.toFixed(getFractionDigits()))}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}
