import React from 'react'
import classnames from 'classnames'
import { getFractionDigits } from 'utils'
import { NAN } from 'consts'
import { numberUtils } from '@norna/norna-uikit'
import styles from './styles.module.scss'

export const Matrics = props => {
    const len = props.len || props.max

    const { min, max, avg } = props

    const left = (len - (max - min)) / 2 / len * 100
    const right = left

    const minRight = 100 - left
    const maxLeft = 100 - left
    const avgLeft = left + (avg / len) * 100 - (min / len) * 100

    return (
        <div
            className={styles.metrics}
            style={{ 
                paddingLeft: 40, 
                paddingRight: 40, 
                cursor: typeof props?.onClick === 'function' ? 'pointer' : 'default', 
            }}
            onClick={props?.onClick}
        >
            <div>
                {
                    props.max === 0 && props.min === 0 && props.avg === 0 || String(props.max) === NAN ? null : (
                        <>
                            <div
                                className={styles.tangleLeft}
                                style={{
                                    left: left + '%',
                                    right: right + '%',
                                }}
                            />
                            <div
                                className={classnames(styles['matrics-label'], styles['min-label'])}
                                style={{ right: minRight + '%' }}
                            > 
                                {numberUtils.formatNumberByComma(props.min.toFixed(getFractionDigits()))}
                            </div>
                            <div
                                className={classnames(styles['matrics-label'], styles['max-label'])}
                                style={{ left: maxLeft + '%' }}
                            > 
                                {numberUtils.formatNumberByComma(props.max.toFixed(getFractionDigits()))}
                            </div>
                            <div
                                className={classnames(styles['matrics-label'], styles['avg-label'])}
                                style={{ left: avgLeft + '%' }}
                            > 
                                {numberUtils.formatNumberByComma(props.avg.toFixed(getFractionDigits()))}
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}
