export const OPTIONS_NAME = '# Options'
export const OPTIONS_IN_NAME = '# In'
export const OPTIONS_OUT_NAME = '# Out'

export const NA = 'N/A'
export const NAN = 'NaN'    // String(Number('a')) === 'NaN'

export const GENDER_CHILDREN = 'Children'

export const PRICE_PERSPECTIVE_ALL_ACTUAL = 'Sales prices'
export const PRICE_PERSPECTIVE_ALL_FULL = 'Full prices'
export const PRICE_PERSPECTIVE_ALL_INTRO_FULL = 'Introduction prices'
export const PRICE_PERSPECTIVE_HIGHEST_PRICE = 'Highest sales price'

export const SORTBY_INTRO_DATE = 'Introduction date'
export const SORTBY_NAME = 'Name'
export const SORTBY_MOST_POPULAR = 'Most popular'
export const SORTBY_HIGHEST_PRICE = 'Highest price'
export const SORTBY_LOWEST_PRICE = 'Lowest price'

// material
export const FABRIC_TECH_SPEC = 'Fabric tech spec'

export const PAGE_CONTAINER_CLASSNAME = 'page-container'

// Menu name
export const TIME_PERIOD = 'Time period'

// Dashboard
export const SUMMARY = 'Summary'

// Alert type
export const ALERT_TYPE_LAUNCHEDAFTER = 'launchedAfter'

// Material split
// ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/952
export const FILTERED_OUT = 'Filtered out'

/* *************************** qa_button ****************************** */
export const QA_BUTTON_QA_SIZE_VALUE = 'qa_size'
export const QA_BUTTON_QA_SIZE_LABEL = 'Size Harmonization'
export const QA_BUTTON_PRICE_BACKPOLATION_VALUE = 'price_backpolation'
export const QA_BUTTON_PRICE_BACKPOLATION_LABEL = 'Price backpolation'
export const QA_BUTTON_IN_OUT_FIX_VALUE = 'in_out_fix'
export const QA_BUTTON_IN_OUT_FIX_LABEL = 'In/out fix'
export const QA_BUTTON_CORE_RUN_VALUE = 'core_run'
export const QA_BUTTON_CORE_RUN_LABEL = 'Core run'
export const QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_VALUE = 'export_zalandodeep_shops'
export const QA_BUTTON_EXPORT_ZALANDODEEP_SHOPS_LABEL = 'Export true seller names'
export const QA_BUTTON_SPEED_BENCHMARK_VALUE = 'speed_benchmark'
export const QA_BUTTON_SPEED_BENCHMARK_LABEL = 'Speed benchmark'

// target 下拉框
export const QA_BUTTON_TARGET_TO_DB3 = 'to_db3'
export const QA_BUTTON_TARGET_UNDO_DB3 = 'undo_db3'
export const QA_BUTTON_TARGET_PRODUCT_DATA = 'product_data'
export const QA_BUTTON_TARGET_TO_SIZE_PRICE_MONITOR = 'to_size_price_monitor'
export const QA_BUTTON_TARGET_UNDO_SIZE_PRICE_MONITOR = 'undo_size_price_monitor'

export const NO_SIZE_NO_AVAILABLE_SIZE_UPDATE = 'No available size update'
export const NO_SIZE_NO_AVAILABLE_SIZE_UPDATE_V2 = 'No available size update v2'
export const NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE = 'No unavailable size update'
export const NO_SIZE_NO_UNAVAILABLE_SIZE_UPDATE_V2 = 'No unavailable size update v2'
export const NO_SIZE_NO_SIZE_HISTORY = 'No size history'
export const NO_SIZE_NO_HARMONIZED_SIZE = 'No harmonized size'
export const NO_SIZE_NO_TRUE_SELLER_INFORMATION_Z = 'No true seller information (Z)'
export const NO_SIZE_IGNORE_NO_AVAILABLE_SIZE = 'Exclude sold out'

export const PROPERTY_MULTI_PACK_VALUE = 'Multi Packs'
export const PROPERTY_MULTI_PACK_LABEL = 'Sixteen+ Packs'
