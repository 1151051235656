import React, {  } from 'react'
import TableBody from '@material-ui/core/TableBody'
import Table from '@material-ui/core/Table'
import styles from './styles.module.scss'
import { NornaTableProps } from './NornaTable.type'
import { Row } from '../Row'
import classNames from 'classnames'

export const NornaTable = ({
  style,
  className,
  columns,
  dataSource = [],

  categoryToVendor,
  metricsTab,
  metricTabFn,
  isTd,
  onExpand,
  collapseLineStyle = {},
  firstLevelArrowStyle = {},
}: NornaTableProps) => {
  return (
    <div
      style={style}
      className={classNames([ styles.metricsTable, className ])}
    >
      <Table 
        style={{ tableLayout: 'fixed', width: 'fit-content' }} 
        aria-label="collapsible table" 
        className="table-custom"
        test-id="norna-table"
      >
          <TableBody className="tableBody">
            {
              dataSource.map((rowData: any, rowIndex: number) => {
                return (
                  <Row
                    key={rowData.categoryName + rowData.vendorCode + rowIndex.toString()}
                    rowData={rowData}
                    columns={columns}

                    categoryToVendor={categoryToVendor}
                    setMetricsTab={metricTabFn}
                    metricsTab={metricsTab}
                    onExpand={onExpand}
                    collapseLineStyle={{ ...collapseLineStyle }}
                    firstLevelArrowStyle={firstLevelArrowStyle}
                    isTd={isTd}
                  />
                )
              })}
          </TableBody>
      </Table>
    </div>
  )
}

