import { priceSpreadsColumns } from 'consts/dashboardTableFields'

export const width01 = 80
export const width02 = 95

export const tabWidth = 148
export const optionsWidth = 120
export const categoryWidth = 180
export const priceIndexWidth = 75
export const arrowWidth = 40

export const lowPriceValueWidth = width01
export const lowPriceChangePercentWidth = width02
export const avgPriceValueWidth = width01
export const avgPriceChangePercentWidth = width02
export const highPriceValueWidth = width01
export const highPriceChangePercentWidth = width02
export const lowIndexWidth = priceIndexWidth
export const avgIndexWidth = priceIndexWidth
export const highIndexWidth = priceIndexWidth
export const priceSpreadWidth = 300

export const lowPriceWidth = lowPriceValueWidth + lowPriceChangePercentWidth
export const avgPriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth
export const highPriceWidth = highPriceValueWidth + highPriceChangePercentWidth

export const getTableScrollWidth = (fieldList: string[] = []) => {
    let width = tabWidth + arrowWidth + optionsWidth + categoryWidth
    if (fieldList.includes(priceSpreadsColumns.LOW_PRICE_VALUE)) {
        width += lowPriceWidth
    }
    if (fieldList.includes(priceSpreadsColumns.AVG_PRICE_VALUE)) {
        width += avgPriceWidth
    }
    if (fieldList.includes(priceSpreadsColumns.HIGH_PRICE_VALUE)) {
        width += highPriceWidth
    }
    if (fieldList.includes(priceSpreadsColumns.LOW_INDEX)) {
        width += lowIndexWidth + avgIndexWidth + highIndexWidth
    }
    if (fieldList.includes(priceSpreadsColumns.PRICE_SPREAD)) {
        width += priceSpreadWidth
    }
    return width
}
