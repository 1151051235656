import React from 'react'
import classNames from 'classnames'
import * as config from './config'
import styles from './Thead.module.scss'
import { Flex, Tooltip } from 'antd'
import { getTableTitleTooltip } from 'utils'
import { mostPopularLaunchDaysColumns } from 'consts/dashboardTableFields'

let dragKey

const Thead = ({
    width,
    fieldList = [],
    sortList = [],
    onDrag,
}: {
    width?: number;
    fieldList?: string[];
    sortList?: string[];
    onDrag?: (dragKey, dropKey) => void;
}) => {
    const onDragStart = e => {
        dragKey = e.currentTarget.getAttribute('data-key')
        e.dataTransfer.effectAllowed = 'move'
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropKey = e.currentTarget.getAttribute('data-key')
        if (dragKey === dropKey) return
        onDrag?.(dragKey, dropKey)
    }

    return (
        <div className={classNames([ 'most-popular-launch-days-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td 
                            width={config.tabWidth} 
                            align="center"
                            style={{ order: -3 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    Vendors
                                </div>
                            </Flex>
                        </td>
                        <td 
                            width={config.optionsWidth}
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth, order: -2 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    <Tooltip title={getTableTitleTooltip('# In')} zIndex={9999999}>
                                        <span># In</span>
                                    </Tooltip>
                                </div>
                            </Flex>
                        </td>
                        <td
                            width={config.categoryWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth + config.optionsWidth, order: -1 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    Category
                                </div>
                            </Flex>
                        </td>
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.MONDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.MONDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.MONDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Monday')} zIndex={9999999}>
                                                <span>Monday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.TUESDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.TUESDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.TUESDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Tuesday')} zIndex={9999999}>
                                                <span>Tuesday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.WEDNESDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.WEDNESDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.WEDNESDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Wednesday')} zIndex={9999999}>
                                                <span>Wednesday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.THURSDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.THURSDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.THURSDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Thursday')} zIndex={9999999}>
                                                <span>Thursday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.FRIDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.FRIDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.FRIDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Friday')} zIndex={9999999}>
                                                <span>Friday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.SATURDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.SATURDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.SATURDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Saturday')} zIndex={9999999}>
                                                <span>Saturday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(mostPopularLaunchDaysColumns.SUNDAY) && (
                                <td 
                                    width={config.weekdayWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(mostPopularLaunchDaysColumns.SUNDAY) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={mostPopularLaunchDaysColumns.SUNDAY}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRowWeekday}>
                                            <Tooltip title={getTableTitleTooltip('Sunday')} zIndex={9999999}>
                                                <span>Sunday</span>
                                            </Tooltip>
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        <td 
                            className="tableArrowTd bgWhite" 
                            width={config.arrowWidth}
                            style={{ order: 99 }}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
