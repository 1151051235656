import React, { ReactNode } from 'react'
import classNames from 'classnames'
import * as config from './config'
import styles from './Thead.module.scss'
import { Flex, Tooltip } from 'antd'
import { useFilterCurrency } from 'hooks/useFilter'
import { OPTIONS_NAME } from 'consts'
import { getTableTitleTooltip } from 'utils'
import { assortmentInsightsColumns } from 'consts/dashboardTableFields'
import { ComparisonTooltipV3 } from 'componentsv2/business/ComparisonTooltip/ComparisonTooltipV3'

const PrimaryText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <span style={{ color: '#01A699' }}>{children}</span>
    )
}

let dragKey

const Thead = ({
    width,
    isCompetitorView = true,
    fieldList = [],
    sortList = [],
    onDrag,
}: {
    width?: number;
    isCompetitorView?: boolean;
    fieldList?: string[];
    sortList?: string[];
    onDrag?: (dragKey, dropKey) => void;
}) => {
    const [ filterCurrency ] = useFilterCurrency()

    const onDragStart = e => {
        dragKey = e.currentTarget.getAttribute('data-key')
        e.dataTransfer.effectAllowed = 'move'
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropKey = e.currentTarget.getAttribute('data-key')
        if (dragKey === dropKey) return
        onDrag?.(dragKey, dropKey)
    }

    return (
        <div className={classNames([ 'assortment-insights-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td 
                            width={config.tabWidth} 
                            align="center"
                            style={{ order: -3 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Vendors' : 'Categories'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        <td
                            width={config.optionsWidth}
                            className={styles.fixedColumn}
                            style={{ left: config.tabWidth, order: -2 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    <Tooltip title={getTableTitleTooltip(OPTIONS_NAME)} zIndex={9999999}>
                                        <span># Options</span>
                                    </Tooltip>
                                </div>
                                <Flex 
                                    justify="space-between" 
                                    style={{ paddingRight: 30 }}
                                    className={styles.subtitleRow}
                                >
                                    <span>#</span> 
                                    <span>+/- <PrimaryText>%</PrimaryText></span>
                                </Flex>
                            </Flex>
                        </td>
                        <td
                            width={config.categoryWidth}
                            className={styles.fixedColumn}
                            style={{ left: config.tabWidth + config.optionsWidth, order: -1 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Category' : 'Vendor'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        {
                            fieldList.includes(assortmentInsightsColumns.CATEGORY_SPLIT_VALUE) && (
                                <td 
                                    width={config.categorySplitWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.CATEGORY_SPLIT_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.CATEGORY_SPLIT_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Category split')} zIndex={9999999}>
                                                <span>Category split</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.categorySplitValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div 
                                                style={{ width: config.categorySplitChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.SIZE_OF_LINE_VALUE) && (
                                <td 
                                    width={config.sizeOfLineWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.SIZE_OF_LINE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.SIZE_OF_LINE_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Size of line')} zIndex={9999999}>
                                                <span>Size of line</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.sizeOfLineValueWidth }}>
                                                &nbsp;
                                            </div>
                                            <div 
                                                style={{ width: config.sizeOfLineChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.SIZE_RATIO_VALUE) && (
                                <td 
                                    width={config.sizeRatioWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.SIZE_RATIO_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.SIZE_RATIO_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Size ratio')} zIndex={9999999}>
                                                <span>Size ratio</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.sizeRatioValueWidth }}>
                                                &nbsp;
                                            </div>
                                            <div 
                                                style={{ width: config.sizeRatioChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.OPTION_RATIO_VALUE) && (
                                <td 
                                    width={config.optionRatioWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.OPTION_RATIO_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.OPTION_RATIO_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Option ratio')} zIndex={9999999}>
                                                <span>Option ratio</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.sizeRatioValueWidth }}>
                                                &nbsp;
                                            </div>
                                            <div 
                                                style={{ width: config.sizeRatioChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.SOLD_OUT_VALUE) && (
                                <td 
                                    width={config.soldOutWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.SOLD_OUT_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.SOLD_OUT_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Sold out')} zIndex={9999999}>
                                                <span>Sold out</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.soldOutValueWidth }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div
                                                style={{ width: config.soldOutChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.UNIQUE_OPTIONS_VALUE) && (
                                <td 
                                    width={config.uniqueOptionsWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.UNIQUE_OPTIONS_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.UNIQUE_OPTIONS_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('# Unique options')} zIndex={9999999}>
                                                <span># Unique options</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.width01 }}>
                                                &nbsp;
                                            </div>
                                            <div
                                                style={{ width: config.width02 }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.ROTATION_RATIO_VALUE) && (
                                <td 
                                    width={config.optionRotationRatioWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.ROTATION_RATIO_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.ROTATION_RATIO_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Rotation ratio')} zIndex={9999999}>
                                                <span>Rotation ratio</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.width01 }}>
                                                &nbsp;
                                            </div>
                                            <div
                                                style={{ width: config.width02 }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.LIFE_SPAN_VALUE) && (
                                <td 
                                    width={config.lifeSpanWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.LIFE_SPAN_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.LIFE_SPAN_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Lifespan, days')} zIndex={9999999}>
                                                <span>Lifespan, days</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.width01 }}>
                                                &nbsp;
                                            </div>
                                            <div
                                                style={{ width: config.width02 }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.ECO_LABEL_VALUE) && (
                                <td 
                                    width={config.ecoLabelWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.ECO_LABEL_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.ECO_LABEL_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Eco label')} zIndex={9999999}>
                                                <span>Eco label</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.width01 }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div
                                                style={{ width: config.width02 }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(assortmentInsightsColumns.SOLID_VALUE) && (
                                <td 
                                    width={config.solidWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(assortmentInsightsColumns.SOLID_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={assortmentInsightsColumns.SOLID_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Solid')} zIndex={9999999}>
                                                <span>Solid</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.width01 }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div
                                                style={{ width: config.width02 }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        <td 
                            className="tableArrowTd bgWhite" 
                            width={config.arrowWidth}
                            style={{ order: 99 }}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
