import { inOutMonitoringColumns } from 'consts/dashboardTableFields'

export const width01 = 80
export const width02 = 95

export const tabWidth = 148
export const optionsWidth = 120
export const categoryWidth = 180
export const arrowWidth = 40

export const inValueWidth = width01
export const inChangePercentWidth = width02
export const avgPriceValueWidth = width01
export const avgPriceChangePercentWidth = width02
export const outValueWidth = width01
export const outChangePercentWidth = width02
export const rotationValueWidth = width01
export const rotationChangeWidth = width02
export const priceSpreadWidth = 300

export const inWidth = inValueWidth + inChangePercentWidth
export const avgPriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth
export const outWidth = outValueWidth + outChangePercentWidth
export const rotationWidth = rotationValueWidth + rotationChangeWidth

export const getTableScrollWidth = (fieldList: string[] = []) => {
    let width = tabWidth + arrowWidth + optionsWidth + categoryWidth
    if (fieldList.includes(inOutMonitoringColumns.IN_VALUE)) {
        width += inWidth
    }
    if (fieldList.includes(inOutMonitoringColumns.AVG_PRICE_VALUE)) {
        width += avgPriceWidth
    }
    if (fieldList.includes(inOutMonitoringColumns.OUT_VALUE)) {
        width += outWidth
    }
    if (fieldList.includes(inOutMonitoringColumns.ROTATION_VALUE)) {
        width += rotationWidth
    }
    if (fieldList.includes(inOutMonitoringColumns.PRICE_SPREAD)) {
        width += priceSpreadWidth
    }
    return width
}
