import { useState, useEffect } from 'react'
import {
  initEmptyFilter,
  initFilter,
  initFromSharedFilter,
} from 'features/filters/filtersSlice'
import { cloneDeep, isEmpty, uniqBy } from 'lodash'
import { formatMetaCategory } from 'components/layout/Header/components/HeaderFilter/components/FilterListData'
import { getCacheData, initRegionsAndTargetsData, updateQaPermission } from 'features/filters/cacheDataSlice'
import { formatCollection } from 'components/layout/Header/components/HeaderFilter/utils'
import { getDateRangeValueByPeriod } from 'componentsv2/form-elements/DatePicker'
import { useDispatch, useSelector } from 'react-redux'
import { IS_LIVE_ENV, SHARE_FILTER_KEY } from 'consts'
import { sortCategoryFn } from 'utils/sortCategory'
import { lsGetLicenseId, lsSet, setLicenseId } from 'utils/ls'
import { getCodeAndNameMapperBySellers } from 'utils'
import { storage } from 'utils/storage'
import { updateCalendarComparisonDate, updateCalendarDate, updateCalendarCompareToValue, updateConvertCurrency, updateCalendarPeriodValue, updateCalendarSelectionDate, updateVersionRead, updateFavoriteList } from 'features/filters/globalDataSlice'
import { initNotShowSubGroupsMap } from 'components/layout/Header/components/HeaderFilter/property.util'
import { getFilterGenderAllValue } from 'components/layout/Header/components/HeaderFilter/gender.util'
import { updateCollectionList } from 'features/filters/collectionDataSlice'
import { getAllFilterCategoryValue, getAllFilterCompetitorValue } from 'utils/filterUtils'
import { useUrlParams } from './useUrlParams'
import { useLogin } from './useLogin'
import { initDynamicDashboardFormData } from 'features/filters/dynamicDashboardSlice'
import { COMPARE_TO_PREVIOUS_PERIOD, PERIOD_LAST_WEEK } from 'componentsv2/MenuBar/GlobalDatePicker/GlobalDatePicker.const'
import { CROSS_REGION_SOLD_OUT_NAME } from 'configs/moduleName'
import { updateModuleData2 } from 'features/filters/moduleDataSlice'
import { getToday } from 'utils/dateUtils'
import { useFetch } from 'libs'
import { releaseNote } from 'configs/releaseNote'
import { convertPermisionToMenuAndFilterItems } from 'utils/properties'
import { useLookbook } from './useLookbook'
import { RoutesObj } from 'global/Routes'
import { showUpdatePwdDialog } from 'componentsv2/UpdatePwdDialog'

export const initPageDate = () => {
  const gloablDateValue = storage.getGlobalDateValue()
  const recordDate = storage.getRecordDate()
  const today = getToday()
  if (gloablDateValue && recordDate && recordDate === today) {
    return gloablDateValue
  }
  return getDateRangeValueByPeriod('oneWholeWeekAgo')
}

export const initComparisonPageDate = () => {
  const gloablComparisonDateValue = storage.getGlobalComparisonDateValue()
  const recordComparisonDate = storage.getRecordComparisonDate()
  const today = getToday()
  if (gloablComparisonDateValue && recordComparisonDate && recordComparisonDate === today) {
    return gloablComparisonDateValue
  }
  return getDateRangeValueByPeriod('twoWholeWeeksAgo')
}

/**
 *
 * @todo
 * considering cache data in localstorage
 *
 * @desc
 * the hook of reading cache data
 * - the cache data is stored in mem
 * - for one of below two reason we need to read cache date from api
 * 1. refreshing page
 * 2. logining by account
 * 
 * user = { nickname, username, email }
 */
export const useReadCache = user => {
  const dispatch = useDispatch()

  // request from mem  selection
  const { isReady } = useSelector(getCacheData)

  // request from api selection
  const [ sharedFilterInfo, setSharedFilterInfo ] = useState('')// querying share filter res by key

  const pathSearchObj = useUrlParams()
  const isSharedLink = pathSearchObj[SHARE_FILTER_KEY]

  /* ******************************************************************** */
  const { 
    getFn: fetchInitialisation, 
    data: initialisationData,
    loading: initialisationLoading, 
    error: initialisationError,
  } = useFetch()

  /**
   * 刷新页面场景: 用户存在(localStorage 中的 email), isReady 为 false 表示刷新页面或第一次登录时
   */
  useEffect(() => {
    if (user?.email && !isReady) {
      const payload = {
        url: '/initialisation',
        query: {
          key_hash: isSharedLink,
          version: releaseNote.version,
        },
      }
      fetchInitialisation(payload.url, payload.query)
    }
  }, [ user, isReady ]);  //eslint-disable-line

  useEffect(() => {
    if (initialisationError?.status === 401) {
      window.location.href = RoutesObj.LOGIN.URL
    }
  }, [ initialisationError ])

  const { initNewUser } = useLogin()
  const { setLookbookList, setMyLookbookList, setLatestLookbook } = useLookbook()

  useEffect(() => {
    if (!isReady && initialisationData) {

      const {
        // account
        company,
        sellers = [],
        ourSeller = {},
        vendorOrder = [],
        email,
        // metadata
        blacklist,
        latest_date,
        metacategory,
        properties = {},
        // release_note
        read = false,
        // favorite list
        myfavorite = [],
        // alert_messages
        ignored_alert_messages = [],
        // get_filter
        filter,
        // collections
        filters = [],
        // convert_currency
        cash_convert = [],
        // dashboard_get_target_group_region_currency
        available_currency: currencys = [],
        available_region: regions = [],
        available_target_group: targets = [],
        region_currency_mapping: regionToCurrency = {},
        unavailable_currency: uncurrencys = [],
        unavailable_filter = [],
        unavailable_menu = [],
        unavailable_region: unregions = [],
        unavailable_target_group: untargets = [],
        // fe_authentication
        fe_authorization = [],
        // myLookbooks
        lookbooks = [],
        // findAccountAllCompany
        licenses = [],
        isUpdatePassword = false,
      } = initialisationData

      const { unavailableMenu, unavailableFilter } = convertPermisionToMenuAndFilterItems(unavailable_menu, unavailable_filter)
      const companyId = company?.id
      const feConfig = initialisationData?.fe_config || {}
      const dynamicDashboardSettings = initialisationData?.dynamic_dashboard_settings || {}
      const metaStructureId = initialisationData?.meta_structure_id || ''
      const accountId = initialisationData?.id || ''
      const restAccount = {
        company,
        dynamic_dashboard_settings: dynamicDashboardSettings,
        email,
        fe_config: feConfig,
        id: accountId,
        meta_structure_id: metaStructureId,
      }
      const data = {
        regions,
        unregions,
        currencys,
        uncurrencys,
        targets: targets.sort(),
        untargets,
        regionToCurrency,
        unavailableMenu,
        unavailableFilter,
      }

      /**
       * 获取前端缓存中的 licenseId
       * 与接口返回的登录账户的 licenseId 做比较
       * 如果不一样, 重新加载页面
       */
      const licenseId = lsGetLicenseId()
      if (licenseId && companyId !== licenseId) {
        setLicenseId(companyId)
        return window.location.reload()
      }

      initNewUser(null, initialisationData.email, companyId, initialisationData.id)

      // update alert messages
      storage.setAlertMessages(ignored_alert_messages)

      // update latest data
      const latestDate = latest_date ?? new Date().toISOString().slice(0, 10)
      lsSet('latestDate', latestDate)

      /* **************************** lookbooks *********************************** */
      setLookbookList(lookbooks.map(item => ({
        id: item.id,
        count: item.lookbookproductions?.length ?? 0,
        name: item.name,
      })))
      setMyLookbookList(lookbooks.map(item => item))
      setLatestLookbook(lookbooks[lookbooks.length - 1])

      /**
       * 2022/04/14 需求:
       * 切换到 Bogner license，TD 页面，将表格上面的 Region 下拉框切换为 China 时，下面线图上面的 Competitor 下拉框里没有 Bogner 自己
       * 原因是: 接口返回的 sellers 里就没有 Bogner - China 这个组合
       * 
       * 这里需要做处理：当某个 Region 没有自己时，需要加上，仅针对 customer
       */
      // 获取所有 region 列表
      let regionCurrencyList = sellers.map(item => ({ region: item.region, currency: item.currency }))
      regionCurrencyList = uniqBy(regionCurrencyList, 'region')
      const customerSellers = sellers.filter(item => item.vendor === ourSeller?.code)
      if (ourSeller?.code && customerSellers.length > 0 && customerSellers.length !== regionCurrencyList.length) {
        const name = customerSellers[0]?.name
        const excludeRegionCurrencyList = regionCurrencyList.filter(item => customerSellers.findIndex(item2 => item2.region === item.region) === -1)
        const excludeCustomerSellers = excludeRegionCurrencyList.map(item => ({
          brands: [],
          currency: item.currency,
          region: item.region,
          name,
          vendor: ourSeller?.code,
        }))
        sellers.push(...excludeCustomerSellers)
      }

      storage.setLoginTime()

      const metaCategories = formatMetaCategory(metacategory || [])

      /** *
       * 功能:  Category基础数据按既定规则 排序
       */
      metaCategories.list = ourSeller.code === 'marimekko' ? sortCategoryFn(metaCategories.list, 'name', 'list') : metaCategories.list

      /**
       * set blacklist to localStorage
       */
      storage.setBlacklist(blacklist)

      /**
       * set fe_config to localStorage
       */
      storage.setFeConfig(feConfig)

      /**
       * set meta_structure_id to localStorage
       */
      storage.setMetaStructureId(metaStructureId)

      /**
       * set id to localStorage
       */
      storage.setAccountId(accountId)

      /**
       * set email to localStorage
       */
      storage.setEmail(email)

      /**
       * set dynamic_dashboard_settings to localStorage
       */
      storage.setDynamicDashboardSettings(dynamicDashboardSettings)

      /**
       * 
       * {
       *  [Category]: [{Property Object}]
       * }
       */
      const notShowSubGroupsMap = initNotShowSubGroupsMap(properties)
      storage.setNotShowSubGroupsMap(notShowSubGroupsMap)

      /**
       * set categoryList to localStorage
       */
      storage.setCategoryTreeList(metaCategories.list || [])
      storage.setCategoryTreeObj(metaCategories.listObj || {})

      /**
       * set customerVendor to localStorage
       */
      const customerVendor = ourSeller.code
      storage.setCustomerVendor(customerVendor)
      storage.setPovVendor(ourSeller?.pov || customerVendor)

      /**
       * set vendorOrder to localStorage
       */
      storage.setVendorOrder(vendorOrder || [])

      /**
       * set region_currency_mapping to localStorage
       */
      storage.setRegionToCurrency(regionToCurrency)

      /**
       * set licenses to localStorage
       */
      storage.setLicenses(licenses)

      /**
       * set sellers, codenames, namecodes to localStorage
       */
      const { codenames, namecodes } = getCodeAndNameMapperBySellers(sellers)
      storage.setSellers(sellers || [])
      storage.setCodenames(codenames || {})
      storage.setNamecodes(namecodes || {})

      /**
       * set configs to localStorage
       */
      storage.setConfigs(data)

      /**
       * set properties to localStorage
       */
      storage.setProperties(properties)

      /* *********************** 更新 globalDataSlice ************************ */
      dispatch(updateConvertCurrency(cash_convert))

      const calendarDate = initPageDate()
      const calendarComparisonDate = initComparisonPageDate()
      // pageDate 默认值设置为最近一周
      dispatch(updateCalendarDate(calendarDate))
      dispatch(updateCalendarComparisonDate(calendarComparisonDate))
      dispatch(updateCalendarPeriodValue(PERIOD_LAST_WEEK))
      dispatch(updateCalendarCompareToValue(COMPARE_TO_PREVIOUS_PERIOD))
      dispatch(updateCalendarSelectionDate(getToday()))

      // versionRead
      dispatch(updateVersionRead(read))

      // favorite list
      dispatch(updateFavoriteList(myfavorite))

      /* *********************** 更新 moduleDataSlice ************************ */
      // sold out cross region 的 metricValue 需要初始化
      dispatch(updateModuleData2({
        moduleName: CROSS_REGION_SOLD_OUT_NAME,
        payload: { 
          metricValue: [ `${ourSeller.region} sold out` ], 
          tmpMetricValue: [ `${ourSeller.region} sold out` ], 
        },
      }))

      /* *********************** 更新 collectionDataSlice ************************ */
      dispatch(updateCollectionList(filters || []))

      // 这一步操作会将 isReady 设置为 true
      dispatch(initRegionsAndTargetsData({
        ...data,
        latestDate,
        metaCategories,
        notShowSubGroupsMap,
        originalProperties: properties,
        sellers,
        account: restAccount,
        vendorOrder,
        ourSeller: {
          ...restAccount,
          vendor: ourSeller.code,
          ...ourSeller,
        },
      }))

      dispatch(updateQaPermission(fe_authorization))

      /* *********************** 更新 dynamic dashboard 表单值 ************************ */
      dispatch(initDynamicDashboardFormData({
        region: ourSeller.region,
        customer: ourSeller.code,
      }))

      /**
       * 在 Open in new 打开的窗口中不要显示这条提示信息
       * 因为打开 Open in new 的时候一定是已登录状态
       */
      const isOpenInNewWindow = (window?.location?.search || '').indexOf('openinnew=yes') !== -1
      const latestQuery = {}
      const sharedFilter = JSON.parse(filter || '{}')
      if (isEmpty(sharedFilter) && isSharedLink && !isOpenInNewWindow) {
        setSharedFilterInfo('Your account does not have the shared license or the shared link is invalid')
      }

      if (!isEmpty(sharedFilter)) {           // init filter from api
        const lQ = cloneDeep(sharedFilter)
        dispatch(initFromSharedFilter(lQ))
      } else if (!isEmpty(latestQuery)) {           // init filter from api
        const lQ = cloneDeep(latestQuery)
        // eslint-disable-next-line
        // if (!latestQuery.comparisons && latestQuery.__comparisons__) lQ.comparisons = lQ.__comparisons__;

        const comparison = formatCollection(lQ, null)
        if (comparison.collection?.query?.targetGroups?.length && data.targets.length) {
          const tagets: Array<string> = data.targets
          comparison.collection.query.targetGroups = comparison.collection.query.targetGroups
            .filter((item: string) => tagets.indexOf(item) > -1)
        }
        // priceFilter
        dispatch(initFilter(comparison))
      } else {
        // init price filter from url
        let priceFilter = {}
        let otherQuery = {}
        // eslint-disable-next-line
        if (pathSearchObj._queryFrom && pathSearchObj.priceFilter) { //query from url
          priceFilter = {
            priceFilter: { val: pathSearchObj.priceFilter },
          }
        } else { // query from db
          /**
           * Filter > Country 默认选中当前登录 License 对应的 Region
           */
          const [ region ] = ourSeller?.region ? [ ourSeller?.region ] : data.regions
          otherQuery = {
            region: {
              key: region ?? '',
              /**
               * the priority of default currency 
               * 1. localstorage (last applied)
               * 2. last saved
               * 3. region-currency map
               */
              currency: region && regionToCurrency?.[region] ? regionToCurrency?.[region] : '',
            },
            targets: getFilterGenderAllValue(),
          }
        }

        // 初始化 Filter > Competitor
        const region = data?.regions?.[0]
        dispatch(initEmptyFilter({ // set default region/targets value for Filter
          ...priceFilter,
          ...otherQuery,
          categories: getAllFilterCategoryValue(),
          sellers: getAllFilterCompetitorValue(ourSeller?.region || region),
        }))
      }

      if (isUpdatePassword) {
        setTimeout(() => {
          showUpdatePwdDialog()
        }, 1000)
      }
    }
  }, [ initNewUser, pathSearchObj, isSharedLink, isReady, dispatch, initialisationData ])

  return {
    isReady,
    error: initialisationError,
    sharedFilterInfo,
    loading: initialisationLoading,
  }
}
