import React, { useEffect, useState } from 'react'
import { Flex, Flex1 } from 'componentsv2/Flex'
import { CategoryCompetitorSwitch } from 'componentsv2/business/CategoryCompetitorSwitch'
import { ExportDropdown } from 'componentsv2/ExportDropdown'
import { getExportSelector } from 'utils'
import { useModuleData } from 'hooks/useModuleData'
import { cloneDeep } from 'lodash'
import { downloadFileByAxios } from 'export'
import { LICENSE_CANADAGOOSE, MARKET_VENDOR, SELECTED_VENDORS } from 'consts'
import { getExportFilenameByModule } from 'utils/exportUtils'
import { GenderDropdown } from 'componentsv2/business/GenderDropdown'
import { RegionDropdown } from 'componentsv2/business/RegionDropdown'
import { CompetitorDropdown } from 'componentsv2/business/CompetitorDropdown'
import { useFilterCategory, useFilterCompetitor, useFilterCountry } from 'hooks/useFilter'
import { MultiCategoryDropdown } from 'componentsv2/business/CategoryDropdown'
import { PricePerspectiveDropdown } from 'componentsv2/business/PricePerspectiveDropdown'
import { getLeafCategories } from 'components/layout/Header/components/HeaderFilter/category.util'
import { storage } from 'utils/storage'
import { isPriceInsightPage } from 'global/Routes'
import { useLookbook } from 'hooks/useLookbook'
import GraphButton from 'componentsv2/GraphButton'
import { useWindowZoom } from 'hooks/useWindow'
import { getChromeVersion } from 'utils/browserUtils'

export const DashboardTableForm = ({
    moduleName,
    customePointOfView,
    onExport,
    fieldElement,
}: {
    moduleName: string;
    customePointOfView?: any;
    onExport?: any;
    fieldElement?: any;
}) => {
    const zoom = useWindowZoom()
    const [ moduleData, setModuleData ] = useModuleData(moduleName)
    const { fetchPayload, isCompetitorView } = moduleData
    
    const [ filterCompetitor, setFilterCompetitor ] = useFilterCompetitor()
    const [ filterCountry ] = useFilterCountry()
    const [ filterCategory, setFilterCategory ] = useFilterCategory()
    const { isLookbook } = useLookbook()

    // ref: https://gitlab.com/norna/pricing-hub-front-end/pricing-hub-front-end/-/issues/832
    const [ switchValue, setSwitchValue ] = useState(true)

    useEffect(() => {
        if (isPriceInsightPage()) {
            setSwitchValue(storage.getCustomerVendor() !== LICENSE_CANADAGOOSE)
        }
    }, [])

    /* ****************************** Export *********************************** */
    const exportSelector = getExportSelector(moduleName)
    const exportFilename = getExportFilenameByModule(moduleName)

    const onExportExcel = async () => {
        if (typeof onExport === 'function') {
            onExport?.()
            return
        }
        const payload = cloneDeep(fetchPayload)
        payload.query.by_category = !isCompetitorView
        if (payload?.data?.groups[SELECTED_VENDORS]) {
            payload.data.competitors = payload.data.competitors.filter(item => item !== MARKET_VENDOR.vendor)
        }

        if (isCompetitorView && payload?.data?.groups?.[SELECTED_VENDORS] && !filterCompetitor.map(item => item.vendor).includes(SELECTED_VENDORS)) {
            delete payload?.data?.groups?.[SELECTED_VENDORS]
        }

        payload.query.has_selected_vendors = filterCompetitor.map(s => s.vendor).includes(SELECTED_VENDORS)

        await downloadFileByAxios({
            filename: exportFilename,
            payload,
        })
    }

    return (
        <Flex justifyContent="space-between" alignItems="flex-end" className="form">
            <GenderDropdown
                right
                enableFilter
                enableLoadingBar
                disabled={isLookbook}
            />
            <RegionDropdown
                right
                whetherUpdateCurrency
                enableFilter
                enableLoadingBar
                disabled={isLookbook}
            />
            <MultiCategoryDropdown
                zoom={getChromeVersion() > 127 ? zoom : 1}
                right
                excludeTotal
                multiple
                minCount={1}
                disabled={isLookbook}
                value={filterCategory}
                onChange={value => {
                    if (storage.getIsLookbook()) return
                    if (!value.length) {
                        const categoryTreeList = storage.getCategoryTreeList()
                        const firstLevelCategoryList = categoryTreeList.map(item => item.name).filter(item => item)
                        setFilterCategory(getLeafCategories({ selectedCategories: firstLevelCategoryList.slice(0, 1) }))
                    } else {
                        setFilterCategory([ ...value ])
                    }
                }}
            />
            <CompetitorDropdown
                maxCount={9999}
                showSelectAll
                showClearAll
                disabledFilter
                right
                clearAllMinCount={1}
                clearAllType="empty"
                disabled={isLookbook}
                value={filterCompetitor.map(seller => seller.vendor)}
                onChange={value => {
                    setFilterCompetitor(value.map(vendor => ({ vendor, region: filterCountry })))
                }}
            />
            <PricePerspectiveDropdown
                right
                enableFilter
                enableLoadingBar
                disabled={isLookbook}
            />
            {fieldElement}
            <ExportDropdown
                left
                right
                selector={exportSelector}
                fileName={exportFilename}
                onExportExcel={() => onExportExcel?.()}
            />
            <GraphButton />
            <Flex1 />
            {
                customePointOfView ? (
                    <>
                        {customePointOfView}
                    </>
                ) : (
                    <CategoryCompetitorSwitch
                        right={10}
                        value={switchValue} 
                        onChange={value => {
                            setSwitchValue(value)
                            setModuleData({ switchLoading: true })
                            setTimeout(() => {
                                setModuleData({ 
                                    isCompetitorView: value,
                                    switchLoading: false,
                                })
                            }, 2000)
                        }} 
                    />
                )
            }
        </Flex>
    )
}
