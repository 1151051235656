import React, { ReactNode } from 'react'
import classNames from 'classnames'
import * as config from './config'
import styles from './Thead.module.scss'
import { Flex, Tooltip } from 'antd'
import { useFilterCurrency } from 'hooks/useFilter'
import { getTableTitleTooltip } from 'utils'
import { OPTIONS_NAME } from 'consts'
import { priceSpreadsColumns } from 'consts/dashboardTableFields'
import { ComparisonTooltipV3 } from 'componentsv2/business/ComparisonTooltip/ComparisonTooltipV3'

const PrimaryText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <span style={{ color: '#01A699' }}>{children}</span>
    )
}

let dragKey

const Thead = ({
    width,
    isCompetitorView = true,
    fieldList = [],
    sortList = [],
    onDrag,
}: {
    width?: number;
    isCompetitorView?: boolean;
    fieldList?: string[];
    sortList?: string[];
    onDrag?: (dragKey, dropKey) => void;
}) => {
    const [ filterCurrency ] = useFilterCurrency()

    const onDragStart = e => {
        dragKey = e.currentTarget.getAttribute('data-key')
        e.dataTransfer.effectAllowed = 'move'
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropKey = e.currentTarget.getAttribute('data-key')
        if (dragKey === dropKey) return
        onDrag?.(dragKey, dropKey)
    }

    return (
        <div className={classNames([ 'price-spreads-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td 
                            width={config.tabWidth} 
                            align="center"
                            style={{ order: -3 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Vendors' : 'Categories'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        <td 
                            width={config.optionsWidth}
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth, order: -2 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    <Tooltip title={getTableTitleTooltip(OPTIONS_NAME)} zIndex={9999999}>
                                        <span># Options</span>
                                    </Tooltip>
                                </div>
                                <Flex 
                                    justify="space-between" 
                                    style={{ paddingRight: 30 }}
                                    className={styles.subtitleRow}
                                >
                                    <span>#</span> 
                                    <span>+/- <PrimaryText>%</PrimaryText></span>
                                </Flex>
                            </Flex>
                        </td>
                        <td
                            width={config.categoryWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth + config.optionsWidth, order: -1 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Category' : 'Vendor'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        {
                            fieldList.includes(priceSpreadsColumns.LOW_PRICE_VALUE) && (
                                <td 
                                    width={config.lowPriceWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceSpreadsColumns.LOW_PRICE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceSpreadsColumns.LOW_PRICE_VALUE}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Low price')} zIndex={9999999}>
                                                <span>Low price</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.lowPriceValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div
                                                style={{ width: config.lowPriceChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceSpreadsColumns.AVG_PRICE_VALUE) && (
                                <td 
                                    width={config.avgPriceWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceSpreadsColumns.AVG_PRICE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceSpreadsColumns.AVG_PRICE_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Average price')} zIndex={9999999}>
                                                <span>Average price</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.avgPriceValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div
                                                style={{ width: config.avgPriceChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceSpreadsColumns.HIGH_PRICE_VALUE) && (
                                <td 
                                    width={config.highPriceWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceSpreadsColumns.HIGH_PRICE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceSpreadsColumns.HIGH_PRICE_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('High price')} zIndex={9999999}>
                                                <span>High price</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.highPriceValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div
                                                style={{ width: config.highPriceChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceSpreadsColumns.LOW_INDEX) && (
                                <td
                                    width={config.lowIndexWidth + config.avgIndexWidth + config.highIndexWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceSpreadsColumns.LOW_INDEX) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceSpreadsColumns.LOW_INDEX}
                                >
                                    <Flex vertical>
                                        <Flex className={styles.titleRow}>
                                            <div style={{ width: config.lowIndexWidth }}>
                                                <Tooltip title={getTableTitleTooltip('Low %')} zIndex={9999999}>
                                                    <span>Low %</span>
                                                </Tooltip>
                                            </div>
                                            <div 
                                                style={{ width: config.avgIndexWidth }}
                                                className={styles.cellPx}
                                            >
                                                <Tooltip title={getTableTitleTooltip('Avg %')} zIndex={9999999}>
                                                    <span>Avg %</span>
                                                </Tooltip>
                                            </div>
                                            <div 
                                                style={{ width: config.highIndexWidth }}
                                                className={styles.cellPx}
                                            >
                                                <Tooltip title={getTableTitleTooltip('High %')} zIndex={9999999}>
                                                    <span>High %</span>
                                                </Tooltip>
                                            </div>
                                        </Flex>
                                        <div className={styles.subtitleRow}>
                                            &nbsp;
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceSpreadsColumns.PRICE_SPREAD) && (
                                <td 
                                    width={config.priceSpreadWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceSpreadsColumns.PRICE_SPREAD) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceSpreadsColumns.PRICE_SPREAD}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Price spread')} zIndex={9999999}>
                                                <span>Price spread</span>
                                            </Tooltip>
                                        </div>
                                        <div className={styles.subtitleRow}>
                                            <span style={{ display: 'inline-block', width: '10px', height: '10px', borderRadius: '10px', marginRight: '6px', background: '#ec7765' }} />Average price
                                        </div>
                                    </Flex>
                                </td>
                            )
                        }
                        <td 
                            className="tableArrowTd bgWhite" 
                            width={config.arrowWidth}
                            style={{ order: 99 }}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
