import { useFetch } from 'libs'
import { argFnAveragePriceChanges, argFnDashboardFetchProductsV2, argFnDashboardHarmonizingSoldOutIds, argFnDashboardProductsInOutIds, DashboardFetchProductsMetric } from 'graphql/nornaapi'
import { useFilterCategory, useFilterCompetitor, useFilterCountry, useFilterCurrency, useFilterGender, useFilterPricePerspective, useFilterSortBy, useFilterTax, useNoSizeFilter } from 'hooks/useFilter'
import { usePageDate } from 'hooks/usePageDate'
import { getDashboardPropertiesPayload, getGroupsPayload, getPriceFilterPayload, getSortByPayload, getTargetGroupPayload } from 'utils/queryPayload'
import { useCompetitorOptions } from 'hooks/useOptions'
import { ALL_VENDORS, MARKET_VENDOR, SELECTED_VENDORS, SUMMARY } from 'consts'
import { groupsConfig } from 'configs/groups'
import { useExcludeProductsComparisonQuery } from 'hooks'
import { OptionsInOutType } from 'types'
import { useLookbook } from 'hooks/useLookbook'
import { dateRangeUtils } from 'norna-uikit'

export const useDashboardFetchProducts = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterSortBy ] = useFilterSortBy()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true, excludeCategoryValue: true })
    const { pageDate: dateRangeValue } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { checked: lookbooks } = useLookbook()

    const { postFn: fetch } = useFetch()

    const fetchData = async (query: {
        metric: DashboardFetchProductsMetric;
        category: string;
        competitor: string;
    }) => {
        if (query.competitor === SUMMARY) {
            query.competitor = SELECTED_VENDORS
        }

        const competitorSellers = [ ...filterCompetitor ]
        if ([ SELECTED_VENDORS, SUMMARY ].includes(query.competitor) && !competitorSellers.map(s => s.vendor).includes(SELECTED_VENDORS)) {
            competitorSellers.push({
                vendor: SELECTED_VENDORS,
                region: filterCountry,
            })
        }
        let groups = getGroupsPayload({ 
            competitorSellers,
        })
        if (query.competitor === MARKET_VENDOR.vendor) {
            groups = {
                [MARKET_VENDOR.vendor]: competitorOptions.filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor)),
            }
        }

        const groupList = [
            MARKET_VENDOR.vendor,
            SELECTED_VENDORS,
            ALL_VENDORS,
            ...groupsConfig.map(item => item.name),
        ]
        if (!groupList.includes(query.competitor)) {
            groups = {}
        }

        const sortBy = getSortByPayload(filterSortBy)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
 
        const payload = argFnDashboardFetchProductsV2({
            region: filterCountry,
            currency: filterCurrency,
            targetGroup,
            date: dateRangeUtils.from(dateRangeValue),
            includeDiscount: filterPricePerspective,
            allCategory: [ ...filterCategory ],
            competitors: query.competitor,
            category: query.category,
            metric: query.metric,
            taxFilter: filterTax,
            groups,
            sortBy,
            properties: getDashboardPropertiesPayload(comparisonQuery),
            priceFilter,
            lookbooks,
            noSizeFilter,
        })
        const result = await fetch(payload.url, payload)
        return result.data 
    }

    return {
        fetchData,
    }
}

/**
 * Price insight 页面 > 点击 Avg price 两个变化列发请求获取产品列表
 */
export const useDashboardAvgPriceChanges = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterSortBy ] = useFilterSortBy()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true, excludeCategoryValue: true })
    const { pageDate: dateRangeValue, comparisonPageDate } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { checked: lookbooks } = useLookbook()

    const { postFn: fetch } = useFetch()

    const fetchData = async (query: {
        category: string;
        competitor: string;
    }) => {
        if (query.competitor === SUMMARY) {
            query.competitor = SELECTED_VENDORS
        }

        const competitorSellers = [ ...filterCompetitor ]
        if ([ SELECTED_VENDORS, SUMMARY ].includes(query.competitor) && !competitorSellers.map(s => s.vendor).includes(SELECTED_VENDORS)) {
            competitorSellers.push({
                vendor: SELECTED_VENDORS,
                region: filterCountry,
            })
        }
        let groups = getGroupsPayload({ competitorSellers })
        if (query.competitor === MARKET_VENDOR.vendor) {
            groups = {
                [MARKET_VENDOR.vendor]: competitorOptions.filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor)),
            }
        }

        const groupList = [
            MARKET_VENDOR.vendor,
            SELECTED_VENDORS,
            ALL_VENDORS,
            ...groupsConfig.map(item => item.name),
        ]
        if (!groupList.includes(query.competitor)) {
            groups = {}
        }

        const sortBy = getSortByPayload(filterSortBy)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const payload = argFnAveragePriceChanges({
            region: filterCountry,
            currency: filterCurrency,
            targetGroup,
            selectedVendor: query.competitor,
            comparisonDate: dateRangeUtils.from(comparisonPageDate),
            date: dateRangeUtils.from(dateRangeValue),
            includeDiscount: filterPricePerspective,
            allCategory: [ ...filterCategory ],
            category: query.category,
            taxFilter: filterTax,
            groups,
            sortBy,
            properties: getDashboardPropertiesPayload(comparisonQuery),
            priceFilter,
            lookbooks,
            noSizeFilter,
        })
        const result = await fetch(payload.url, payload)
        return result.data 
    }

    return {
        fetchData,
    }
}

/**
 * In & Out monitoring 页面点击 In 和 Out 单元格查看产品列表 ids
 */
export const useDashboardFetchInOutProducts = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterSortBy ] = useFilterSortBy()
    const [ filterCategory ] = useFilterCategory()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true, excludeCategoryValue: true })
    const { pageDate: dateRangeValue } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { checked: lookbooks } = useLookbook()

    const { postFn: fetch } = useFetch()

    const fetchData = async (query: {
        category: string;
        competitor: string;
        metric: OptionsInOutType;
    }) => {
        if (query.competitor === SUMMARY) {
            query.competitor = SELECTED_VENDORS
        }

        const competitorSellers = [ ...filterCompetitor ]
        if ([ SELECTED_VENDORS, SUMMARY ].includes(query.competitor) && !competitorSellers.map(s => s.vendor).includes(SELECTED_VENDORS)) {
            competitorSellers.push({
                vendor: SELECTED_VENDORS,
                region: filterCountry,
            })
        }
        let groups = getGroupsPayload({ competitorSellers })
        if (query.competitor === MARKET_VENDOR.vendor) {
            groups = {
                [MARKET_VENDOR.vendor]: competitorOptions.filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor)),
            }
        }

        const groupList = [
            MARKET_VENDOR.vendor,
            SELECTED_VENDORS,
            ALL_VENDORS,
            ...groupsConfig.map(item => item.name),
        ]
        if (!groupList.includes(query.competitor)) {
            groups = {}
        }

        const sortBy = getSortByPayload(filterSortBy)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        const targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })

        const payload = argFnDashboardProductsInOutIds({
            region: filterCountry,
            currency: filterCurrency,
            targetGroup,
            competitor: [ query.competitor ],
            date: dateRangeUtils.from(dateRangeValue),
            includeDiscount: filterPricePerspective,
            allCategory: filterCategory,
            category: query.category,
            metric: query.metric,
            groups,
            sortBy,
            properties: getDashboardPropertiesPayload(comparisonQuery),
            priceFilter,
            lookbooks,
            noSizeFilter,
        })
        const result = await fetch(payload.url, payload)
        return result.data 
    }

    return {
        fetchData,
    }
}

export const useDashboardHarmonizingSoldOutIds = () => {
    const [ filterCountry ] = useFilterCountry()
    const [ filterGender ] = useFilterGender()
    const [ filterCurrency ] = useFilterCurrency()
    const [ filterPricePerspective ] = useFilterPricePerspective()
    const [ filterTax ] = useFilterTax()
    const [ filterSortBy ] = useFilterSortBy()
    const [ filterCompetitor ] = useFilterCompetitor()
    const [ filterCategory ] = useFilterCategory()
    const [ noSizeFilter ] = useNoSizeFilter()
    const comparisonQuery = useExcludeProductsComparisonQuery({ excludeCompetitorValue: true, excludeCategoryValue: true })
    const { pageDate: dateRangeValue } = usePageDate()
    const { competitorOptions } = useCompetitorOptions()
    const { checked: lookbooks } = useLookbook()

    const { postFn: fetch } = useFetch()

    const fetchData = async (query: {
        metric: '<S' | 'S' | 'M' | 'L' | 'XL' | '>XL';
        category: string;
        competitor: string;
    }) => {
        if (query.competitor === SUMMARY) {
            query.competitor = SELECTED_VENDORS
        }

        const competitorSellers = [ ...filterCompetitor ]
        if ([ SELECTED_VENDORS, SUMMARY ].includes(query.competitor) && !competitorSellers.map(s => s.vendor).includes(SELECTED_VENDORS)) {
            competitorSellers.push({
                vendor: SELECTED_VENDORS,
                region: filterCountry,
            })
        }
        let groups = getGroupsPayload({ 
            competitorSellers,
        })
        if (query.competitor === MARKET_VENDOR.vendor) {
            groups = {
                [MARKET_VENDOR.vendor]: competitorOptions.filter(item => ![ SELECTED_VENDORS, MARKET_VENDOR.vendor ].includes(item.vendor)),
            }
        }

        const groupList = [
            MARKET_VENDOR.vendor,
            SELECTED_VENDORS,
            ALL_VENDORS,
            ...groupsConfig.map(item => item.name),
        ]
        if (!groupList.includes(query.competitor)) {
            groups = {}
        }

        const sortBy = getSortByPayload(filterSortBy)
        const priceFilter = getPriceFilterPayload(comparisonQuery?.collection?.query || {})
        let targetGroup = getTargetGroupPayload({ targetGroups: filterGender, returnType: 'array' })
        targetGroup = targetGroup.filter(item => [ 'Men', 'Women' ].includes(item))
 
        const payload = argFnDashboardHarmonizingSoldOutIds({
            region: filterCountry,
            currency: filterCurrency,
            targetGroup,
            date: dateRangeUtils.from(dateRangeValue),
            includeDiscount: filterPricePerspective,
            allCategory: [ ...filterCategory ],
            competitors: query.competitor,
            category: query.category,
            metric: query.metric,
            taxFilter: filterTax,
            groups,
            sortBy,
            properties: getDashboardPropertiesPayload(comparisonQuery),
            priceFilter,
            lookbooks,
            noSizeFilter,
        })
        const result = await fetch(payload.url, payload)
        return result.data 
    }

    return {
        fetchData,
    }
}
