import React, { ReactNode } from 'react'
import classNames from 'classnames'
import * as config from './config'
import styles from './Thead.module.scss'
import { Flex, Tooltip } from 'antd'
import { useFilterCurrency } from 'hooks/useFilter'
import { getTableTitleTooltip } from 'utils'
import { OPTIONS_NAME } from 'consts'
import { priceInsightsColumns } from 'consts/dashboardTableFields'
import { ComparisonTooltipV3 } from 'componentsv2/business/ComparisonTooltip/ComparisonTooltipV3'

const PrimaryText = ({
    children,
}: {
    children: ReactNode;
}) => {
    return (
        <span style={{ color: '#01A699' }}>{children}</span>
    )
}

let dragKey

const Thead = ({
    width,
    isCompetitorView = true,
    fieldList = [],
    sortList = [],
    onDrag,
}: {
    width?: number;
    isCompetitorView?: boolean;
    fieldList?: string[];
    sortList?: string[];
    onDrag?: (dragKey, dropKey) => void;
}) => {
    const [ filterCurrency ] = useFilterCurrency()

    const onDragStart = e => {
        dragKey = e.currentTarget.getAttribute('data-key')
        e.dataTransfer.effectAllowed = 'move'
    }

    const onDragOver = e => {
        e.preventDefault()
    }

    const onDrop = e => {
        const dropKey = e.currentTarget.getAttribute('data-key')
        if (dragKey === dropKey) return
        onDrag?.(dragKey, dropKey)
    }

    return (
        <div className={classNames([ 'price-insights-table-header', styles.wrapper ])}>
            <table style={{ tableLayout: 'fixed', width }} cellPadding={0} cellSpacing={0}>
                <tbody>
                    <tr className={styles.title}>
                        <td 
                            width={config.tabWidth} 
                            align="center"
                            style={{ order: -3 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Vendors' : 'Categories'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        <td 
                            width={config.optionsWidth}
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth, order: -2 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    <Tooltip title={getTableTitleTooltip(OPTIONS_NAME)} zIndex={9999999}>
                                        <span># Options</span>
                                    </Tooltip>
                                </div>
                                <Flex 
                                    justify="space-between" 
                                    style={{ paddingRight: 8 }}
                                    className={styles.subtitleRow}
                                >
                                    <span>#</span> 
                                    <span>+/- <PrimaryText>%</PrimaryText></span>
                                </Flex>
                            </Flex>
                        </td>
                        <td 
                            width={config.categoryWidth} 
                            className={styles.fixedColumn} 
                            style={{ left: config.tabWidth + config.optionsWidth, order: -1 }}
                        >
                            <Flex vertical>
                                <div className={styles.titleRow}>
                                    {isCompetitorView ? 'Category' : 'Vendor'}
                                </div>
                                <div className={styles.subtitleRow} />
                            </Flex>
                        </td>
                        {
                            fieldList.includes(priceInsightsColumns.AVG_PRICE_VALUE) && (
                                <td 
                                    width={config.averagePriceWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.AVG_PRICE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.AVG_PRICE_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Average price')} zIndex={9999999}>
                                                <span>Average price</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.avgPriceValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div 
                                                style={{ width: config.avgPriceChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                            <div 
                                                style={{ width: config.avgPriceChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/-
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceInsightsColumns.INDEX) && (
                                <td 
                                    width={config.indexWrapperWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.INDEX) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.INDEX}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Index')} zIndex={9999999}>
                                                <span>Index</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.indexWidth }}>&nbsp;</div>
                                            <div 
                                                style={{ width: config.indexChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceInsightsColumns.FREQUENT_PRICE_VALUE) && (
                                <td 
                                    width={config.frequentPriceWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.FREQUENT_PRICE_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.FREQUENT_PRICE_VALUE}    
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Frequent price')} zIndex={9999999}>
                                                <span>Frequent price</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.frequentPriceValueWidth }}>
                                                {filterCurrency}
                                            </div>
                                            <div 
                                                style={{ width: config.frequentPriceChangePercentWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        {filterCurrency} +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceInsightsColumns.DIS_EFFECT_VALUE) && (
                                <td 
                                    width={config.disEffectWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.DIS_EFFECT_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.DIS_EFFECT_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Discount Effect')} zIndex={9999999}>
                                                <span>Discount Effect</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.disEffectValueWidth }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div 
                                                style={{ width: config.disEffectChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceInsightsColumns.DIS_DEPTH_VALUE) && (
                                <td 
                                    width={config.disDepthWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.DIS_DEPTH_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.DIS_DEPTH_VALUE}
                                >
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Discount Depth')} zIndex={9999999}>
                                                <span>Discount Depth</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.disDepthValueWidth }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div 
                                                style={{ width: config.disDepthChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        {
                            fieldList.includes(priceInsightsColumns.DIS_WIDTH_VALUE) && (
                                <td 
                                    width={config.disWidthWidth}
                                    className={styles.draggable}
                                    style={{ order: sortList.indexOf(priceInsightsColumns.DIS_WIDTH_VALUE) }}
                                    draggable
                                    onDragStart={onDragStart}
                                    onDragOver={onDragOver}
                                    onDrop={onDrop}
                                    data-key={priceInsightsColumns.DIS_WIDTH_VALUE}
                                >
                                    
                                    <Flex vertical>
                                        <div className={styles.titleRow}>
                                            <Tooltip title={getTableTitleTooltip('Discount Width')} zIndex={9999999}>
                                                <span>Discount Width</span>
                                            </Tooltip>
                                        </div>
                                        <Flex className={styles.subtitleRow}>
                                            <div style={{ width: config.disWidthValueWidth }}>
                                                <PrimaryText>%</PrimaryText>
                                            </div>
                                            <div 
                                                style={{ width: config.disWidthChangeWidth }}
                                                className={styles.cellPx}
                                            >
                                                <ComparisonTooltipV3>
                                                    <div>
                                                        Units +/- <PrimaryText>%</PrimaryText>
                                                    </div>
                                                </ComparisonTooltipV3>
                                            </div>
                                        </Flex>
                                    </Flex>
                                </td>
                            )
                        }
                        <td 
                            className="tableArrowTd bgWhite" 
                            style={{ order: 99 }}
                            width={config.arrowWidth}
                        />
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Thead
