import { Modal } from 'antd'
import React, { useState } from 'react'
import { getCurrencyByRegion } from 'utils'
import { Flex, Input } from 'antd'
import { MultiCategoryDropdown } from 'componentsv2/business/CategoryDropdown'
import { CurrencyDropdown } from 'componentsv2/business/CurrencyDropdown'
import { GenderDropdown } from 'componentsv2/business/GenderDropdown'
import { RegionDropdown } from 'componentsv2/business/RegionDropdown'
import { Button, MultiSelect, Select } from 'druikit'
import { DYNAMIC_DASHBOARD_KPIS, geneCompetitorOptions } from 'features/filters/dynamicDashboardSlice'
import { cloneDeep } from 'lodash'
import { arrayUtils } from 'norna-uikit'
import CompetitorTreeSelect from 'pages/DynamicDashboard/components/QueryForm/CompetitorTreeSelect'
import classNames from 'classnames'
import styles from './OrderForm.module.scss'

export interface OrderData {
    povValue: string;
    competitorValue: string[];
    regionValue: string;
    currencyValue: string;
    genderValue: string[];
    categoryValue: string[];
    kpiValue: string[];
    frequencyValue: string;
    receiveEmailValue: string;
    statusValue: string;
    orderId: string;
}

const EditModal = ({
    loading = false,
    record = {},
    onSave,
    onCancel,
}: {
    loading?: boolean;
    record?: any;
    onSave?: (data: OrderData) => void;
    onCancel?: () => void;
}) => {
    const [ povValue, setPovValue ] = useState(record?.query?.povValue)
    const [ regionValue, setRegionValue ] = useState(record?.query?.regionValue)
    const [ currencyValue, setCurrencyValue ] = useState(record?.query?.currencyValue)
    const [ genderValue, setGenderValue ] = useState<string[]>(record?.query?.genderValue || [])
    const [ competitorValue, setCompetitorValue ] = useState<string[]>(record?.query?.competitorValue || [])
    const [ categoryValue, setCategoryValue ] = useState(record?.query?.categoryValue || [])
    const kpiOptions = DYNAMIC_DASHBOARD_KPIS.map(item => ({ value: item, label: item }))
    const [ kpiValue, setKpiValue ] = useState<string[]>(record?.query?.kpiValue || [])
    const [ frequencyValue, setFrequencyValue ] = useState(record?.frequency)
    const [ receiveEmailValue, setReceiveEmailValue ] = useState(record?.receive_email)
    const [ statusValue, setStatusValue ] = useState(record?.status)

    return (
        <Modal
            width={1000}
            open={true}
            footer={false}
            onCancel={onCancel}
        >
            <Flex wrap="wrap" gap={10} align="end">
                <CompetitorTreeSelect
                    region={regionValue}
                    multiple={false}
                    label="Point of view"
                    placeholder="Point of view"
                    style={{ marginRight: 0 }}
                    value={[ povValue ]}
                    onChange={value => {
                        setPovValue(value[0])

                        const newCompetitorValue = cloneDeep(competitorValue)
                        if (newCompetitorValue.includes(value[0])) {
                            if (newCompetitorValue.length === 1) {
                                const competitorOptions = geneCompetitorOptions(regionValue, false)
                                setCompetitorValue([ competitorOptions.filter(item => item?.key !== value[0])?.[0]?.key ])
                            } else {
                                setCompetitorValue(newCompetitorValue.filter(item => item !== value[0]))
                            }
                        }
                    }}
                />
                <CompetitorTreeSelect
                    region={regionValue}
                    style={{ marginRight: 0 }}
                    excludeVendor={povValue}
                    value={competitorValue}
                    onChange={value => {
                        setCompetitorValue(value)
                    }}
                />
                <RegionDropdown
                    value={regionValue}
                    whetherUpdateCurrency
                    onChange={(value) => {
                        const competitorOptions = geneCompetitorOptions(value, false)

                        let newPovValue = povValue
                        if (!competitorOptions.find(item => item.key === povValue)) {
                            newPovValue = competitorOptions[0]?.key
                        }

                        const oldCompetitorValue = cloneDeep(competitorValue)?.filter(item => item !== povValue)
                        let newCompetitorValue = arrayUtils.intersection(oldCompetitorValue, competitorOptions.map(item => item.key))
                        if (!newCompetitorValue.length) {
                            newCompetitorValue = [ competitorOptions?.filter(item => item.key !== newPovValue)?.[0]?.key ].filter(item => item)
                        }
                        setCompetitorValue([ ...newCompetitorValue ].filter(item => item))

                        // currency
                        const currency = getCurrencyByRegion(value as string)
                        setCurrencyValue(currency)

                        setRegionValue(value)
                    }}      
                />
                <GenderDropdown
                    value={genderValue}
                    onChange={setGenderValue}
                />
                <CurrencyDropdown
                    value={currencyValue}
                    onChange={setCurrencyValue}
                />
                <MultiCategoryDropdown
                    excludeTotal
                    multiple
                    minCount={1}
                    value={categoryValue}
                    onChange={value => {
                        setCategoryValue(value)
                    }}
                />
                <MultiSelect
                    label="KPI"
                    placeholder="KPI"
                    options={kpiOptions}
                    value={kpiValue}
                    onChange={setKpiValue}
                />
                <Select 
                    label="Status"
                    placeholder="Status"
                    options={[ 'active', 'pause', 'inactive' ].map(item => ({ label: item, value: item }))}
                    value={statusValue}
                    onChange={setStatusValue}
                />
                <Select 
                    label="Frequency"
                    placeholder="Frequency"
                    options={[ {
                        label: 'Weekly',
                        value: '* * Mon',
                    }, {
                        label: 'Monthly',
                        value: '1 * *',
                    } ]}
                    value={frequencyValue}
                    onChange={setFrequencyValue}
                />
                <Input
                    className={classNames([ styles.input, styles.emailInput ])}
                    style={{ width: 200 }}
                    placeholder="Receive email"
                    value={receiveEmailValue}
                    onChange={e => setReceiveEmailValue(e.target.value)}
                />
                <Button 
                    type="danger"
                    loading={loading}
                    disabled={Boolean(!frequencyValue || !receiveEmailValue)}
                    onClick={() => {
                        onSave?.({
                            povValue,
                            competitorValue,
                            regionValue,
                            currencyValue,
                            genderValue,
                            categoryValue,
                            kpiValue,
                            frequencyValue,
                            receiveEmailValue,
                            statusValue,
                            orderId: record.order_id,
                        })
                    }}
                >
                    Save
                </Button>
            </Flex>
        </Modal>
    )
}

export default EditModal
