import React, { useState, useRef, CSSProperties, useEffect } from 'react'
import { BaseSelect, BaseSelectRefProps } from 'componentsv2/BaseSelect'
import { handleLeftAndRight } from 'utils'
import { SizedBox } from 'componentsv2/SizedBox'
import { isSuperArray } from 'utils/array'
import { ShouldRender } from 'componentsv2/ShouldRender'
import { Tree, TreeProps, TreeRefProps } from './components/Tree'
import { DropdownHeaderTitle } from './components/DropdownHeaderTitle'
import { DropdownHeaderButton } from './components/DropdownHeaderButton'
import { DropdownFooterButton } from './components/DropdownFooterButton'
import styles from './styles.module.scss'

interface TreeDropdownProps extends TreeProps {
    zoom?: number;
    placeholder?: string;
    showClearAll?: boolean;
    showSelectAll?: boolean;
    onOk?: (value: string[]) => void;
    onItemChange?: (value: string[]) => void;
    label?: string;
    labelStyle?: CSSProperties;
    right?: number | boolean;                           // 下拉框 (select) marginRight 值
    left?: number | boolean;                            // 下拉框 (select) marginLeft 值
    style?: CSSProperties;
    selectStyle?: CSSProperties;
    selectorStyle?: CSSProperties;
    selectorClassName?: string;
    dropdownStyle?: CSSProperties;
    className?: string;
    disabled?: boolean;
    minCount?: number;
    maxCount?: number;
    level1MaxCount?: number;
    multiple?: boolean;
    showOkButton?: boolean;
}

export const TreeDropdown = ({
    zoom = 1,
    options = [],
    value = [],
    onChange,
    onItemChange,
    onOk,
    placeholder,
    showClearAll = true,
    showSelectAll = true,
    selectStyle = {},
    selectorStyle = {},
    selectorClassName,
    dropdownStyle = {},
    label,
    labelStyle = {},
    left,
    right,
    style,
    className,
    disabled = false,

    // 多选才有的属性
    minCount = 0,               // 最少选择个数
    maxCount = 99999,           // 最多选择个数
    level1MaxCount = 9999,      // 一级最多选择个数
    multiple = false,           // 是否可以多选, 单选没有复选框
    showOkButton = true,
}: TreeDropdownProps) => {
    const treeDropdownRef = useRef<BaseSelectRefProps>(null)
    const treeRef = useRef<TreeRefProps>(null)

    /* ************************************** 下拉框样式 ****************************************** */
    left = handleLeftAndRight(left)
    right = handleLeftAndRight(right)
    const selectWrapperStyle: CSSProperties = {}
    if (typeof left !== 'undefined') {
        selectWrapperStyle.marginLeft = left + 'px'
    }
    if (typeof right !== 'undefined') {
        selectWrapperStyle.marginRight = right + 'px'
    }

    /* ************************************** 下拉框显示文本 ****************************************** */
    const [ inputValue, setInputValue ] = useState(getInputValue(value, options))

    useEffect(() => {
        setInputValue(getInputValue(value, options))
    }, [ value, options ])

    return (
        <div style={{ ...selectWrapperStyle, ...style }} className={className}>
            <div className={styles.label} style={{ ...labelStyle }}>{label || placeholder}</div>
            <BaseSelect
                className="cell-mouse-effect-no-display-no-within"
                style={{ minWidth: '120px', borderRadius: '18px', width: '120px', ...selectStyle }}
                selectorStyle={{ ...selectorStyle }}
                selectorClassName={selectorClassName}
                disabled={disabled}
                onIsOpenChanged={() => {
                    const data = treeRef.current?.getValue() || []
                    onOk?.(data)
                }}
                ref={treeDropdownRef}
                placeholder={placeholder}
                inputValue={inputValue}
                dropdownStyle={{ overflow: 'visible' }}
                renderDropdown={(
                    <>
                        <DropdownHeaderTitle
                            title={placeholder}
                            showClose={true}
                            onClick={() => treeDropdownRef.current?.close()}
                        />
                        <ShouldRender shouldRender={multiple}>
                            <DropdownHeaderButton
                                onClearAll={() => {
                                    const data = treeRef.current?.clearAll() || []
                                    onItemChange?.(data)
                                }}
                                onSelectAll={() => {
                                    const data = treeRef.current?.selectAll() || []
                                    onItemChange?.(data)
                                }}
                                showClearAll={showClearAll}
                                showSelectAll={showSelectAll}
                            />
                        </ShouldRender>
                        <Tree
                            zoom={zoom}
                            ref={treeRef}
                            style={{ marginTop: multiple ? '0px' : '10px', ...dropdownStyle }}
                            minCount={minCount}
                            maxCount={maxCount}
                            level1MaxCount={level1MaxCount}
                            options={options}
                            multiple={multiple}
                            value={value}
                            onChange={value => {
                                // 单选, 选中一个关闭下拉框
                                if (!multiple) {
                                    treeDropdownRef.current?.close()
                                }
                                setInputValue(getInputValue(value, options))
                                if (typeof onItemChange === 'function') {
                                    onItemChange?.(value)
                                } else {
                                    onChange?.(value)
                                }
                            }}
                            ancestorBordered={false}
                        />
                        <SizedBox type="block" height={10} />
                        <ShouldRender shouldRender={multiple && showOkButton}>
                            <DropdownFooterButton
                                onOk={() => {
                                    treeDropdownRef.current?.close()
                                    const data = treeRef.current?.getValue() || []
                                    onOk?.(data)
                                }}
                            />
                        </ShouldRender>
                    </>
                )}
            />
        </div>
    )
}

function getInputValue(data: string[] = [], options): string {
    let newData: string[] = []
    options.forEach(l1 => {
        const isParent = Array.isArray(l1?.children) && l1?.children?.length
        if (!isParent && data.includes(l1.key)) {
            newData.push(l1.description)
        }
        const childrenValue = l1?.children?.map(l2 => l2.key) || []
        if (isParent && isSuperArray(data, childrenValue)) {
            newData = newData.filter(d => !childrenValue.includes(d))
            newData.push(l1.description)
        }
        if (isParent && !isSuperArray(data, childrenValue)) {
            l1.children.forEach(l2 => {
                if (data.includes(l2.key)) {
                    newData.push(l2.description)
                }
            })
        }
    })

    if (data.length > 1) {
        const desc = newData[0]
        return `${desc.length > 7 ? desc.slice(0, 6) + '...' : desc}, +${data.length - 1}`
    } 
    if (data.length === 1) {
        return newData[0]
    } 
    return ''
}
