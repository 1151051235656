import useDeepCompareEffect from 'use-deep-compare-effect'
import { SETTING_CATEGORY_PREFIX, SETTING_KPI_PREFIX, clearExportPdfLoadingObj, getApiData, getApiLoading, getCategoryValue, getCompetitorOptions, getCompetitorValue, getExportPdfLoadingObj, getExportPdfSource, getExportPdfVisible, getFetchPayload, getModalExportDisabled, getModalRequests, getModalVisible, getPovValue, getRequests, getSettingCategoryImages, getSettingCategoryOptions, getSettingCategoryValue, getSettingKpiValue, getSettingOptions, getSettingValue, initSettingValue, updateApiData, updateApiLoading, updateCategoryImages, updateCategoryValue, updateCompetitorOptions, updateCompetitorValue, updateExportPdfLoadingObj, updateExportPdfSource, updateExportPdfVisible, updateFetchPayload, updateModalExportDisabled, updateModalRequests, updateModalVisible, updatePovValue, updateRequests, updateSettingKpiValue, updateSettingValue } from 'features/filters/dynamicDashboardSlice'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFetchMetaImages, useModifySettings } from './useApi'
import { RequestsType } from 'features/filters/dynamicDashboardSlice.interface'

export const useCompetitorOptions = () => {
    const competitorOptions = useSelector(getCompetitorOptions)

    const dispatch = useDispatch()
    const setCompetitorOptions = (value: string[]) => {
        dispatch(updateCompetitorOptions(value))
    }

    return [
        competitorOptions,
        setCompetitorOptions,
    ]
}

export const useCompetitorValue = () => {
    const competitorValue = useSelector(getCompetitorValue)

    const dispatch = useDispatch()
    const setCompetitorValue = (value: string[]) => {
        dispatch(updateCompetitorValue(value))
    }

    return [
        competitorValue,
        setCompetitorValue,
    ]
}

export const usePovValue = () => {
    const povValue = useSelector(getPovValue)

    const dispatch = useDispatch()
    const setPovValue = (value: string[]) => {
        dispatch(updatePovValue(value))
    }

    return [
        povValue,
        setPovValue,
    ]
}

export const useCategoryValue = (): [string[], (value: string[]) => void] => {
    const categoryValue = useSelector(getCategoryValue)

    const dispatch = useDispatch()
    const setCategoryValue = (value: string[]) => {
        dispatch(updateCategoryValue(value))
    }

    return [
        categoryValue,
        setCategoryValue,
    ]
}

export const useSettingOptions = () => {
    const settingOptions = useSelector(getSettingOptions)

    return [
        settingOptions,
    ]
}

export const useSettingValue = () => {
    const settingValue = useSelector(getSettingValue)

    const dispatch = useDispatch()
    const setSettingValue = (value: string) => {
        dispatch(updateSettingValue(value))
    }

    return [
        settingValue,
        setSettingValue,
    ]
}

export const useSettingCategoryValue = () => {
    const settingCategoryVaue = useSelector(getSettingCategoryValue)

    return [
        settingCategoryVaue,
    ]
}

export const useSettingCategoryOptions = () => {
    const settingCategoryOptions = useSelector(getSettingCategoryOptions)

    return [
        settingCategoryOptions,
    ]
}

export const useSettingCategoryImages = () => {
    const settingCategoryImages = useSelector(getSettingCategoryImages)

    const dispatch = useDispatch()
    const setSettingCategoryImages = (value: Record<string, string>) => {
        dispatch(updateCategoryImages(value))
    }

    return [
        settingCategoryImages,
        setSettingCategoryImages,
    ]
}

export const useSettingKpiValue = (): [ string[], (value: string[]) => void ] => {
    const settingKpiVaue = useSelector(getSettingKpiValue)

    const dispatch = useDispatch()
    const setSettingKpiValue = (value: string[]) => {
        dispatch(updateSettingKpiValue(value))
    }

    return [
        settingKpiVaue,
        setSettingKpiValue,
    ]
}

export const useModalExportDisabled = () => {
    const modalExportDisabled = useSelector(getModalExportDisabled)

    const dispatch = useDispatch()
    const setModalExportDisabled = (value: boolean) => {
        dispatch(updateModalExportDisabled(value))
    }

    return [
        modalExportDisabled,
        setModalExportDisabled,
    ]
}

export const useModalRequests = () => {
    const modalRequests = useSelector(getModalRequests)

    const dispatch = useDispatch()
    const setModalRequests = (value: any = {}) => {
        dispatch(updateModalRequests(value))
    }

    return [
        modalRequests,
        setModalRequests,
    ]
}

export const useModalVisible = () => {
    const modalVisible = useSelector(getModalVisible)

    const dispatch = useDispatch()
    const setModalVisible = (value: any = {}) => {
        dispatch(updateModalVisible(value))
    }

    return [
        modalVisible,
        setModalVisible,
    ]
}

export const useApiData = () => {
    const apiData = useSelector(getApiData)

    const dispatch = useDispatch()
    const setApiData = (value: any) => {
        dispatch(updateApiData(value))
    }

    return [
        apiData,
        setApiData,
    ]
}

export const useApiLoading = () => {
    const apiLoading = useSelector(getApiLoading)

    const dispatch = useDispatch()
    const setApiLoading = (value: any) => {
        dispatch(updateApiLoading(value))
    }

    return [
        apiLoading,
        setApiLoading,
    ]
}

export const useFetchPayload = () => {
    const fetchPayload = useSelector(getFetchPayload)

    const dispatch = useDispatch()
    const setFetchPayload = (value: any) => {
        dispatch(updateFetchPayload(value))
    }

    return [
        fetchPayload,
        setFetchPayload,
    ]
}

export const useDynamicDashboardRequests = (): [ RequestsType, (value: RequestsType) => void ] => {
    const requests = useSelector(getRequests)

    const dispatch = useDispatch()
    const setRequests = (value: any) => {
        dispatch(updateRequests(value))
    }

    return [
        requests,
        setRequests,
    ]
}

export const useExportPdfVisible = (): [ boolean, (value: boolean) => void ] => {
    const exportPdfVisible = useSelector(getExportPdfVisible)

    const dispatch = useDispatch()
    const setExportPdfVisible = (value: any) => {
        dispatch(updateExportPdfVisible(value))
    }

    return [
        exportPdfVisible,
        setExportPdfVisible,
    ]
}

export const useExportPdfLoadingObj = (): [ { [key: string]: boolean }, (value: { [key: string]: boolean }) => void, () => void ] => {
    const exportPdfLoadingObj = useSelector(getExportPdfLoadingObj)

    const dispatch = useDispatch()
    const setExportPdfLoadingObj = (value: any) => {
        dispatch(updateExportPdfLoadingObj(value))
    }

    const setExportPdfLoadingObjEmpty = () => {
        dispatch(clearExportPdfLoadingObj())
    }

    return [
        exportPdfLoadingObj,
        setExportPdfLoadingObj,
        setExportPdfLoadingObjEmpty,
    ]
}

export const useExportPdfSource = (): [ 'button' | 'script', (value: 'button' | 'script') => void ] => {
    const exportPdfSource = useSelector(getExportPdfSource)

    const dispatch = useDispatch()
    const setExportPdfSource = (value: 'button' | 'script') => {
        dispatch(updateExportPdfSource(value))
    }

    return [
        exportPdfSource,
        setExportPdfSource,
    ]
}

export const useInit = () => {
    /* **************************** 初始化 settingValue *********************************** */
    const [ settingValue, setSettingValue ] = useSettingValue()

    useEffect(() => {
        if (Array.isArray(settingValue) && settingValue.length) return
        // 设置初始化值
        const value = initSettingValue()
        setSettingValue(value)
        // 发请求保存数据
    }, [])

    /* **************************** 监听 settingValue 变化, 存库 *********************************** */
    const { modifySettings } = useModifySettings()
    const [ competitorValue ] = useCompetitorValue()
    const [ categoryValue ] = useCategoryValue()

    useDeepCompareEffect(() => {
        if (!settingValue?.length || !competitorValue?.length || !categoryValue?.length) return
        const categories = settingValue.filter(s => s.startsWith(SETTING_CATEGORY_PREFIX))
        const kpis = settingValue.filter(s => s.startsWith(SETTING_KPI_PREFIX))
        const payload = {
            categories,
            KPIs: kpis,
            competitors: competitorValue,
            selected_categories: categoryValue,
        }
        modifySettings(payload)
    }, [ settingValue, categoryValue, competitorValue, [] ])

    /* ************************* 初始化 categoryImages ****************************** */
    const { fetchMetaImages } = useFetchMetaImages()
    const [ , setSettingCategoryImages ] = useSettingCategoryImages()

    useEffect(() => {
        fetchMetaImages().then(res => {
            setSettingCategoryImages(res)
        })
    }, [])
}
