/**
 * 各种权限控制
 */

import { LICENSE_BESTSELLER, LICENSE_CALVINKLEIN, LICENSE_JUST_BRANDS, LICENSE_TOMMYHILFIGER } from 'consts'
import { storage } from './storage'

/**
 * GlobalDatePicker
 * Last quarter     禁选
 * Year on Year     禁选
 */
export function isDisabledDatePreset() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
        LICENSE_JUST_BRANDS,
        LICENSE_BESTSELLER,
    ].includes(storage.getCustomerVendor())
}

/**
 * 是否显示 Sold out analytics 页面
 */
export function isShowSoldOutAnalyticsPage() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
    ].includes(storage.getCustomerVendor())
}

/**
 * 是否显示 Platform analytics 页面
 */
export function isShowPlatformAnalyticsPage() {
    return [
        LICENSE_BESTSELLER,
    ].includes(storage.getCustomerVendor())
}

/**
 * 这些 license 下会判断日期，可能会显示 warn data dialog
 * 
 * 当前选择的 vendors 只要有一个 vendor 的 launch_date 比如所选日期范围的开始日期要大，
 * 会弹出警告弹窗 "Data is not available during the selected time"
 */
export function isShowWarnDataDialog() {
    return [
        LICENSE_TOMMYHILFIGER,
        LICENSE_CALVINKLEIN,
        LICENSE_BESTSELLER,
    ].includes(storage.getCustomerVendor())
}
