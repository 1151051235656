import React from 'react'
import { Dialog } from '@norna/norna-uikit'
import styles from './PriceSpreadDialog.module.scss'
import { CloseIcon } from 'assets/icons'
import { Matrics } from '../PriceRange/PriceRange'

const priceSpreadDialogId = 'priceSpreadDialog'

const PriceSpreadDialog = ({
    record,
    onClose,
}: {
    record?: any;
    onClose?: () => void;
}) => {
    const onCloseDialog = () => {
        hidePriceSpreadDialog()
        onClose?.()
    }

    return (
        <Dialog
            id={priceSpreadDialogId} 
            className={styles.priceSpreadDialog}
            left="calc(50% - 400px)"
            top="calc(50% - 50px)"
        >
            <div 
                className={styles.wrapper}
                onMouseDown={e => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                onMouseDownCapture={e => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
            >
                <div className={styles.header}>
                    <div>
                        Price spread
                    </div>
                    <CloseIcon onClick={onCloseDialog} />
                </div>
                <div className={styles.body}>
                    <Matrics
                        max={record?.max?.value || 0}
                        min={record?.min?.value || 0}
                        avg={record?.avg?.value || 0}
                        len={record?.maxOfMaxField || 0}
                    />
                </div>
            </div>
        </Dialog>
    )
}

export default PriceSpreadDialog

export const showPriceSpreadDialog = () => {
    setTimeout(() => {
        const dialog = document.getElementById(priceSpreadDialogId) as HTMLDialogElement
        dialog?.showModal()
    }, 300)
}

export const hidePriceSpreadDialog = () => {
    const dialog = document.getElementById(priceSpreadDialogId) as HTMLDialogElement
    dialog?.close()
}
