import React, { CSSProperties, FC, memo, useContext, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { format } from 'date-fns'
import { DateFormats } from 'consts'
import { SnackInfo } from 'componentsv2/SnackInfo'
import { BaseSelect, BaseSelectRefProps } from 'componentsv2/BaseSelect'
import { OptionProps, DropdownHeader, DropdownOptionItem } from 'componentsv2/form-elements/Dropdown'
import { CaretIcon } from 'assets/icons'
import { NavigationBarContext } from 'pages/Home/NavigationBarContext'
import useDisableLazyLoad from 'hooks/useDisableLazyLoad'
import { handleLeftAndRight } from 'utils'
import { loadingBar } from 'hooks'
import { exportPdf, exportImg, onAllImgLoaded, exportImgUtil } from './utils'
import { ExportDropdownProps } from './types'
import styles from './styles.module.scss'

/**
 * ## ExportDropdown
 * 
 * 导出下拉框
 * 
 * ## 使用示例
 * 
 * ### 导出 csv
 * 
 * ```
 * <ExportDropdown 
 *      showCSV
 *      onExportCSV={() => {}}
 * />
 * ```
 * 
 * ### 导出 jpg
 * 
 * ```
 * <ExportDropdown 
 *      showJPG
 *      fileName="example_filename"
 * />
 * ```
 * 
 * ### autoGeneDate
 * 
 * autoGeneDate 默认值为 true，会自动为 fileName 拼接上导出时的日期时间，如：
 * fileName="test"
 * 最终导出的 jpg 文件名为 "test_2021-11-25.jpg"
 * 最终导出的 pdf 文件名为 "test_2021-11-25.pdf"
 * 
 * ```
 * <ExportDropdown 
 *      showJPG
 *      showPDF
 *      fileName="test"
 * />
 * ```
 * 
 * 如果把 autoGeneDate 设置为 false
 * fileName="test"
 * 最终导出的 jpg 文件名为 "test.jpg"
 * 最终导出的 pdf 文件名为 "test.pdf
 * 
 * ```
 * <ExportDropdown
 *      showJPG
 *      showPDF
 *      fileName="test"
 *      autoGeneDate={false}
 * />
 * ```
 * 
 * ### selector
 * 
 * 导出图片(showJPG)、导出PDF(showPDF)、生成图片并复制到剪贴板(showCLIPBOARD)
 * 
 * 这些导出只要传参一个 dom 选择器即可，将指定 dom 内容导出为文件，selector 参数接收 dom 选择器。
 * 
 * 类选择器
 * 
 * ```
 * <ExportDropdown 
 *      selector=".export"
 * />
 * ```
 * 
 * ID 选择器
 * 
 * ```
 * <ExportDropdown
 *      selector="#export"
 * />
 * ```
 */
export const ExportDropdown: FC<ExportDropdownProps> = memo(({
    showCSV = false,
    showPDF = false,
    showJPG = true,
    showExcel = true,
    showCLIPBOARD = true,
    showEmailReport = false,
    left,
    right,
    label = '',
    enableForceLoadImg = false,
    adjustElementFn,
    calcJpgWidthFn,
    calcJpgHeightFn,
    beforeFn,
    imgsSeletor,
    afterFn,
    scale,
    disabled = false,
    loading = false,
    onExportCSV,                        // showCSV 时自定义导出函数
    onExportExcel,                      // showExcel 时自定义导出函数
    onExportImg,
    onExportPdf,
    onExportEmailReport,
    pdfAlias,
    fileName,                           // showJPG、showPDF 时需要定义导出文件名字，不传的话默认值是 undefined
    autoGeneDate = false,
    selector = '#export-wrapper',
    excludeSelectors = [],
    scrollHeight,
    size,
}) => {
    const { setBeforeExportLoading, startExportLoading, setStartExportLoading } = useContext(NavigationBarContext)
    const { disableLazyLoad } = useDisableLazyLoad()

    // 生成下拉框选项
    const options: OptionProps[] = []
    if (showExcel) {
        options.push({ key: 'Excel', description: 'Excel' })
    }
    if (showCSV) {
        options.push({ key: 'CSV', description: 'CSV' })
    }
    if (showPDF) {
        options.push({ key: 'PDF', description: pdfAlias || 'PDF' })
    }
    if (showEmailReport) {
        options.push({ key: 'EmailReport', description: 'Email Report' })
    }
    if (showJPG) {
        options.push({ key: 'JPG', description: 'JPG' })
    }
    if (showCLIPBOARD) {
        options.push({ key: 'CLIPBOARD', description: 'To clipboard' })
    }

    // 通过 selectRef.current 可以调用 BaseSelectRefProps 对外暴露的方法
    // selectRef.current.close() 手动关闭下拉框
    const selectRef = useRef<BaseSelectRefProps>(null)

    // 导出成功后右下角会弹出对话框显示导出结果是成功还是失败
    const snackRef = useRef<any>({})

    // 下拉框选项点击时触发事件
    const onSelect = async key => {
        switch (key) {
            case 'Excel':
                try {
                    loadingBar.restart()
                    await onExportExcel?.()
                    loadingBar.done()
                } catch (e) {
                    loadingBar.done()
                }
                break
            case 'CSV':
                onExportCSV?.()
                break
            case 'PDF':
                if (typeof onExportPdf === 'function') {
                    onExportPdf?.()
                    break
                }
                fileName = autoGeneDate ? fileName + '_' + format(new Date(), DateFormats.DEFAULT) : fileName
                loadingBar.restart()
                exportPdf({
                    selector,
                    excludeSelectors,
                    fileName: fileName as string,
                    onSuccess: () => {
                        snackRef.current?.open?.('Exporting pdf successfully')
                        loadingBar.done()
                    },
                    onFail: () => {
                        snackRef.current?.open?.('Failed to export pdf')
                        loadingBar.done()
                    },
                })
                break
            case 'EmailReport':
                onExportEmailReport?.()
                break
            case 'JPG':
                if (typeof onExportImg === 'function') {
                    onExportImg?.()
                    break
                }
                fileName = autoGeneDate ? fileName + '_' + format(new Date(), DateFormats.DEFAULT) : fileName
                if (enableForceLoadImg) {
                    const element: any = document.querySelector(selector)
                    if (!element) return
                    try {
                        loadingBar.start()
                        disableLazyLoad(true)
                        beforeFn?.()
                        await onAllImgLoaded(element, imgsSeletor)
                    } finally {
                        exportImg({
                            selector,
                            excludeSelectors,
                            fileName: fileName as string,
                            adjustElementFn,
                            calcJpgWidthFn,
                            calcJpgHeightFn,
                            scrollHeight,
                            scale,
                            onSuccess: () => {
                                snackRef.current?.open?.('Exporting image successfully')
                                enableForceLoadImg && disableLazyLoad(false)
                                afterFn?.()
                                loadingBar.done()
                            },
                            onFail: () => {
                                snackRef.current?.open?.('Failed to export image')
                                enableForceLoadImg && disableLazyLoad(false)
                                afterFn?.()
                                loadingBar.done()
                            },
                        })
                    }

                } else {
                    beforeFn?.()
                    loadingBar.start()
                    exportImg({
                        selector,
                        excludeSelectors,
                        fileName: fileName as string,
                        adjustElementFn,
                        calcJpgWidthFn,
                        calcJpgHeightFn,
                        scrollHeight,
                        scale,
                        onSuccess: () => {
                            snackRef.current?.open?.('Exporting image successfully')
                            afterFn?.()
                            loadingBar.done()
                        },
                        onFail: () => {
                            snackRef.current?.open?.('Failed to export image')
                            afterFn?.()
                            loadingBar.done()
                        },
                    })
                }

                break
            case 'CLIPBOARD':
                if (enableForceLoadImg) {
                    const element: any = document.querySelector(selector)
                    if (!element) return
                    disableLazyLoad(true)
                    beforeFn?.()
                    try {
                        await onAllImgLoaded(element, imgsSeletor)
                    } finally {
                        exportImgUtil({
                            selector,
                            excludeSelectors,
                            adjustElementFn,
                            calcJpgWidthFn,
                            calcJpgHeightFn,
                            scale,
                            scrollHeight,
                            snackRef,
                            afterFn,
                            disableLazyLoad,
                        })
                    }
                } else {
                    beforeFn?.()
                    exportImgUtil({
                        selector,
                        excludeSelectors,
                        adjustElementFn,
                        calcJpgWidthFn,
                        calcJpgHeightFn,
                        scale,
                        scrollHeight,
                        snackRef,
                        afterFn,
                        disableLazyLoad,
                    })
                }

                break
            default:
        }
    }

    const keyRef = useRef<string>('')

    useEffect(() => {
        if (startExportLoading && keyRef.current) {
            setTimeout(async () => {
                setBeforeExportLoading(false)
                await onSelect(keyRef.current)
                setStartExportLoading(false)
            }, 1000)
        }
        // eslint-disable-next-line
    }, [startExportLoading, keyRef.current])

    const [ isOpen, setIsOpen ] = useState(false)

    const renderSelector = (
        <div 
            className={classnames({
                [styles.selector]: true,
                [styles.selectorSmall]: size === 'small',
            })} 
            style={{ cursor: disabled ? 'not-allowed' : 'auto' }}
        >
            <div className={styles.selectorSelection}>Export</div>
            <div className={classnames(styles.selectorArrow, isOpen && styles.selectorArrowFlipped)}>
                <CaretIcon color="666" />
            </div>
        </div>
    )

    const renderDropdown = (
        <>
            <DropdownHeader title="Export format" />
            <div style={{ flex: 1, overflow: 'auto', padding: '0 0px', margin: '10px 0' }}>
                {
                    options.map((item: OptionProps) =>
                        <DropdownOptionItem
                            customClass="cell-mouse-effect-no-display-no-within-padding-lr-15"
                            key={item.key}
                            item={item}
                            active={false}
                            showCheckbox={false}
                            onSelect={item => {
                                selectRef.current?.close()
                                const lineChart = document.querySelector(selector)?.querySelector('div[class*="nornaLineChart"]')
                                if (!lineChart) {
                                    setTimeout(() => {
                                        onSelect?.(item.key)
                                    }, 500)
                                } else {
                                    setTimeout(() => {
                                        keyRef.current = item.key
                                        setBeforeExportLoading(true)
                                    }, 500)
                                }
                            }}
                        />,
                    )
                }
            </div>
        </>
    )

    const defaultStyle: CSSProperties = {}

    left = handleLeftAndRight(left)
    right = handleLeftAndRight(right)

    if (typeof left !== 'undefined') {
        defaultStyle.marginLeft = left + 'px'
    }

    if (typeof right !== 'undefined') {
        defaultStyle.marginRight = right + 'px'
    }

    return (
        <div style={{ ...defaultStyle }}>
            {label ? <div className={styles.label}>{label}</div> : null}
            <BaseSelect
                disabled={disabled}
                ref={selectRef}
                className="cell-mouse-effect-no-display-no-within"
                // 
                style={{ minWidth: '120px', borderRadius: '18px', height: size === 'small' ? 28 : 36 }}
                renderSelector={renderSelector}
                renderDropdown={renderDropdown}
                dropdownStyle={{ width: '140px' }}
                onIsOpenChanged={value => setIsOpen(value)}
            />
            <SnackInfo ref={snackRef} />
        </div>
    )
})

ExportDropdown.displayName = 'ExportDropdown'
