/* ******************** Price insights *********************** */
export const priceInsightsColumns = {
    OPTIONS: 'options',
    CATEGORY_KEY: 'categoryKey',
    AVG_PRICE_VALUE: 'avgPriceValue',
    INDEX: 'index',
    FREQUENT_PRICE_VALUE: 'frequentPriceValue',
    DIS_EFFECT_VALUE: 'disEffectValue',
    DIS_DEPTH_VALUE: 'disDepthValue',
    DIS_WIDTH_VALUE: 'disWidthValue',
}

export const priceInsightsOptions = [
    {
        key: priceInsightsColumns.AVG_PRICE_VALUE,
        description: 'Average price',
    },
    {
        key: priceInsightsColumns.INDEX,
        description: 'Index',
    },
    {
        key: priceInsightsColumns.FREQUENT_PRICE_VALUE,
        description: 'Frequent price',
    },
    {
        key: priceInsightsColumns.DIS_EFFECT_VALUE,
        description: 'Discount Effect',
    },
    {
        key: priceInsightsColumns.DIS_DEPTH_VALUE,
        description: 'Discount Depth',
    },
    {
        key: priceInsightsColumns.DIS_WIDTH_VALUE,
        description: 'Discount Width',
    },
]

export const priceInsightsFields = priceInsightsOptions.map(item => item.key)

/* ******************** Assortment insights *********************** */
export const assortmentInsightsColumns = {
    OPTIONS: 'options',
    CATEGORY_KEY: 'categoryKey',
    CATEGORY_SPLIT_VALUE: 'categorySplitValue',
    SIZE_OF_LINE_VALUE: 'sizeOfLineValue',
    SIZE_RATIO_VALUE: 'sizeRatioValue',
    OPTION_RATIO_VALUE: 'optionRatioValue',
    SOLD_OUT_VALUE: 'soldOutValue',
    UNIQUE_OPTIONS_VALUE: 'uniqueOptions',
    ROTATION_RATIO_VALUE: 'optionRotationRatio',
    LIFE_SPAN_VALUE: 'lifeSpan',
    ECO_LABEL_VALUE: 'ecoLabelValue',
    SOLID_VALUE: 'solidValue',
}

export const assortmentInsightsOptions = [
    {
        key: assortmentInsightsColumns.CATEGORY_SPLIT_VALUE,
        description: 'Category split',
    },
    {
        key: assortmentInsightsColumns.SIZE_OF_LINE_VALUE,
        description: 'Size of line',
    },
    {
        key: assortmentInsightsColumns.SIZE_RATIO_VALUE,
        description: 'Size ratio',
    },
    {
        key: assortmentInsightsColumns.OPTION_RATIO_VALUE,
        description: 'Option ratio',
    },
    {
        key: assortmentInsightsColumns.SOLD_OUT_VALUE,
        description: 'Sold out',
    },
    {
        key: assortmentInsightsColumns.UNIQUE_OPTIONS_VALUE,
        description: '# Unique options',
    },
    {
        key: assortmentInsightsColumns.ROTATION_RATIO_VALUE,
        description: 'Rotation ratio',
    },
    {
        key: assortmentInsightsColumns.LIFE_SPAN_VALUE,
        description: 'Lifespan, days',
    },
    {
        key: assortmentInsightsColumns.ECO_LABEL_VALUE,
        description: 'Eco label',
    },
    {
        key: assortmentInsightsColumns.SOLID_VALUE,
        description: 'Solid',
    },
]

export const assortmentInsightsFields = assortmentInsightsOptions.map(item => item.key)

/* ******************** Price spreads *********************** */
export const priceSpreadsColumns = {
    OPTIONS: 'options',
    CATEGORY_KEY: 'categoryKey',
    LOW_PRICE_VALUE: 'lowPriceValue',
    AVG_PRICE_VALUE: 'avgPriceValue',
    HIGH_PRICE_VALUE: 'highPriceValue',
    LOW_INDEX: 'lowIndex',
    PRICE_SPREAD: 'priceSpread',
}

export const priceSpreadsOptions = [
    {
        key: priceSpreadsColumns.LOW_PRICE_VALUE,
        description: 'Low price',
    },
    {
        key: priceSpreadsColumns.AVG_PRICE_VALUE,
        description: 'Average price',
    },
    {
        key: priceSpreadsColumns.HIGH_PRICE_VALUE,
        description: 'High price',
    },
    {
        key: priceSpreadsColumns.LOW_INDEX,
        description: 'Index',
    },
    {
        key: priceSpreadsColumns.PRICE_SPREAD,
        description: 'Price spread',
    },
]

export const priceSpreadsFields = priceSpreadsOptions.map(item => item.key)

/* ******************** In out monitoring *********************** */
export const inOutMonitoringColumns = {
    OPTIONS: 'options',
    CATEGORY_KEY: 'categoryKey',
    IN_VALUE: 'inValue',
    AVG_PRICE_VALUE: 'avgPriceValue',
    OUT_VALUE: 'outValue',
    ROTATION_VALUE: 'rotationValue',
    PRICE_SPREAD: 'priceSpread',
}

export const inOutMonitoringOptions = [
    {
        key: inOutMonitoringColumns.IN_VALUE,
        description: '# In',
    },
    {
        key: inOutMonitoringColumns.AVG_PRICE_VALUE,
        description: 'Average price In',
    },
    {
        key: inOutMonitoringColumns.OUT_VALUE,
        description: '# Out',
    },
    {
        key: inOutMonitoringColumns.ROTATION_VALUE,
        description: 'Rotation',
    },
    {
        key: inOutMonitoringColumns.PRICE_SPREAD,
        description: 'Price spread',
    },
]

export const inOutMonitoringFields = inOutMonitoringOptions.map(item => item.key)

/* ******************** Most popular launch days *********************** */
export const mostPopularLaunchDaysColumns = {
    OPTIONS: 'options',
    CATEGORY_KEY: 'categoryKey',
    MONDAY: 'monday',
    TUESDAY: 'tuesday',
    WEDNESDAY: 'wednesday',
    THURSDAY: 'thursday',
    FRIDAY: 'friday',
    SATURDAY: 'saturday',
    SUNDAY: 'sunday',
}

export const mostPopularLaunchDaysOptions = [
    {
        key: mostPopularLaunchDaysColumns.MONDAY,
        description: 'Monday',
    },
    {
        key: mostPopularLaunchDaysColumns.TUESDAY,
        description: 'Tuesday',
    },
    {
        key: mostPopularLaunchDaysColumns.WEDNESDAY,
        description: 'Wednesday',
    },
    {
        key: mostPopularLaunchDaysColumns.THURSDAY,
        description: 'Thursday',
    },
    {
        key: mostPopularLaunchDaysColumns.FRIDAY,
        description: 'Friday',
    },
    {
        key: mostPopularLaunchDaysColumns.SATURDAY,
        description: 'Saturday',
    },
    {
        key: mostPopularLaunchDaysColumns.SUNDAY,
        description: 'Sunday',
    },
]

export const mostPopularLaunchDaysFields = mostPopularLaunchDaysOptions.map(item => item.key)
