import React, { useState, useEffect, ReactNode, memo, useMemo, CSSProperties } from 'react'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import classnames from 'classnames'
import { CaretUpOutlined, CaretDownOutlined } from 'druikit'
import { ShouldRender } from 'componentsv2/ShouldRender'
import classNames from 'classnames'
import { StyledTableBodyCell } from '../StyledTableBodyCell'
import styles from './styles.module.scss'
import { RowGroupType, RowType } from './Row.type'
import { calcHandler } from './utils'
import { ColumnsType } from '../types'

type VoidCallback = () => void;

function getCellBackgroundColorByLevel(level: number = 999) {
  const bgs = [
    'rgb(249, 251, 251)',  // level-0 bg
    'white',  // level-1 bg
  ]
  return bgs[level] || 'rgb(237, 237, 237)'
}

const BodyCell = memo(({
  columns,
  rowData = {},
}: {
  columns: ColumnsType[];
  rowData: any;
}) => {
  return (
    <>
      {
        columns.map((column, columnIndex) => {
          // 是否隐藏列
          if (column.hidden) {
            return null
          }
          const text = column.dataIndex ? rowData[column.dataIndex] || '' : ''
          let bodyEl: ReactNode = (<>{text}</>)
          if (typeof column?.render === 'function') {
            bodyEl = (
              <>
                {column.render(text, rowData, columnIndex)}
              </>
            )
          }

          const sty: CSSProperties = {
            background: getCellBackgroundColorByLevel(rowData?.level),
            height: 43,
            width: column?.width,
          }
      
          let tdCls = [ column.className ]
          if (typeof column?.left === 'number') {
            tdCls.push('table-td-fixed')
            sty.position = 'sticky'
            sty.left = column.left
            sty.zIndex = 1
          }

          return (
            <StyledTableBodyCell
              key={column.dataIndex}
              style={{ ...sty, ...column?.style }}
              className={classNames(tdCls)}
            >
              {bodyEl}
            </StyledTableBodyCell>
          )
        })
      }
    </>
  )
})

const ArrowCell = ({
  style,
  className,
  rowActive,
  width = 40,
  onClick,
}: {
  style?: CSSProperties;
  className?: string;
  rowActive?: boolean;
  width?: number;
  onClick?: VoidCallback;
}) => {
  const buttonWrapperSty: CSSProperties = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }

  const buttonSty: CSSProperties = {
    width: 25,
    height: 25,
  }

  return (
    <StyledTableBodyCell
      style={{ ...style }}
      className={classNames([ className, styles.arrowCell, 'tableArrowTd' ])}
      width={width}
    >
      <div style={buttonWrapperSty}>
        <IconButton
          aria-label="expand row"
          size="small"
          style={{ ...buttonSty }}
          onClick={onClick}
        >
          {
            rowActive ? (
              <CaretUpOutlined />
            ) : (
              <CaretDownOutlined />
            )
          }
        </IconButton>
      </div>
    </StyledTableBodyCell>
  )
}

export const RowGroup = ({
  rowData, 
  columns, 
  metricsTab, 
  setMetricsTab, 
  categoryToVendor, 
  isTd,
  expandSub,
  rowActive,
  appendNum,
  toggleExpandFn,
  currentRowHeight,
  onExpand,
  collapseLineStyle = {},
  firstLevelArrowStyle = {},
}: RowGroupType) => {
  const [ expanded, setExpanded ] = useState(false)

  useEffect(() => {
    let timer: ReturnType<typeof setTimeout>
    if (rowActive === false) {
      timer = setTimeout(() => {
        setExpanded(false)
      }, 500)
    } else {
      setExpanded(true)
    }
    return () => {
      clearTimeout(timer)
    }
  }, [ rowActive ])

  // sub group height
  const height = rowActive ? currentRowHeight : 0

  return (
    <>
      <TableRow test-id="table-row">
        <BodyCell 
          columns={useMemo(() => columns, [ columns ])}
          rowData={useMemo(() => rowData, [ rowData ])}
        />
        <ArrowCell
          style={rowData.level ? { background: 'white' } : { background: 'rgb(249, 251, 251)', ...firstLevelArrowStyle }}
          className={styles.svgColor}
          rowActive={rowActive}
          onClick={() => {
            if (rowActive !== expanded) return
            toggleExpandFn?.()
          }}
        />
      </TableRow>
      <TableRow>
        <TableCell colSpan={columns.length + appendNum + 1}>
          <div
            className={styles.collapse}
            style={{ height }}
          >
            <ShouldRender shouldRender={rowActive ? true : expanded}>
              <Table style={{ tableLayout: 'fixed', width: 'fit-content' }} aria-label="collapsible table">
                <TableBody aria-label="purchases">
                  {
                    expanded && rowData?.children?.length ? rowData?.children?.map(subrowData => {
                      if (subrowData.children && subrowData.children.length) {
                        return (
                          <Row
                            categoryToVendor={categoryToVendor}
                            rowData={subrowData}
                            key={subrowData.categoryName + subrowData.vendorCode + categoryToVendor}
                            isTd={isTd}
                            columns={columns}
                            setMetricsTab={setMetricsTab}
                            metricsTab={metricsTab}
                            onExpand={onExpand}
                            collapseLineStyle={collapseLineStyle}
                          />
                        )
                      }
                      return (
                        <TableRow key={subrowData.categoryName + subrowData.vendorCode}>
                          <BodyCell 
                            columns={columns}
                            rowData={subrowData}
                          />
                          <StyledTableBodyCell width={40} className={classNames([ styles.arrowCell, 'tableArrowTd' ])} style={subrowData.level ? { background: 'white' } : {}} />
                        </TableRow>
                      )
                    }) : null
                  }
                </TableBody>
              </Table>
            </ShouldRender>
          </div>
          <ShouldRender shouldRender={expandSub}>
            <div className={styles.collapseLine} style={collapseLineStyle} />
          </ShouldRender>
        </TableCell>
      </TableRow>
    </>
  )
}

export function Row({
  rowData, 
  columns, 
  metricsTab, 
  setMetricsTab, 
  categoryToVendor, 
  isTd, 
  onExpand, 
  collapseLineStyle = {}, 
  firstLevelArrowStyle = {}, 
}: RowType) {
  const {
    subActive,
    expandSub,
    rowActive,
    rowSubIndex,
    appendNum,
    rowIndex,
    currentRowHeight,
  } = calcHandler({ row: rowData, metricsTab, categoryToVendor, isTd })
  
  // children caetgory wrapper height
  let childrenCategoryHeight = 0
  if (subActive && Array.isArray(rowData.childrenData) && rowData.childrenData.length) {
    childrenCategoryHeight = metricsTab[rowIndex].children
      .map(item => {
        return item?.active ? item?.categorycount : 1
      })
      .reduce((curr, next) => curr + next, 0) * 43
  }

  return (
    <>
      <RowGroup {
        ...{
          subActive,
          expandSub,
          rowActive,
          rowSubIndex,
          appendNum,
          rowIndex,
          rowData,
          columns,
          metricsTab,
          setMetricsTab,
          categoryToVendor,
          isTd,
          currentRowHeight,
          onExpand,
          collapseLineStyle,
          firstLevelArrowStyle,
          toggleExpandFn: () => {
            const a = metricsTab.concat()
            // competitor 视图
            if (categoryToVendor) {
              // 第 1 级
              if (rowData.level === 1) {
                const active = !a[rowIndex].children[rowSubIndex].active
                a[rowIndex].children[rowSubIndex].active = active
                setMetricsTab(a)
                onExpand?.(active, String(`${rowIndex}-${rowSubIndex}`))
              } else {
                const index = a.findIndex(item => item.title === rowData.vendorName)
                const active = !a[index].active
                a[index].active = active
                setMetricsTab(a)
                onExpand?.(active, String(index))
              }
            } 
            // category 视图
            else if (rowData.level === 0) {
              const index = a.findIndex(item => item.title === rowData.categoryName)
              const active = !a[index].active
              a[index].active = active
              setMetricsTab(a)
              onExpand?.(active, String(index))
            }
          },
        }}
      />

      <TableRow>
        <TableCell colSpan={columns.length + appendNum + 1}>
          <div
            className={styles.collapse}
            style={{ height: childrenCategoryHeight + 'px' }}
          >
            <Table aria-label="purchases" style={{ tableLayout: 'fixed', display: childrenCategoryHeight ? 'table' : 'none' }}>
              <TableBody>
                {
                  rowData?.childrenData?.length ? rowData.childrenData.map((subRecord, index) => {
                    let subExtraNum = subRecord.level === 1 ? 0 : 1
                    subExtraNum = categoryToVendor ? subExtraNum : 0
                    return (
                        <RowGroup
                          key={subRecord.categoryName + subRecord.vendorCode}
                          {...{
                            subActive,
                            expandSub,
                            rowActive: metricsTab[rowIndex]?.children[index]?.active,
                            rowSubIndex,
                            appendNum: subExtraNum,
                            rowIndex,
                            rowData: subRecord,
                            columns,
                            metricsTab,
                            setMetricsTab,
                            categoryToVendor,
                            isTd,
                            currentRowHeight,
                            collapseLineStyle,
                            toggleExpandFn() {
                              const tabs = metricsTab.concat()
                              // category 视图
                              if (subRecord.level === 0) {
                                tabs[tabs.findIndex(item => item.title === subRecord.categoryName)].active = !tabs[tabs.findIndex(item => item.title === subRecord.categoryName)].active
                                setMetricsTab(tabs)
                              } else if (subRecord.level === 1) {
                                const oneLevelIndex = tabs.findIndex(item => item.title === subRecord.parentCategoryName)
                                const twoLevelIndex = tabs[oneLevelIndex].children.findIndex(item => item.title === subRecord.categoryName)
                                tabs[oneLevelIndex].children[twoLevelIndex].active = !tabs[oneLevelIndex].children[twoLevelIndex].active
                                setMetricsTab(tabs)
                              }
                            },
                          }}
                        />
                    )
                  }) : null}
              </TableBody>
            </Table>
          </div>
        </TableCell>
      </TableRow>
    </>
  )
}
