import { useCalendarComparisonDate, useCalendarComparisonTmpDate, useCalendarDate, useCalendarTmpDate } from 'hooks/useGlobalData'
import React from 'react'

const TestUpdateDateRange = () => {
    const [ , setCalendarDate ] = useCalendarDate()
    const [ , setCalendarTmpDate ] = useCalendarTmpDate()
    const [ , setCalendarComparisonDate ] = useCalendarComparisonDate()
    const [ , setCalendarComparisonTmpDate ] = useCalendarComparisonTmpDate()
    
    const onUpdateDateRange = () => {
        // 2024-10-01_2024-10-01,2024-10-05_2024-10-05
        const value = (document.querySelector('input[test-id="test_update-date-range_input"]') as any)?.value
        if (!value) return
        const [ date, comparisonDate ] = value.split(',')
        setCalendarDate(date)
        setCalendarTmpDate('')
        setCalendarComparisonDate(comparisonDate)
        setCalendarComparisonTmpDate('')
    }

    return (
        <div style={{ position: 'fixed', left: -120, top: 0, zIndex: 9999999, cursor: 'auto' }}>
            <input 
                test-id="test_update-date-range_input"
                type="text"
                style={{ color: 'transparent', borderColor: 'transparent', outline: 'none' }} 
            />
            <button
                test-id="test_update-date-range_button"
                onClick={onUpdateDateRange}
                style={{ color: 'transparent', backgroundColor: 'transparent', borderColor: 'transparent' }}
            >
                Update
            </button>
        </div>
    )
}

export default TestUpdateDateRange
