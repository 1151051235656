import { getVendorNameByCode } from 'utils'

export const getDisplayComeptitor = (competitorValue) => {
    if (competitorValue.length > 1) {
        return 'Competitors'
    }
    return getVendorNameByCode(competitorValue[0])
}

export const alignScrollbar = (selector1, selector2) => {
    const selector1Wrapper = document.querySelector(selector1)
    const selector2Wrapper = document.querySelector(selector2)
    if (!selector1Wrapper || !selector2Wrapper) return
    const scrollLeft = selector1Wrapper.scrollLeft
    selector1Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
    selector2Wrapper.scrollLeft = Number(Number(scrollLeft).toFixed(0))
}
