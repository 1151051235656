import React, { useEffect, useState } from 'react'
import OrderForm, { OrderData } from './OrderForm'
import { Button, Descriptions, Flex, Table, Tag } from 'antd'
import { ModuleTitle } from 'componentsv2/ModuleTitle'
import { RoutesObj } from 'global/Routes'
import { useDynamicDashboardReportData } from 'hooks/api/useDynamicDashboardReportData'
import PdfModal from './PdfModal'
import EditModal from './EditModal'

const DynamicDashboardReport = () => {
    const [ editModalVisible, setEditModalVisible ] = useState(false)
    const [ editModalRecord, setEditModalRecord ] = useState()

    const onEdit = (record) => {
        setEditModalRecord(record)
        setEditModalVisible(true)
    }

    const [ pdfModalVisible, setPdfModalVisible ] = useState(false)
    const [ pdfModalRecord, setPdfModalRecord ] = useState<any>()

    const onShowPdfModal = (record) => {
        setPdfModalVisible(true)
        setPdfModalRecord(record)
    }

    const columns: any[] = [
        {
            dataIndex: 'filterInfo',
            title: 'Filter Info',
            width: '50%',
            render(_, record) {
                return (
                    <Descriptions column={2}>
                        <Descriptions.Item label="Point of view">{record?.query?.povValue}</Descriptions.Item>
                        <Descriptions.Item label="Country">{record?.query?.regionValue}</Descriptions.Item>
                        <Descriptions.Item label="Currency">{record?.query?.currencyValue}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Competitor">{record?.query?.competitorValue?.join(', ')}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Gender">{record?.query?.genderValue?.join(', ')}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Category">{record?.query?.categoryValue?.join(', ')}</Descriptions.Item>
                        <Descriptions.Item span={2} label="KPI">{record?.query?.kpiValue?.join(', ')}</Descriptions.Item>
                    </Descriptions>
                )
            },
        },
        {
            dataIndex: 'frequency',
            title: 'Frequency',
            render(text) {
                let result = ''
                if (text === '* * Mon') {
                    result = 'Weekly'
                } else if (text === '1 * *') {
                    result = 'Monthly'
                } else {
                    result = text
                }
                return <Tag>{result}</Tag>
            },
        },
        {
            dataIndex: 'receive_email',
            title: 'Receive email',
        },
        {
            dataIndex: 'status',
            title: 'Status',
            render(text) {
                const colors = {
                    active: 'green',
                    inactive: 'red',
                    pause: 'grey',
                }
                return <Tag color={colors[text]}>{text}</Tag>
            },
        },
        {
            dataIndex: 'action',
            title: 'Action',
            align: 'center',
            render(_, record) {
                return (
                    <Flex gap={10} justify="center">
                        <Button
                            type="link"
                            onClick={() => onEdit(record)}
                        >
                            Edit
                        </Button>
                        <Button
                            type="link"
                            onClick={() => onShowPdfModal(record)}
                        >
                            PDF
                        </Button>
                    </Flex>
                )
            },
        },
    ]

    const {
        listConfigs,
        listConfigsLoading,
        listConfigsData,
        createConfig,
        createConfigLoading,
        modifyConfig,
    } = useDynamicDashboardReportData()

    useEffect(() => {
        listConfigs()
    }, [])

    const onSaveOrder = async (data) => {
        await modifyConfig({
            orderId: data.orderId,
            frequency: data.frequencyValue,
            receiveEmail: data.receiveEmailValue,
            status: data.statusValue,
            query: {
                povValue: data.povValue,
                competitorValue: data.competitorValue || [],
                regionValue: data.regionValue,
                currencyValue: data.currencyValue,
                genderValue: data.genderValue || [],
                categoryValue: data.categoryValue || [],
                kpiValue: data.kpiValue || [],

            },
        })
        await listConfigs()
        setEditModalVisible(false)
    }

    const onOrder = async (data: OrderData) => {
        if (!data.frequencyValue || !data.receiveEmailValue) return
        for (let i = 0; i < data.frequencyValue.length; i++) {
            await createConfig({
                frequency: data.frequencyValue[i],
                receiveEmail: data.receiveEmailValue,
                query: {
                    currencyValue: data.currencyValue,
                    genderValue: data.genderValue,
                    regionValue: data.regionValue,
                    competitorValue: data.competitorValue,
                    povValue: data.povValue,
                    categoryValue: data.categoryValue || [],
                    kpiValue: data.kpiValue || [],
                },
            })
        }
        await listConfigs()
    }
    
    return (
        <Flex vertical gap={20}>
            <ModuleTitle 
                title={RoutesObj.DYNAMIC_DASHBOARD_REPORT.LABEL}
            />
            <OrderForm
                loading={createConfigLoading}
                onOrder={onOrder}
            />
            <Table 
                columns={columns}
                dataSource={listConfigsData}
                loading={listConfigsLoading}
                rowKey="order_id"                
            />
            {
                editModalVisible && editModalRecord && (
                    <EditModal 
                        record={editModalRecord}
                        onSave={onSaveOrder}
                        onCancel={() => setEditModalVisible(false)}
                    />
                )
            }
            {
                pdfModalVisible && pdfModalRecord && (
                    <PdfModal 
                        orderId={pdfModalRecord.order_id}
                        onCancel={() => setPdfModalVisible(false)}
                    />
                )
            }
        </Flex>
    )
}

export default DynamicDashboardReport
