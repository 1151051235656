import { Switch } from 'componentsv2/form-elements/Switch'
import React from 'react'

export const CategoryCompetitorSwitch = ({
    value,
    onChange,
    left,
    right,
}: {
    value?: boolean;
    onChange?: (value: boolean) => void;
    left?: number;
    right?: number;
}) => {
    return (
        <Switch
            leftLabel="Category"
            rightLabel="Competitor"
            label=""
            switchWrapperStyle={{ alignItems: 'center', height: 36 }}
            value={value}
            onChange={onChange}
            right={right}
            left={left}
        />
    )
}
