import { priceInsightsColumns } from 'consts/dashboardTableFields'

const width01 = 80
const width02 = 85

export const tabWidth = 148
export const optionsWidth = 115
export const categoryWidth = 195
export const arrowWidth = 40

export const avgPriceValueWidth = width01
export const avgPriceChangePercentWidth = width02
export const avgPriceChangeWidth = width02
export const indexWidth = width01
export const indexChangeWidth = width02
export const frequentPriceValueWidth = width01
export const frequentPriceChangePercentWidth = width02
export const frequentPriceChangeWidth = width02
export const disEffectValueWidth = width01
export const disEffectChangeWidth = width02
export const disDepthValueWidth = width01
export const disDepthChangeWidth = width02
export const disWidthValueWidth = width01
export const disWidthChangeWidth = width02

export const averagePriceWidth = avgPriceValueWidth + avgPriceChangePercentWidth + avgPriceChangeWidth
export const indexWrapperWidth = indexWidth + indexChangeWidth
export const frequentPriceWidth = frequentPriceValueWidth + frequentPriceChangePercentWidth
export const disEffectWidth = disEffectValueWidth + disEffectChangeWidth
export const disDepthWidth = disDepthValueWidth + disDepthChangeWidth
export const disWidthWidth = disWidthValueWidth + disWidthChangeWidth

export const getTableScrollWidth = (fieldList: string[] = []) => {
    let width = tabWidth + arrowWidth + optionsWidth + categoryWidth
    if (fieldList.includes(priceInsightsColumns.AVG_PRICE_VALUE)) {
        width += averagePriceWidth
    }
    if (fieldList.includes(priceInsightsColumns.INDEX)) {
        width += indexWrapperWidth
    }
    if (fieldList.includes(priceInsightsColumns.FREQUENT_PRICE_VALUE)) {
        width += frequentPriceWidth
    }
    if (fieldList.includes(priceInsightsColumns.DIS_EFFECT_VALUE)) {
        width += disEffectWidth
    }
    if (fieldList.includes(priceInsightsColumns.DIS_DEPTH_VALUE)) {
        width += disDepthWidth
    }
    if (fieldList.includes(priceInsightsColumns.DIS_WIDTH_VALUE)) {
        width += disWidthWidth
    }
    return width
}
